import React, { useState, useContext, useEffect } from "react";

import { 
    ScreenContainer,
    TextField,
    Button,
    Box,
    Message,
    Tab,
    NumberField
} from '../../components';
import { post, get } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';

function Kit() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ pieces, setPieces ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ loadKit, setLoadKit ] = useState();
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const history = useHistory();
    const {id, kitId} = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
            let result = await get('/kit/'+kitId);
            if(result.success){
                setLoadKit(result.data);
                setValue('title', result.data.title);
                setPieces(result.data.products);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let piecesLight = [];

        pieces.forEach(piece =>{
            piecesLight.push({id: piece.id, quantity: piece.quantity ? piece.quantity : 0});
        })

        let params = {
            ...data,
            pieces: JSON.stringify(piecesLight),
            machine: id
        }

        const result = await post("/kit/update/"+kitId, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.goBack()
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_create_kit', lang));
            }
        }
    };

    const renderImage = (e) => {
        if(e){
            return <img src={e} width={50}/>
        }

        return null;
    }

    const handleAction = (action, element) => {
        let index = pieces.findIndex(p => p.id === element.id)

        let newPieces = [ ...pieces ];
        newPieces.push(element);

        if(index === -1){
            setPieces(newPieces);
        }
    }

    const onRemove = (index) => {
        let newPieces = [ ...pieces ];

        newPieces.splice(index, 1);

        setPieces(newPieces);
    }

    const renderStock = (e) => {
        if(e){
            return Text('yes', lang);
        }

        return Text('no', lang);
    }

    const config = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "sku",
                label: Text('sku', lang),
            },
            {
                name: "price",
                label: Text('price', lang),
            },
            {
                name: "is_in_stock",
                label: Text('is_in_stock', lang),
                render: renderStock
            },
        ],
        actions: [
            {
                label: Text('add', lang),
                name: 'add',
                active: true,
                skin: 'success'
            },
        ],
        error: Text('piece_list_error', lang),
        no_results: Text('no_piece', lang),
        fetchUrl: '/machine/'+id+'/pieces',
        handleAction: handleAction,
        params: {
            machine: id,
            search
        }
    };

    const changeQuantity = (quantity, index) => {
        let newPieces = [...pieces];
        newPieces[index]['quantity'] = quantity;
        setPieces(newPieces);
    }

    return (
        <ScreenContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{loadKit ? loadKit.title : null}</h1>
                    </div>
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="w-full mb-4">
                        <TextField className="mb-4" onChange={(e) => setSearch(e.target.value)}>
                            {Text('search', lang)}
                        </TextField>
                    </Box>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="flex-1 mr-4">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('pieces', lang)}</span>
                        </div>
                        <Tab {...{config}}/>
                    </Box>
                    <Box className="flex-1">
                        <div className="mb-4 ">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="title"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.title}
                            className="mb-6"
                        >
                            {Text('name', lang)}
                        </TextField>
                        <div className="mb-4">
                            <span className="block font-bold text-xs">{Text('piece_added', lang).toUpperCase()}</span>
                        </div>
                        <div className="w-full h-100 bg-secondary rounded border flex flex-col overflow-auto px-4 py-2">
                            {pieces && pieces.length ? (
                                <table className={"w-full"}>
                                    <thead>
                                        <tr className="border-b border-gray-400">
                                            <th className="px-4 py-2 text-left text-gray-500">
                                                {Text('name', lang)}
                                            </th>
                                            <th className="px-4 py-2 text-left text-gray-500">
                                                {Text('quantity', lang)}
                                            </th>
                                            <th className="px-4 py-2 text-left text-gray-500">
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        { pieces.map((piece, i) => (
                                            <tr key={i} className={i === pieces.length - 1? null : "border-b border-gray-400"} >
                                                <td className="px-4 py-2">
                                                    <span>{piece.title}</span>
                                                </td>
                                                <td className="px-4 py-2">
                                                    <NumberField
                                                        className="mb-6"
                                                        defaultValue={piece.quantity}
                                                        onChange={(e) => changeQuantity(e.target.value, i)}
                                                        min='1'
                                                    />
                                                </td>
                                                <td className="flex flex-raw px-4 py-2">
                                                    <Button onPress={() => onRemove(i)}><i className={"icon-less text-l"}></i></Button>
                                                </td>
                                            </tr>
                                        )) }
                                    </tbody>
                                </table>
                            ) : (
                                <Message>{Text('no_piece_add', lang)}</Message>
                            )}
                        </div>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default Kit;