import React, { useState, useContext, useRef, useEffect } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    Checkbox,
    Button,
    Box,
    Message,
    Badge,
    Modal
} from '../../components';
import { post, get, remove } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import Text from '../../utils/text';
import Rights from '../../constants/Rights';

function User() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [ user, setUser ] = useContext(UserContext);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const modalRef = useRef();
    const deleteModalRef = useRef();
    const { id } = useParams();
    const history = useHistory();
    const [ loadUser, setLoadUser ] = useState();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
        unregister
    } = useForm();

    const role = watch("role");

    useEffect(() => {
        clearErrors('site');
        clearErrors('subsidiary');

        switch (role) {
            case 'ROLE_ADMIN':
                unregister('site');
                unregister('subsidiary');
                break;
            case 'ROLE_COMPANY_MANAGER':
                unregister('site');
                unregister('subsidiary');
                break;

            case 'ROLE_SUBSIDIARY_MANAGER':
                unregister('site');
                break;

            case 'ROLE_SITE_MANAGER':
                unregister('subsidiary');
                break;

            default:
                unregister('subsidiary');
                break
        }
    }, [role])

    useEffect(() => {
        const load = async () => {
            setIsLoadingData(true);
            const result = await get("/user/"+id);

            if(result.success){
                setLoadUser(result.data);
                setValue('company', result.data ? result.data.company : null);
                setValue('subsidiary', result.data ? result.data.subsidiary : null);
                setValue('site', result.data ? result.data.site : null);
                setValue('email', result.data  ? result.data.email : null);
                setValue('firstname', result.data ? result.data.first_name : null);
                setValue('lastname', result.data ? result.data.last_name : null);
                setValue('phone', result.data ? result.data.phone : null);
                setValue('role', result.data ? result.data.role : null);
                setValue('add_machine', result.data && result.data.rights ? result.data.rights.includes('add_machine') : false);
                setValue('update_machine', result.data && result.data.rights ? result.data.rights.includes('update_machine') : false);
                setValue('delete_machine', result.data && result.data.rights ? result.data.rights.includes('delete_machine') : false);
                setValue('add_kit', result.data && result.data.rights ? result.data.rights.includes('add_kit') : false);
                setValue('update_kit', result.data && result.data.rights ? result.data.rights.includes('update_kit') : false);
                setValue('delete_kit', result.data && result.data.rights ? result.data.rights.includes('delete_kit') : false);
                //setValue('add_quote', result.data && result.data.rights ? result.data.rights.includes('add_quote') : false);
                //setValue('update_quote', result.data && result.data.rights ? result.data.rights.includes('update_quote') : false);
                setValue('delete_quote', result.data && result.data.rights ? result.data.rights.includes('delete_quote') : false);
                //setValue('validate_quote', result.data && result.data.rights ? result.data.rights.includes('validate_quote') : false);
                setValue('see_order', result.data && result.data.rights ? result.data.rights.includes('see_order') : false);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoadingData(false);
        }

        load();
    }, [history])

    let roles = [
        {
            value:'ROLE_OPERATOR', 
            label:Text('ROLE_OPERATOR', lang)
        },
    ]

    if(user && user.hasOwnProperty('role')){
        // eslint-disable-next-line default-case
        switch (user.role) {
            case 'ROLE_ADMIN':
                if(menu.context === 'TOPAZ'){
                    roles = [
                        {
                            value:'ROLE_ADMIN', 
                            label:Text('ROLE_ADMIN', lang)
                        },
                    ]
                } else {
                    roles = [
                        ...roles,
                        {
                            value:'ROLE_COMPANY_MANAGER', 
                            label:Text('ROLE_COMPANY_MANAGER', lang)
                        },
                        {
                            value:'ROLE_SUBSIDIARY_MANAGER', 
                            label:Text('ROLE_SUBSIDIARY_MANAGER', lang)
                        },
                        {
                            value:'ROLE_SITE_MANAGER', 
                            label:Text('ROLE_SITE_MANAGER', lang)
                        },
                    ]
                }
                
                break;
            
            case 'ROLE_COMPANY_MANAGER':
                roles = [
                    ...roles,
                    {
                        value:'ROLE_SUBSIDIARY_MANAGER', 
                        label:Text('ROLE_SUBSIDIARY_MANAGER', lang)
                    },
                    {
                        value:'ROLE_SITE_MANAGER', 
                        label:Text('ROLE_SITE_MANAGER', lang)
                    },
                ]
                break;

            case 'ROLE_SUBSIDIARY_MANAGER':
                roles = [
                    {
                        value:'ROLE_SITE_MANAGER', 
                        label:Text('ROLE_SITE_MANAGER', lang)
                    },
                ]
                break;
        }
    }

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            first_name: data.firstname,
            last_name: data.lastname,
            email: data.email,
            phone: data.phone,
            role: data.role,
        }

        // eslint-disable-next-line default-case
        switch (data.role) {
            case 'ROLE_COMPANY_MANAGER':
                params = {
                    ...params,
                    company: data.company
                }
                break;

            case 'ROLE_SUBSIDIARY_MANAGER':
                params = {
                    ...params,
                    subsidiary: data.subsidiary
                }
                break;

            case 'ROLE_SITE_MANAGER':
                params = {
                    ...params,
                    site: data.site
                }
                break;

            case 'ROLE_OPERATOR':
                let rights = [];

                Rights.forEach(R => {
                    if(data[R]){
                        rights.push(R);
                    }
                });

                params = {
                    ...params,
                    site: data.site,
                    rights
                }
                break;
        }

        const result = await post("/user/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/users")
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_user', lang));
            }
        }
    };

    const onDisabled = async () => {
        const result = await post('/user/toggle/'+id);
        if(result.success){
            modalRef.current.toggle();
            setLoadUser(result.data);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const onDelete = async () => {
        const result = await remove('/user/delete/'+id);
        if(result.success){
            modalRef.current.toggle();
            history.push('/user');
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    return (
        <ScreenContainer isLoading={isLoadingData}>
            <Modal ref={modalRef}>
                <span className="text-primary text-m">{Text('popup_deactivate_user', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={loadUser && loadUser.active ? "warning" : "success"} onPress={() => onDisabled()}>
                        {loadUser && loadUser.active ? Text('deactivate', lang) : Text('activate', lang) }
                    </Button>
                </div>
            </Modal>
            <Modal ref={deleteModalRef}>
                <span className="text-primary text-m">{Text('popup_delete_user', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => deleteModalRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"warning"} onPress={() => onDelete()}>
                        {Text('delete', lang)}
                    </Button>
                </div>
            </Modal>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('edit_user', lang)} ( {loadUser ? loadUser.first_name : null} {loadUser ? loadUser.last_name : null} )</h1>
                    </div>
                    <Button className="mb-7.5 mr-4" onPress={() => modalRef.current.toggle()}>
                        { loadUser && loadUser.active ? Text('deactivate_user', lang) : Text('activate_user', lang)}
                    </Button>
                    <Button className="mb-7.5 mr-4" onPress={() => deleteModalRef.current.toggle()}>
                        {Text('delete_user', lang)}
                    </Button>
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="mr-4 mb-4 md:mb-0 flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <div className="flex items-center mb-6">
                            <span className="text-xs font-bold mr-4">{Text('status', lang).toUpperCase()}</span>
                            <Badge type={loadUser && loadUser.active ? "enable" : "error"}>
                                { loadUser && loadUser.active ? Text('activated', lang) : Text('disabled', lang)}
                            </Badge>
                        </div>
                        <TextField
                            name="lastname"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.lastname}
                            className="mb-6"
                        >
                            {Text('lastname', lang)}
                        </TextField>
                        <TextField
                            name="firstname"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.firstname}
                            className="mb-6"
                        >
                            {Text('firstname', lang)}
                        </TextField>
                        <TextField
                            name="email"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.email}
                            className="mb-6"
                        >
                            {Text('email', lang)}
                        </TextField>
                        <TextField
                            name="phone"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.phone}
                            className="mb-6"
                        >
                            {Text('phone', lang)}
                        </TextField>
                    </Box>
                    <Box className={"flex-1 "+ (role === 'ROLE_OPERATOR' ? "md:mr-4 mb-4 md:mb-0" : null)}>
                        <div className="mb-4">
                            <span className="text-xl title">{Text('role_affiliations', lang)}</span>
                        </div>
                        <SelectField
                            name="role"
                            value={loadUser ? loadUser.role : null}
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.role}
                            className="mb-6"
                            choices={roles}
                            setValue={setValue}
                        >
                            {Text('role', lang)}
                        </SelectField>
                        { role === 'ROLE_COMPANY_MANAGER' ? (
                            <SelectField
                                name="company"
                                value={loadUser ? loadUser.company : null}
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.company}
                                className="mb-6"
                                choices={menu.companies}
                                setValue={setValue}
                            >
                                {Text('company', lang)}
                            </SelectField>
                        ) : null}
                        { role === 'ROLE_SUBSIDIARY_MANAGER' ? (
                            <SelectField
                                name="subsidiary"
                                value={loadUser ? loadUser.subsidiary : null}
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.subsidiary}
                                className="mb-6"
                                choices={menu.subsidiaries}
                                setValue={setValue}
                            >
                                {Text('subsidiary', lang)}
                            </SelectField>
                        ) : null}
                        { role === 'ROLE_SITE_MANAGER' || role === 'ROLE_OPERATOR'? (
                            <SelectField
                                name="site"
                                value={loadUser ? loadUser.site : null}
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.site}
                                className="mb-6"
                                choices={menu.sites}
                                setValue={setValue}
                            >
                                {Text('site', lang)}
                            </SelectField>
                        ) : null}
                    </Box>
                    { role === 'ROLE_OPERATOR' ? (
                        <Box className="flex-1">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('rights', lang)}</span>
                            </div>
                            {/* <div>
                                <span className="text-xl">{Text('users', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_user"
                                        register={register}
                                        error={errors?.add_user}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_user"
                                        register={register}
                                        error={errors?.update_user}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_user"
                                        register={register}
                                        error={errors?.delete_user}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div> */}
                            {/* <div>
                                <span className="text-xl">{Text('addresses', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_address"
                                        register={register}
                                        error={errors?.add_address}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_address"
                                        register={register}
                                        error={errors?.update_address}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_address"
                                        register={register}
                                        error={errors?.delete_address}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div> */}
                            <div>
                                <span className="text-xl">{Text('machines', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_machine"
                                        register={register}
                                        error={errors?.add_machine}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_machine"
                                        register={register}
                                        error={errors?.update_machine}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_machine"
                                        register={register}
                                        error={errors?.delete_machine}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('kits', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_kit"
                                        register={register}
                                        error={errors?.add_kit}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_kit"
                                        register={register}
                                        error={errors?.update_kit}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_kit"
                                        register={register}
                                        error={errors?.delete_kit}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('quotes', lang)}</span>
                                <div className="flex">
                                    {/* <Checkbox
                                        name="add_quote"
                                        register={register}
                                        error={errors?.add_quote}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_quote"
                                        register={register}
                                        error={errors?.update_quote}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox> */}
                                    <Checkbox
                                        name="delete_quote"
                                        register={register}
                                        error={errors?.delete_quote}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                    {/* <Checkbox
                                        name="validate_quote"
                                        register={register}
                                        error={errors?.validate_quote}
                                        className="mb-6"
                                    >
                                        {Text('validate', lang)}
                                    </Checkbox> */}
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('orders', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="see_order"
                                        register={register}
                                        error={errors?.see_order}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('see', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                        </Box>
                    ) : null}
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default User;