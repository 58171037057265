import React, { useState, useContext, useEffect} from "react";

import { ScreenContainer, Tab, Button, TextField, Box, SelectField } from '../../components';
import { useHistory } from 'react-router-dom';
import { MenuContext } from '../../contexts/MenuContext';
import { LangContext } from '../../contexts/LangContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import userEvent from "@testing-library/user-event";
import { get } from "../../server/api";

function Users() {
    const history = useHistory();
    const [ menu ] = useContext(MenuContext);
    const [ lang ] = useContext(LangContext);
    const [ user ] = useContext(UserContext);

    const [ search, setSearch ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ subsidiary, setSubsidiary ] = useState('');
    const [ site, setSite ] = useState('');

    const [ companies, setCompanies] = useState([{value: '', label: Text('select', lang)}]);
    const [ subsidiaries, setSubsidiaries ] = useState([{value: '', label: Text('select', lang)}]);
    const [ sites, setSites ] = useState([{value: '', label: Text('select', lang)}]);  

    useEffect(() => {
        const load = async () =>{
            let data = {
                company,
                subsidiary,
                site,
                all: true
            }

            let dataCompanies = await get('/companies', data);
            if(dataCompanies && dataCompanies.success){
                let newCompanies = [{value: '', label: Text('select', lang)}];
                dataCompanies.items.forEach(element => {
                    newCompanies.push({value: element.id, label: element.name})
                });
                setCompanies(newCompanies);
            }
            let dataSubsidiaries = await get('/subsidiaries', data);
            if(dataSubsidiaries && dataSubsidiaries.success){
                let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                dataSubsidiaries.items.forEach(element => {
                    newSubsidiaries.push({value: element.id, label: element.name})
                });
                setSubsidiaries(newSubsidiaries);
            }
            let dataSites = await get('/sites', data);
            if(dataSites && dataSites.success){
                let newSites = [{value: '', label: Text('select', lang)}];
                dataSites.items.forEach(element => {
                    newSites.push({value: element.id, label: element.name})
                });
                setSites(newSites);
            }
        }

        if(user.role === 'ROLE_ADMIN'){
            load();
        }

    }, [user, company, subsidiary, site])

    const handleAction = (action, element) => {
        let url = "/"
        if(element.id === user.id){
            url = "/account";
        } else {
            url = "/user/"+element.id;
        }

        history.push(url);
    }

    const config = {
        headers: [
            {
                name: "last_name",
                label: Text('lastname', lang),
            },
            {
                name: "first_name",
                label: Text('firstname', lang),
            },
            {
                name: "email",
                label: Text('email', lang),
            },
            {
                name: "phone",
                label: Text('phone', lang),
            },
            {
                name: "company",
                label: Text('company', lang),
            },
            {
                name: "subsidiary",
                label: Text('subsidiary', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "user/:id",
                active: menu.context === 'TOPAZ' ? "TOPAZ" : true,
                index: menu.context === 'TOPAZ' ? "company" : false
            },
        ],
        error: Text('user_list_error', lang),
        no_results: Text('no_user', lang),
        fetchUrl: '/users',
        handleAction: handleAction,
        params: { 
            search,
            company: menu.context === 'TOPAZ' ? company : menu.company, 
            subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary, 
            site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site, 
        }
    };

    return (
        <ScreenContainer>
            <div className="flex items-center w-full">
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('users', lang)}</h1>
                </div>
                <Button className="mb-7.5" onPress={() => history.push('/user/add')}>
                    {Text('add_user', lang)}
                </Button>
            </div>
            <div className="flex">
                <Box className="mr-4 w-1/5">
                    <TextField className="mb-4" onChange={(e) => setSearch(e.target.value)}>
                        {Text('search', lang)}
                    </TextField>
                    { menu.context !== 'TOPAZ' ? null : (
                        <SelectField 
                            className="mb-4" 
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            choices={companies}
                            search
                        >
                            {Text('company', lang)}
                        </SelectField>
                    )}
                    { menu.subsidiary !== '' ? null : (
                        <SelectField 
                            className="mb-4" 
                            value={subsidiary}
                            onChange={(e) => setSubsidiary(e.target.value)}
                            choices={menu.context === 'TOPAZ' ? subsidiaries : menu.subsidiaries}
                            search
                        >
                            {Text('subsidiary', lang)}
                        </SelectField>
                    )}
                    { menu.site !== '' ? null : (
                        <SelectField 
                            className="mb-4" 
                            value={site}
                            onChange={ (e) => setSite(e.target.value)}
                            choices={menu.context === 'TOPAZ' ? sites : menu.sites}
                            search
                        >
                            {Text('site', lang)}
                        </SelectField>
                    )}
                </Box>
                <Box className="flex flex-1">
                    <Tab {...{config}} />
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Users;