import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RouteManager from './RouteManager';

import {
    StyleGuide,
    NotFound,
    Dashboard,
    Company,
    HistoryMachine, 
    HistorySite, 
    Login, 
    Machine, 
    Machines, 
    Orders, 
    Quote, 
    Quotes, 
    Site, 
    Sites, 
    Subsidiaries, 
    Subsidiary, 
    User, 
    Users,
    SubsidiaryAdd,
    SiteAdd,
    QuoteAdd,
    UserAdd,
    Companies,
    CompanyAdd,
    // Address,
    // Addresses,
    // AddressAdd,
    PasswordForgot,
    Account,
    MachineAdd, 
    PartAdd,
    Part,
    Kit,
    KitAdd,
    Pieces,
    Piece,
    MachinesPending,
    MachinePending,
    Maintenance,
    Configurations,
    ReferencesHistory,
    MachineLight,
    ModelsHistory
} from "../screens";
import MassiveMachinesProducts from '../screens/MassiveMachinesProducts';

function Router({ children }) {
    
    return (
        <BrowserRouter>
            { children }
            <RouteManager>
                <Switch>
                    <Route path="/style-guide" component={StyleGuide} exact />
                    <Route path="/login" component={Login} exact />
                    <Route path="/password/forgot" component={PasswordForgot} exact />
                    <Route path="/dashboard" component={Dashboard} exact />   

                    <Route path="/companies" component={Companies} exact />
                    <Route path="/company/add" component={CompanyAdd} exact />
                    <Route path="/company/:id" component={Company} exact />

                    <Route path="/subsidiaries" component={Subsidiaries} exact />
                    <Route path="/subsidiary/add" component={SubsidiaryAdd} exact />
                    <Route path="/subsidiary/:id" component={Subsidiary} exact />
                    
                    <Route path="/sites" component={Sites} exact />
                    <Route path="/site/add" component={SiteAdd} exact />
                    <Route path="/site/:id" component={Site} exact />
                    <Route path="/site/:id/history" component={HistorySite} exact />

                    <Route path="/account" component={Account} exact />
                    <Route path="/users" component={Users} exact />
                    <Route path="/user/add" component={UserAdd} exact />
                    <Route path="/user/:id" component={User} exact />

                    <Route path="/machines" component={Machines} exact />
                    <Route path="/machines-pending" component={Machines} exact />
                    <Route path="/machines/pending" component={MachinesPending} exact />
                    <Route path="/machine/pending/:id" component={MachinePending} exact />
                    <Route path="/machine/add" component={MachineAdd} exact />
                    <Route path="/machine/:id" component={Machine} exact />
                    <Route path="/machine/:id/history" component={HistoryMachine} exact />
                    <Route path="/machine/:id/add-part" component={PartAdd} exact />
                    <Route path="/machine/:id/add-kit" component={KitAdd} exact />
                    <Route path="/machine/:id/part/:partId" component={Part} exact />
                    <Route path="/machine/:id/kit/:kitId" component={Kit} exact />
                    <Route path="/machine/:id/pieces" component={Pieces} exact />
                    <Route path="/machine/:id/piece/:pieceId" component={Piece} exact />
                    <Route path="/machine/waiting/:id" component={MachineLight} exact />

                    <Route path="/quotes" component={Quotes} exact />
                    <Route path="/quote/add" component={QuoteAdd} exact />
                    <Route path="/quote/:id" component={Quote} exact />
                    
                    <Route path="/orders" component={Orders} exact />

                    <Route path="/maintenance" component={Maintenance} exact />

                    <Route path="/configurations" component={Configurations} exact />
                    <Route path="/references-history" component={ReferencesHistory} exact />
                    <Route path="/model-history" component={ModelsHistory} exact />
                    <Route path="/massive-machines-products" component={MassiveMachinesProducts} exact />

                    {/* <Route path="/addresses" component={Addresses} exact />
                    <Route path="/address/add" component={AddressAdd} exact />
                    <Route path="/address/:id" component={Address} exact /> */}

                    <Route component={NotFound} />
                </Switch> 
            </RouteManager>
        </BrowserRouter>        
    )
}

export default Router;