import React, { useState, useContext, useEffect, useRef } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    Button,
    Box,
    Message,
    Modal,
    Tab,
    Badge,
    TextAreaField,
    NumberField
} from '../../components';
import { post } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { get, remove } from '../../server/api';

function Part() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ loadPart, setLoadPart] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const { id, partId } = useParams();
    const history = useHistory();
    const location = useLocation();
    const modalRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
            let result = await get('/part/'+id);
            if(result.success){
                setLoadPart(result.data);
                setValue('title', result.data.title)
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data
        }

        const result = await post("/part/update/"+partId, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/machine/"+id)
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_part', lang));
            }
        }
    };

    const onDelete = async () => {
        const result = await remove('/machine/delete/'+id);
        if(result.success){
            modalRef.current.toggle();
            history.push('/machines')
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    return (
        <ScreenContainer>
            <Modal ref={modalRef}>
                <span className="text-primary text-m">{Text('popup_delete_site', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"warning"} onPress={() => onDelete()}>
                        {Text('delete', lang)}
                    </Button>
                </div>
            </Modal>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{loadPart ? loadPart.title : null}</h1>
                    </div>
                    <Button className="mb-7.5 mr-4" onPress={() => modalRef.current.toggle()}>
                        {Text('delete_part', lang)}
                    </Button>
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap">
                    <Box className="flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="title"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.title}
                            className="mb-6"
                        >
                            {Text('name', lang)}
                        </TextField>
                        
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default Part;