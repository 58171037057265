import React from "react";

function FileUpload({
    children,
    name,
    type = "file",
    placeholder,
    register,
    error,
    options,
    className,
    onChange,
    inputClassName,
    extraContent
}) {
    className = "form-field relative " + className;

    let errorMessage = error ? error.message : null;

    const inputRegister = register(name, options);
    const handleOnChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let newFile = e.target.files[0];

        if(newFile){
            reader.readAsDataURL(newFile);
        } 

        if (typeof onChange !== "undefined") {
            onChange(newFile);
        }

        inputRegister.onChange(e);
    };

    let isRequired = options && options.hasOwnProperty("required");
    if (isRequired) {
        className += " required";
    }

    return (
        <div className={className}>
            <div className="relative">
                <div className="flex flex-row justify-between mb-2.5">
                    {children && type !== "hidden" ? (
                        <label
                            htmlFor={"input-" + name}
                            className=" block font-bold text-xs"
                        >
                            {children.toUpperCase()}
                        </label>
                    ) : null}
                    {extraContent ? extraContent : !isRequired ? (<span className="font-bold text-xs opacity-50">Facultatif</span>) : null}
                </div>
                <div>
                    <input
                        id={"input-" + name}
                        type={type}
                        placeholder={placeholder}
                        className={
                            inputClassName
                                ? inputClassName
                                : "bg-primary-50 border-primary-200 border-solid border w-full py-2 px-4 outline-none rounded-lg focus:bg-white focus:border-2 focus:border-primary"
                        }
                        onChange={handleOnChange}
                        onBlur={inputRegister.onBlur}
                        ref={inputRegister.ref}
                        name={inputRegister.name}
                    />
                </div>
            </div>
            {errorMessage ? (
                <span className="text-sm text-status-100">{errorMessage}</span>
            ) : null}
        </div>
    );
}

export default FileUpload;
