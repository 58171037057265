export const trad = {
    fr:{
        required: 'Ce champ est requis',
        email: 'Email',
        password: 'Mot de passe',
        connection: 'Connexion',
        login: 'Connectez-vous',
        login_error_1: 'Identifiants incorrects, veuillez réessayer.',
        login_error_2: 'Veuillez remplir les champs.',
        login_error_3: 'Connection impossible, cet utilisateur n\'est affilié à aucune société.',
        login_error_4: 'Connection impossible, n° de TVA non valide.',
        login_error_5: 'Connection impossible, vous n\'êtes pas utilisateur premium.',
        connected_company: 'connecté à la société',
        select:'Sélectionnez',
        select_company: 'Sélectionnez une société',
        select_site: 'Sélectionnez un site',
        select_subsidiary: 'Sélectionnez une filiale',
        company: 'Société',
        site: 'Site',
        subsidiary: 'Filiale',
        connected_to_company: 'Connecté à la société',
        connected_to_subsidiary: 'Connecté à la filiale',
        connected_to_site: 'Connecté au site',
        dashboard: 'Tableau de bord',
        users: 'Utilisateurs',
        user: 'Utilisateur',
        add_user: 'Ajouter un utilisateur',
        search: 'recherche',
        no_user: 'Aucun Utilisateur trouvé',
        user_list_error: 'Une erreur est survenue lors de la récupération de la liste des Utilisateurs.',
        logout:'Déconnexion',
        loading: 'Chargement',
        lastname: 'Nom',
        firstname: 'Prénom',
        phone: 'Télephone',
        add: 'Ajouter',
        update: 'Modifier',
        delete: 'Supprimer',
        validate: 'Valider',
        see: 'Consulter',
        back: 'Retour',
        infos: 'Informations',
        role_affiliations: 'Rôle et Affiliations',
        rights:'Droits',
        role: 'Rôle',
        addresses: 'Mes Adresses',
        machines: 'Machines',
        kits: 'Kits',
        quotes: 'Devis',
        orders: 'Commandes',
        edit: 'Éditer',
        deactivate_user: 'Désactiver l\'utilisateur',
        activate_user: 'Activer l\'utilisateur',
        deactivate:'Désactiver',
        activate:'Activer',
        activated: 'Activé',
        disabled: 'Désactivé',
        edit_user: 'Éditer',
        state: 'État',
        status: 'Statut',
        popup_deactivate_user: 'Êtes vous sûr de vouloir désactiver cet utilisateur ?',
        cancel: 'Annuler',
        address: 'Adresse',
        city: 'Ville',
        zip_code: 'Code Postal',
        country: 'Pays',
        address_list_error: 'Une erreur est survenue lors de la récupération de la liste des adresses.',
        no_address: 'Aucune Adresse trouvée',
        add_address: 'Ajouter une adresse',
        edit_address: 'Éditer l\'adresse',
        delete_address: 'Supprimer cette adresse',
        popup_delete_address: 'Êtes vous sûr de vouloir supprimer cette adresse ?',
        companies: 'Sociétés',
        company_list_error: 'Une erreur est survenue lors de la récupération de la liste des sociétés.',
        no_company: 'Aucune société trouvée',
        add_company: 'Ajouter une société',
        delete_company: 'Supprimer cette société',
        popup_delete_company: 'Êtes vous sûr de vouloir supprimer cette société ?',
        edit_company: 'Éditer la société',
        name: 'Nom',
        siret: 'Siret',
        tva_number: 'Numéro TVA',
        subsidiaries: 'Filiales',
        subsidiary_list_error: 'Une erreur est survenue lors de la récupération de la liste des filiales.',
        no_subsidiary: 'Aucune filiale trouvée',
        add_subsidiary: 'Ajouter une filiale',
        delete_subsidiary: 'Supprimer cette filiale',
        popup_delete_subsidiary: 'Êtes vous sûr de vouloir supprimer cette filiale ?',
        edit_subsidiary: 'Éditer la filiale',
        sites: 'Sites',
        site_list_error: 'Une erreur est survenue lors de la récupération de la liste des sites.',
        no_site: 'Aucun site trouvé',
        add_site: 'Ajouter un site',
        delete_site: 'Supprimer ce site',
        popup_delete_site: 'Êtes vous sûr de vouloir supprimer ce site ?',
        edit_site: 'Éditer le site',
        form_error_create_user: 'Une erreur c\'est produite lors de la création de l\'utilisateur',
        form_error_update_user: 'Une erreur c\'est produite lors de la mise à jour de l\'utilisateur',
        form_error_create_company: 'Une erreur c\'est produite lors de la création de la société',
        add_company_error_1: 'Une société avec le même numéro de TVA est déjà existante sur MyPark',
        form_error_update_company: 'Une erreur c\'est produite lors de la mise à jour de la société',
        form_error_create_subsidiary: 'Une erreur c\'est produite lors de la création de la filiale',
        add_subsidiary_error_1: 'Ce numéro de TVA appartient à une autre société déjà existante sur MyPark',
        add_subsidiary_error_2: 'Une filiale avec le même numéro de TVA est déjà existante dans la société',
        form_error_update_subasidiary: 'Une erreur c\'est produite lors de la mise à jour de la filiale',
        form_error_create_site: 'Une erreur c\'est produite lors de la création du site',
        form_error_update_site: 'Une erreur c\'est produite lors de la mise à jour du site',
        select_city: 'Sélectionnez une ville',
        select_zip_code: 'Sélectionnez un code postal',
        min_lenght_14: 'Cette valeur ne doit pas faire moins de 14 caractères',
        max_lenght_14: 'Cette valeur ne doit pas dépasser 14 caractères',
        send: 'Envoyer',
        forgot_password: 'Mot de passe oublié',
        request_success: 'Votre demande à été prise en compte, un formulaire de réinitialisation de votre mot de passe vous a été transmis par e-mail.',
        reset_error_1: 'Veuillez remplir le champ email.',
        reset_error_2: 'Ce compte n\'éxiste pas.',
        reset_error_3: 'Une erreur est survenue lors de l\'envoi de l\'email, veuillez réessayer ultérieurement',
        account: 'Mon Compte',
        save: 'Sauvegarder',
        saved: 'Sauvegardé',
        ROLE_ADMIN: 'Administrateur',
        ROLE_COMPANY_MANAGER: 'Société Manageur',
        ROLE_SUBSIDIARY_MANAGER: 'Filiale Manageur',
        ROLE_SITE_MANAGER: 'Site Manageur',
        ROLE_OPERATOR: 'Opérateur',
        machine_list_error: 'Une erreur est survenue lors de la récupération de la liste des machines.',
        no_machine: 'Aucune machine trouvé',
        add_machine: 'Ajouter une machine',
        deactivate_machine: 'Désactiver cette machine',
        popup_deactivate_machine: 'Êtes vous sûr de vouloir désactiver cette machine ?',
        edit_machine: 'Éditer la machine',
        import_machine: 'Importer des machines',
        export_machine: 'Exporter les machines',
        form_error_create_machine: 'Une erreur c\'est produite lors de la création de la machine',
        form_error_update_machine: 'Une erreur c\'est produite lors de la mise à jour de la machine',
        import: 'Importer',
        export: 'Exporter',
        popup_import_machine: 'Importer des machines ( Uploadez une liste au format csv ).',
        popup_export_machine: 'Exporter les machines ( Sélectionnez le site pour lequel vous souhaitez exporter les machines ).',
        form_success_import_machines: 'Machines importées avec succès',
        form_error_import_machines_1: 'Une erreur est survenue lors de l\'import, veuillez réessayer ultérieurement.',
        form_error_import_machines_2: 'Une erreur est survenue lors de l\'import, veuillez réessayer ultérieurement.',
        form_error_import_machines_3: 'Le format du fichier est incorrect, veuillez réessayer avec le bon format.',
        model: 'Modèle',
        brand: 'Constructeur',
        serial_number: 'N° Serie',
        park_number: 'N° Parc',
        year: 'Année',
        comment: 'Commentaire',
        add_piece: 'Ajouter une pièce',
        add_part: 'Ajouter une partie',
        history: 'Historique',
        engine: 'Moteur',
        kilometer: 'Kilométrage',
        number_hours: 'Nombre d\'heures',
        move_machine: 'Déplacer cette machine',
        form_error_create_part: 'Une erreur c\'est produite lors de la création de la partie',
        form_error_update_part: 'Une erreur c\'est produite lors de la mise à jour de la partie',
        update_part: 'Modifier cette partie',
        delete_part: 'Supprimer cette partie',
        family: 'Famille',
        select_family: 'Sélectionnez une famille',
        select_brand: 'Sélectionnez un constructeur',
        select_model: 'Sélectionnez un modèle',
        select_engine: 'Sélectionnez un moteur',
        additionals_informations: 'Informations additionnelles',
        arrived_on: 'Arrivée le',
        departure_on: 'Départ le',
        history_list_error: 'Une erreur est survenue lors de la récupération de l\'historique.',
        no_history: 'Aucun historique trouvé',
        machine: 'Machine',
        popup_move_machine: 'Sélectionnez un site dans lequel déplacer cette machine.',
        move: 'Déplacer',
        update_see: 'Voir / Modifier',
        unity_type: 'Type d\'unité',
        params: 'Paramètres',
        add_kit: 'Ajouter un kit',
        pieces: 'Pieces',
        form_error_create_kit: 'Une erreur c\'est produite lors de la création du kit',
        form_error_update_kit: 'Une erreur c\'est produite lors de la mise à jour du kit',
        piece_machine_list_error: 'Une erreur est survenue lors de la récupération des pièces de la machine.',
        piece_list_error: 'Une erreur est survenue lors de la récupération des pièces.',
        no_piece: 'Aucune pièce trouvé',
        piece_added: 'Pièces ajoutées',
        no_piece_add: 'Aucune pièce ajoutée',
        remove: 'Retirer',
        add_pieces: 'Ajouter des pièces',
        sku: 'Sku',
        sku_tp: 'Sku Tp',
        image: 'Image',
        kit_list_error: 'Une erreur est survenue lors de la récupération des kits.',
        no_kit: 'Aucun kit trouvé.',
        products: 'Produits',
        docs: 'Documents',
        import_doc: 'Importer un document',
        doc: 'Document',
        form_error_import_document: 'Une erreur est survenue lors de l\'import du document, veuillez réessayer ultérieurement.',
        no_doc: 'Aucun document',
        price: 'Prix',
        quantity: 'Quantité',
        create_quote: 'Créer un devis',
        no_quote: 'Aucun devis trouvé',
        quote_list_error: 'Une erreur est survenue lors de la récupération de la liste des devis.',
        no_order: 'Aucune Commande trouvé',
        order_list_error: 'Une erreur est survenue lors de la récupération de la liste des commandes.',
        comments: 'Commentaires',
        create: 'Créer',
        form_error_create_quote: 'Une erreur c\'est produite lors de la création du devis',
        form_error_update_quote_1: 'Une des pièces du devis présente un prix à 0€, l\'approbation de ce devis est donc impossible.',
        form_error_update_quote: 'Une erreur c\'est produite lors de la mise à jour du devis',
        billing_address: 'Adresse de facturation',
        delivery_address: 'Adresse de livraison',
        no_product: 'Aucun produit trouvé',
        product_list_error: 'Une erreur est survenue lors de la récupération de la liste des produits.',
        order_all_pieces: 'Commander les pièces séléctionnées',
        order_all_kits: 'Commander tous les kits',
        current_quote: 'Devis en cours',
        select_popup_quote: 'Sélectionnez le site pour lequel vous souhaitez ajouter ce(s) produit(s).',
        select_quote: 'Sélectionnez un devis',
        or: 'Ou',
        created_at: 'Créé le',
        updated_at: 'Mis à jour le',
        delivery_method: 'Méthode de livraison',
        total_price_incl_tax: 'Prix total TTC',
        address_2: 'Complément d\'adresse',
        select_address: 'Sélectionnez une adresse',
        tax_rate: 'TVA',
        original_unit_price: 'Prix unitaire',
        row_total_price: 'Prix total',
        request_validation: 'Envoyer en validation',
        draft: 'Brouillon',
        pending: 'En attente de validation',
        complete: 'Approuvé',
        refuse: 'Refusé',
        just_validated: 'Validé',
        validated: 'Validé / En attente de confirmation',
        confirm: 'Approuver',
        summary: 'Résumé',
        total_price: 'Prix HT',
        popup_request_validation: 'Êtes-vous sûr de vouloir envoyer ce devis en validation ?',
        popup_remove_product: 'Êtes-vous sûr de vouloir retirer ce produit du devis ?',
        popup_confirm_validation: 'Êtes-vous sûr de vouloir valider ce devis ?',
        popup_confirm_confirmation: 'Êtes-vous sûr de vouloir approuver ce devis ?',
        refused: 'Refuser',
        popup_validate_quote: 'Complétez les informations ci-dessous pour valider le devis.',
        popup_refuse_quote: 'Êtes-vous sûr de vouloir refusez ce devis ?',
        popup_confirm_quote: 'Veuillez confirmer ou modifier la méthode de livraison',
        select_status: 'Sélectionnez un état',
        select_delivery_method: 'Sélectionnez une méthode de livraison',
        form_error_shipping_method: 'Une erreur est survenue lors de la validation, veuillez réessayer ultérieurement.',
        select_product_brand: 'Sélectionnez une marque',
        product_brand: 'Marque',
        select_product_category: 'Sélectionnez une catégorie',
        product_category: 'Catégorie',
        product_compatibility: 'N\'afficher que les pièces compatibles',
        include_product: 'Contient la pièce',
        select_product: 'Sélectionnez une pièce',
        popup_delete_piece: 'Êtes-vous sûr de vouloir supprimer cette pièce de cette machine ?',
        popup_delete_kit: 'Êtes-vous sûr de vouloir supprimer ce kit de cette machine ?',
        popup_delete_document: 'Êtes-vous sûr de vouloir supprimer ce document de cette machine ?',
        close: 'Fermer',
        delete_quote: 'Supprimer le devis',
        add_ref: 'Ajouter des références',
        form_success_import_items: 'Références importées avec succès',
        form_error_import_items_1: 'Une erreur est survenue lors de l\'import, veuillez réessayer ultérieurement.',
        form_error_import_items_2: 'Quantité invalide pour la référence',
        form_error_import_items_3: 'Référence inconnue',
        popup_import_items: 'Importer des références à partir d\'un fichier CSV', 
        refs: 'Références',
        infos_refs_csv: 'Le fichier doit contenir 3 colonnes (NUMERO_SERIE_MACHINE, SKU, QUANTITÉ) et chaque valeur doit être séparé par un ","',
        infos_machines_csv: 'Le fichier doit contenir 10 colonnes () et chaque valeur doit être séparé par un ","',
        popup_delete_quote: 'Êtes-vous sûr de vouloir supprimer ce devis ?',
        popup_delete_user: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
        delete_user: 'Supprimer l\'utilisateur',
        form_error_delete_quote: 'Une erreur est survenue lors de la suppression de ce devis, veuillez réessayer ultérieurement.',
        delivery_price: 'Frais de livraison',
        form_error_add_item: 'Une erreur est survenue lors de l\'ajout du produit, veuillez réessayer ultérieurement.',
        validate_condition: 'Pour pouvoir valider ce devis, veuillez compléter les informations de livraison.',
        save_login: 'Se souvenir de moi',
        success_add_quote: 'Pièce(s) ajoutée(s) avec succès !',
        duplicate_quote: 'Dupliquer le devis',
        success_duplicate_quote: 'Devis dupliqué avec succès ! Attention, si un de vos devis est actuellement en brouillon, les produits ont été dupliqués sur ce devis.',
        new_address: 'Nouvelle adresse',
        n_company: 'Nombre de sociétés',
        n_subsidiary: 'Nombre de filiales',
        n_site: 'Nombre de sites',
        n_machine: 'Nombre de machines',
        quote_pending: 'Devis en attentes',
        quote_complete: 'Devis confirmés',
        notice: 'Notice d\'utilisation',
        back_request: 'Formulaire de demande de retour',
        guarantee_process: 'Condition de garantie',
        created_by: 'Créé par',
        validated_by: 'Validé par',
        select_custom_brand: 'Renseigner un nouveau constructeur',
        select_custom_model: 'Renseigner un nouveau modèle',
        to_validate: "À valider",
        machines_pending: "Machines en attente de validation",
        validate_machine: "Valider une machine",
        form_error_validate_machine: "Une erreur c'est produite lors de la validation de la machine",
        faq: 'FAQ',
        import_document: 'Importer un document',
        form_success_import_document: 'Document importé avec succès',
        import_pieces: 'Importer des pièces',
        form_success_import_pieces: 'Pièces importées avec succès',
        form_error_import_pieces_1: 'Une erreur est survenue lors de l\'import, veuillez réessayer ultérieurement.',
        form_error_import_pieces_2: 'Une erreur est survenue lors de l\'import, veuillez réessayer ultérieurement.',
        popup_import_piece: 'Importer des pieces ( Uploadez une liste au format csv ).',
        infos_pieces_csv: 'Le fichier doit contenir 4 colonnes (SKU, NOM, PRIX, RÉFÉRENCE FOURNISSEUR) et chaque valeur doit être séparé par un ","',
        popup_add_piece: 'Ajouter une piece',
        form_success_add_piece: 'Pièce ajouté avec succès',
        form_error_add_piece: 'Une erreur est survenue lors de l\'ajout, veuillez réessayer ultérieurement.',
        form_error_add_pieces: 'Une erreur est survenue lors de l\'ajout, veuillez réessayer ultérieurement.',
        popup_error_add_piece: 'Une erreur est survenue lors de l\'ajout, veuillez réessayer ultérieurement.',
        popup_error_add_piece_1: 'Une erreur est survenue lors de l\'ajout, veuillez réessayer avec une autre référence car celle-çi est déjà utilisé',
        ref_command_intern: 'Ref Interne',
        date_start: 'Date début',
        date_end: 'Date fin',
        is_in_stock: 'En stock',
        yes: 'oui',
        no: 'non',
        create_piece: 'Créer une pièce',
        in_stock: 'En stock',
        no_stock: 'Sur commande',
        popup_not_delete_site: 'Pour pouvoir supprimer ce site vous devez supprimer/déplacer les machines associées et supprimer/déplacer les utilisateurs associés.',
        popup_not_delete_subsidiary: 'Pour pouvoir supprimer cette filiale vous devez supprimer/déplacer les machines associées et supprimer/déplacer les utilisateurs associés.',
        popup_not_delete_company: 'Pour pouvoir supprimer cette société vous devez supprimer/déplacer les machines associées et supprimer/déplacer les utilisateurs associés.',
        configurations: 'Configurations',
        reference_change: 'Changer une référence',
        reference_history: 'Historique des changements',
        old_reference: 'Ancienne référence',
        new_reference: 'Nouvelle référence',
        model_change: 'Changer un modèle',
        model_history: 'Historique des changements',
        old_model: 'Ancien modèle',
        new_model: 'Nouveau modèle',
        backup: 'Backup',
        success_change_reference: 'Référence modifié avec succès !',
        success_change_model: 'Model modifié avec succès !',
        success_error_reference: 'Une erreur est survenue lors du changement de référence, veuillez réessayer ultérieurement.',
        success_error_model: 'Une erreur est survenue lors du changement de model, veuillez réessayer ultérieurement.',
        success_error_model_1: 'Aucune machines n\'est impacté par ce changement de modèle',
        user_backup: 'Utilisateur backup',
        massive_machines_update: 'Validation massive',
        form_success_massive_update: 'Les machines séléctionnées ont été validé avec succès',
        form_error_massive_update: 'Une erreur est survenue lors de la validation massive des machines séléctionnées, veuillez réessayer ultérieurement.',
        form_popup_massive_update: 'machine(s) sélectionnée(s) :',
        version : 'Version',
        add_products_to_machines : 'Ajouter des produits aux machines',
        form_success_massive_machine_product_update: 'Les produits sélectionnés ont été ajoutés aux machines avec succès', 
        update_brand: 'Modifier le constructeur',
        update_model: 'Modifier le modèle',
        manufacturer_sku: 'Référence constructeur',
        supplier_sku: 'Référence fournisseur',
    }   
}