import React, { useState, useContext, useEffect } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    Button,
    Box,
    Message
} from '../../components';
import { post, get } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';

function SubsidiaryAdd() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ country, setCountry ] = useState('FR');
    const [ countries, setCountries ] = useState([
        {label:'Sélectionnez un pays', value:''},
    ]);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);

    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
            let result = await get('/magento/countries');

            if(result.success){
                let dataCountries = result.data
                let newCountries = [{label:'Sélectionnez un pays', value:''}];
                if(dataCountries && dataCountries.length){
                    dataCountries.forEach(country => {
                        newCountries.push({value: country.id, label: country.full_name_locale})
                    });
                }

                setCountries(newCountries);
            }

            setValue('country', 'FR');
        }

        if(!countries || countries.length <= 1){
            load();
        }
        
    }, [countries])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let c = countries.find(e => e.label === data.country);

        let params = {
            ...data,
            company: menu.company,
            country_full_name: c && c.label ? c.label : null
        }

        const result = await post("/subsidiary/create", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/subsidiaries")
        } else {

            if(result.hasOwnProperty("error")) {
                switch (result.error) {
                    case 'err_01':
                        setErrorMsg(Text("add_subsidiary_error_1", lang));
                        break;
                    case 'err_02':
                        setErrorMsg(Text("add_subsidiary_error_2", lang));
                        break;
                    case 'invalid_grant':
                        setUser({loggedIn: false});
                        break;
                    default:
                        setErrorMsg(Text("form_error_create_subsidiary", lang))
                }
            }
            
            
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            }
        }
    };

    return (
        <ScreenContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('add_subsidiary', lang)}</h1>
                    </div>
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="mb-4 md:mb-0 flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="name"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.name}
                            className="mb-6"
                        >
                            {Text('name', lang)}
                        </TextField>
                        <TextField
                            name="siret"
                            options={{ 
                                required: Text('required', lang), 
                                minLength: {
                                    value: 14,
                                    message: Text('min_lenght_14', lang) 
                                },
                                maxLength: {
                                    value: 14,
                                    message: Text('max_lenght_14', lang) 
                                },
                            }}
                            register={register}
                            error={errors?.siret}
                            className="mb-6"
                        >
                            {Text('siret', lang)}
                        </TextField>
                        <TextField
                            name="tva_number"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.tva_number}
                            className="mb-6"
                        >
                            {Text('tva_number', lang)}
                        </TextField>
                        <TextField
                            name="phone"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.phone}
                            className="mb-6"
                        >
                            {Text('phone', lang)}
                        </TextField>
                        <TextField
                            name="address"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.address}
                            className="mb-6"
                        >
                            {Text('address', lang)}
                        </TextField>
                        <TextField
                            name="address_2"
                            options={{}}
                            register={register}
                            error={errors?.address_2}
                            className="mb-6"
                        >
                            {Text('address_2', lang)}
                        </TextField>
                        <TextField
                            name="city"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.city}
                            className="mb-6"
                        >
                            {Text('city', lang)}
                        </TextField>
                        <TextField
                            name="zip_code"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.zip_code}
                            className="mb-6"
                        >
                            {Text('zip_code', lang)}
                        </TextField>
                        <SelectField
                            name="country"
                            value={country}
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.country}
                            className="mb-6"
                            choices={countries}
                            search
                        >
                            {Text('country', lang)}
                        </SelectField>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default SubsidiaryAdd;