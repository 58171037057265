import React, { useState, useRef, useEffect } from 'react';

function SelectField({ children, name, inputClassName, className, options, register, placeholder, onChange, value, choices, error, extraContent, search, setValue, searchByRequest, searchValue}) {
    const [ press, setPress ] = useState(false);
    const [ list, setList ] = useState(choices);
    const [ nativeValue, setNativeValue] = useState(value ? value : null);
    const [ visible, setVisible ] = useState(true);

    const selectRef = useRef();

    className = "form-field relative " + className;

    const inputRegister = register ? register(name, options) : false;

    useEffect(() => {
        if(value) {
            setNativeValue(value);
        } else {
            if(choices && choices.length > 0) {
                setNativeValue(choices[0].value);
            }
        }
    }, [value, choices])

    useEffect(() => {
        setList(choices);
    }, [choices])

    const handleOnChange = (e) => {
        if(typeof onChange !== "undefined") {
            onChange(e);
        }
        
        if(inputRegister){
            inputRegister.onChange(e);
            setValue(name, e.target.value);
        }

        setVisible(false);
    }

    const searchChange = (e) => {
        if(!searchByRequest){
            let char = e.toLowerCase();
            let search = choices.filter(element => element.label.toLowerCase().includes(char));
            setList(search);
        }else{
            let char = e.toLowerCase();
            let type = null;
            searchValue(char, type);
        }
       
    }

    const onBlur = (e) => {
        if(!e.relatedTarget || (e.relatedTarget.id && e.relatedTarget.id !== 'input-select-search-'+name && !e.relatedTarget.id.includes('select-value-'+name))){
            setPress(false);
        }
    }

    const selectValue = (e) => {
        setNativeValue(e);

        if(typeof onChange !== "undefined") {
            onChange({target: {value: e}});
        }
        
        if(inputRegister){
            inputRegister.onChange({target: {value: e}});
            setValue(name, e);
        }

        setVisible(false);
        setPress(false);
    }

    let isRequired = options && options.hasOwnProperty("required");
    if (isRequired) {
        className += " required";
    }

    let errorMessage = error ? error.message : null;

    if(choices && choices.length >= 1){
        return (
            <div className={className}>
                <div className="relative zindex-1000">
                    <div className="flex flex-row justify-between mb-2.5">
                        {children ? (
                            <label
                                htmlFor={"input-" + name}
                                className=" block font-bold text-xs"
                            >
                                {children.toUpperCase()}
                            </label>
                        ) : null}
                        {extraContent ? extraContent : !isRequired && register? (<span className="font-bold text-xs opacity-50">Facultatif</span>) : null}
                    </div>
                    {search ? (
                        <select 
                            ref={selectRef}
                            id={"input-" + name} 
                            className={ inputClassName ? inputClassName : "bg-primary-50 border-primary-200 border-solid outline-none border py-2 px-4 mr-10 rounded-lg focus:bg-white focus:border-2 focus:border-primary w-full"}
                            defaultValue={nativeValue}
                            value={nativeValue}
                            onChange={handleOnChange}
                            onFocus={() => setPress(true)}
                            onBlur={(e) => onBlur(e)}
                            name={inputRegister.name}
                            autoComplete={"off"}
                        > 
                            { placeholder && visible ? (
                                <option value="" hidden={search}>{placeholder}</option>
                            ) : null}
                            { choices.map((choice, i) => (
                                <option key={i} value={choice.value} hidden={search}>{choice.label}</option>
                            ))}
                        </select>
                    ) : (
                        <select     
                            ref={selectRef}
                            id={"input-" + name} 
                            className={ inputClassName ? inputClassName : "bg-primary-50 border-primary-200 border-solid outline-none border py-2 px-4 mr-10 rounded-lg focus:bg-white focus:border-2 focus:border-primary w-full"}
                            defaultValue={nativeValue}
                            onChange={handleOnChange}
                            onFocus={() => setPress(true)}
                            onBlur={(e) => onBlur(e)}
                            name={inputRegister.name}
                            autoComplete={"off"}
                        > 
                            { placeholder && visible ? (
                                <option value="" hidden={search}>{placeholder}</option>
                            ) : null}
                            { choices.map((choice, i) => (
                                <option key={i} value={choice.value} hidden={search}>{choice.label}</option>
                            ))}
                        </select>
                    )}
                    
                    
                    { press && search ? (
                        <div className="absolute flex flex-col z-10000000 w-full bg-secondary p-4 rounded">
                            <input
                                id={"input-select-search-"+name}
                                type={"text"}
                                className="bg-secondary border-primary border-solid border w-full py-2 px-4 outline-none rounded focus:bg-white focus:border-2 focus:border-primary mb-2"
                                placeholder={"Rechercher"}
                                onChange={(e) => searchChange(e.target.value)}
                                onBlur={(e) => onBlur(e)}  
                                autoComplete={"off"}
                            />
                            <div className="max-h-40 overflow-auto">
                        
                                { placeholder && visible ? (
                                    <button type="button" id={"select-value-"+name+"-"} className="cursor-pointer mb-2 flex hover:bg-button-300 hover:text-secondary p-2 rounded w-full" onClick={() => selectValue("")}>{placeholder}</button>
                                ) : null}
                                {list.map((element, i) => (
                                    <button key={i} type="button" id={"select-value-"+name+"-"+element.value} className="cursor-pointer mb-2 flex hover:bg-button-300 hover:text-secondary p-2 rounded w-full" onClick={() => selectValue(element.value)}>{element.label}</button>
                                ) )}
                            </div>
                        </div>
                    ) : null}
                </div>
                {errorMessage ? <span className="text-sm text-status-100">{errorMessage}</span> : null}
            </div>
        )
    }

    return null;
    
}

export default SelectField;