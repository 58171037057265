import React, { useContext } from "react";
import { Button, BaseMenu } from "..";
import { useHistory } from 'react-router-dom';
import { LangContext } from "../../contexts/LangContext";
import { MenuContext } from "../../contexts/MenuContext";
import Text from '../../utils/text';

function SiteManagerMenu() {
    const history = useHistory();
    const [lang] = useContext(LangContext);
    const [menu] = useContext(MenuContext);

    return (
        <BaseMenu>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/dashboard')} >
                <i className={"icon-dashboard text-2xl mr-2.5"}></i>
                {Text('dashboard', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/users')} >
                <i className={"icon-user text-2xl mr-2.5"}></i>
                {Text('users', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/site/'+ menu.site)}>
                <i className={"icon-site text-2xl mr-2.5"}></i>
                {Text('site', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/machines')}>
                <i className={"icon-machine text-2xl mr-2.5"}></i>
                {Text('machines', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/quotes')}>
                <i className={"icon-quote text-2xl mr-2.5" }></i>
                {Text('quotes', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/orders')}>
                <i className={"icon-order icon-md text-2xl mr-2.5" }></i>
                {Text('orders', lang)}
            </Button>
        </BaseMenu>
    );
}

export default SiteManagerMenu;