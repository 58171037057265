import React, { useEffect, useState } from "react";

import Router from "./navigation/Router";

import logo from './logo.png';
import { UserProvider } from "./contexts/UserContext";
import { MenuProvider } from "./contexts/MenuContext";
import { LangProvider } from "./contexts/LangContext";
import { QuoteProvider } from "./contexts/QuoteContext";

function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(function() {
            setIsLoading(false);
        }, 300);
    }, []);


    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-white flex-col h-screen">
                <img src={logo} alt="logo" width="60" className="mr-4" />
                <span className="text-tiny block mt-5">
                    Chargement...
                </span>
            </div>
        );
    }

    return (
        <LangProvider>
            <UserProvider>
                <MenuProvider>
                    <QuoteProvider>
                        <Router></Router>
                    </QuoteProvider>
                </MenuProvider>
            </UserProvider>
        </LangProvider>
    );
}

export default App;
