import React, { useState, useEffect } from 'react';

const MenuContext = React.createContext([{}, () => {}]);

const MenuProvider = ({children}) => {

    const [menu, setMenu] = useState(false);

    useEffect(() => {
        // console.log('MENU', menu.context);
        // console.log('localStorage', JSON.parse(localStorage.getItem("park-menu")).context);
        if(!menu){
            let newMenu = JSON.parse(localStorage.getItem("park-menu"));
            if(!newMenu){
                newMenu = {
                    toggle: true, 
                    company: '',
                    subsidiary: '', 
                    site: '',
                    companies: [],
                    subsidiaries: [],
                    sites: [],
                    indexer: [],
                    quote: false,
                    init: false,
                    context: false
                }
            }

            localStorage.setItem("park-menu", JSON.stringify(newMenu));
            setMenu(newMenu);
        } else {
            localStorage.setItem("park-menu", JSON.stringify(menu));
        }
    }, [menu])

    return (
        <MenuContext.Provider value={[menu, setMenu]}>
            {children}
        </MenuContext.Provider>
    );
}

export { MenuContext, MenuProvider };