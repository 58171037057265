import React, { useState, useContext, useEffect} from "react";

import { ScreenContainer, Tab, TextField, Box, SelectField, DateField } from '../../components';
import { useHistory } from 'react-router-dom';
import { MenuContext } from '../../contexts/MenuContext';
import { LangContext } from '../../contexts/LangContext';
import { QuoteContext } from '../../contexts/QuoteContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import getStatus from '../../utils/status';
import { getDocumentUrl, getAuthToken, get } from '../../server/api';

function Quotes() {
    const history = useHistory();
    const [ menu ] = useContext(MenuContext);
    const [ lang ] = useContext(LangContext);
    const [ quote, setQuote] = useContext(QuoteContext);
    const [ user ] = useContext(UserContext);

    const [ search, setSearch ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ subsidiary, setSubsidiary ] = useState('');
    const [ site, setSite ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ dateStart, setDateStart ] = useState('');
    const [ dateEnd, setDateEnd ] = useState('');

    const [ companies, setCompanies ] = useState([{value: '', label: Text('select', lang)}]); 
    const [ subsidiaries, setSubsidiaries ] = useState([{value: '', label: Text('select', lang)}]); 
    const [ sites, setSites ] = useState([{value: '', label: Text('select', lang)}]); 

    useEffect(() => {
        const load = async () =>{
            let data = {
                company,
                subsidiary,
                site,
                all: true
            }

            let dataCompanies = await get('/companies', data);
            if(dataCompanies && dataCompanies.success){
                let newCompanies = [{value: '', label: Text('select', lang)}];
                dataCompanies.items.forEach(element => {
                    newCompanies.push({value: element.id, label: element.name})
                });
                setCompanies(newCompanies);
            }
            let dataSubsidiaries = await get('/subsidiaries', data);
            if(dataSubsidiaries && dataSubsidiaries.success){
                let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                dataSubsidiaries.items.forEach(element => {
                    newSubsidiaries.push({value: element.id, label: element.name})
                });
                setSubsidiaries(newSubsidiaries);
            }
            let dataSites = await get('/sites', data);
            if(dataSites && dataSites.success){
                let newSites = [{value: '', label: Text('select', lang)}];
                dataSites.items.forEach(element => {
                    newSites.push({value: element.id, label: element.name})
                });
                setSites(newSites);
            }
        }

        if(user.role === 'ROLE_ADMIN'){
            load();
        }

    }, [user, company, subsidiary, site])

    const handleAction = (action, element) => {
        let url = false;
        switch (action) {
            case 'quote':
                if(element.hasOwnProperty('quote') && element.quote){
                    url = getDocumentUrl('/document/quotes/'+ element.id +'/'+ getAuthToken() + '/' + element.quote);
                }
                
                if(url){
                    window.open(url);
                }
                break;
            default:
                setQuote({
                    id: element.id,
                    name: element.name
                });
                url = "/quote/"+element.id;
                history.push(url);
                break;
        }
        
    }

    const renderStatus = (e) => {
        return getStatus(e, lang);
    }

    const config = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
            // {
            //     name: "delivery_method",
            //     label:  Text('delivery_method', lang),
            // },
            {
                name: "total_price_incl_tax",
                label: Text('total_price_incl_tax', lang),
            },
            {
                name: "status",
                label: Text('status', lang),
                render: renderStatus
            },
            {
                name: "created_at",
                label: Text('created_at', lang),
            },
            // {
            //     name: "updatedAt",
            //     label: Text('updated_at', lang),
            // },
            {
                name: "createdBy",
                label: Text('created_by', lang),
            },
            {
                name: "validatedBy",
                label: Text('validated_by', lang),
            },
            
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "quote/:id",
                active: !(user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('update_quote')))
            },
            {
                label: <i className={"icon-doc text-l"}></i>,
                name: "quote",
                active: true
            },
        ],
        error: Text('quote_list_error', lang),
        no_results: Text('no_quote', lang),
        fetchUrl: '/quotes',
        handleAction: handleAction,
        params: { 
            search,
            company: menu.context === 'TOPAZ' ? company : menu.company, 
            subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary, 
            site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site, 
            status: status,
            start_date: dateStart,
            end_date: dateEnd
        }
    };

    return (
        <ScreenContainer>
            <div className="flex items-center w-full">
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('quotes', lang)}</h1>
                </div>
            </div>
            <div className="flex">
                <Box className="mr-4 w-1/5">
                    <TextField className="mb-4" onChange={(e) => setSearch(e.target.value)}>
                        {Text('search', lang)}
                    </TextField>
                    { menu.context !== 'TOPAZ' ? null : (
                        <SelectField 
                            className="mb-4" 
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            choices={companies}
                            search
                        >
                            {Text('company', lang)}
                        </SelectField>
                    )}
                    { menu.subsidiary !== '' ? null : (
                        <SelectField 
                            className="mb-4" 
                            value={subsidiary}
                            onChange={(e) => setSubsidiary(e.target.value)}
                            choices={ menu.context === 'TOPAZ' ? subsidiaries : menu.subsidiaries}
                        >
                            {Text('subsidiary', lang)}
                        </SelectField>
                    )}
                    { menu.site !== '' ? null : (
                        <SelectField 
                            className="mb-4" 
                            value={site}
                            onChange={ (e) => setSite(e.target.value)}
                            choices={ menu.context === 'TOPAZ' ? sites : menu.sites}
                        >
                            {Text('site', lang)}
                        </SelectField>
                    )}
                    <SelectField 
                        className="mb-4" 
                        value={status}
                        onChange={ (e) => setStatus(e.target.value)}
                        choices={[
                            {value: '', label: Text('select', lang)},
                            {value: 'draft', label: Text('draft', lang)},
                            {value: 'pending', label: Text('pending', lang)},
                            {value: 'validated', label: Text('validated', lang)},
                            {value: 'complete', label: Text('complete', lang)},
                            {value: 'refuse', label: Text('refuse', lang)},
                        ]}
                    >
                        {Text('status', lang)}
                    </SelectField>
                    <DateField className="mb-4" onChange={(e) => setDateStart(e.target.value)}>
                        {Text('date_start', lang)}
                    </DateField>
                    { dateStart ? (
                        <DateField className="mb-4" onChange={(e) => setDateEnd(e.target.value)}>
                            {Text('date_end', lang)}
                        </DateField>
                    ) : null}
                </Box>
                <Box className="flex flex-1">
                    <Tab {...{config}} />
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Quotes;