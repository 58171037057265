import React, { useState } from "react";

function DefaultField({
    children,
    name,
    type = "text",
    inputClassName,
    className,
    placeholder,
    defaultValue,
    options,
    register,
    error,
    onChange,
    extraContent,
    min,
    value, 
    key
}) {
    className = "form-field relative " + (className ? className : '');

    let [tempType, setTempType] = useState(type);

    const toggleEye = () => {
        setTempType(tempType === "password" ? "text" : "password");
    };

    let errorMessage = error ? error.message : null;

    const inputRegister = register ? register(name, options) : false;
    
    const handleOnChange = (e) => {
        if (typeof onChange !== "undefined") {
            onChange(e);
        }

        if(inputRegister){
            inputRegister.onChange(e);
        }
    };

    let isRequired = options && options.hasOwnProperty("required");
    if (isRequired) {
        className += " required";
    }
    
    return (
        <div className={className} >
            <div className="relative">
                <div className="flex flex-row justify-between mb-2.5">
                    {children && type !== "hidden" ? (
                        <label
                            htmlFor={"input-" + name}
                            className=" block font-bold text-xs"
                        >
                            {children.toUpperCase()}
                        </label>
                    ) : null}
                    {extraContent ? extraContent : !isRequired && register ? (<span className="font-bold text-xs opacity-50">Facultatif</span>) : null}
                </div>
                <input
                    id={"input-" + (name ? name : key)}
                    key={key ? key : null}
                    type={tempType}
                    className={
                        inputClassName
                            ? inputClassName
                            : "bg-secondary border-primary border-solid border w-full py-2 px-4 outline-none rounded focus:bg-white focus:border-2 focus:border-primary"
                    }
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    onBlur={inputRegister.onBlur}
                    ref={inputRegister.ref}
                    name={inputRegister.name}
                    min={min}
                    autoComplete={"off"}
                    value={value ? value : defaultValue}
                />

                {type === "password" ? (
                    <button
                        type="button"
                        onClick={() => toggleEye()}
                        className="absolute right-0 py-2 px-4 bottom-0 outline-none"
                    >
                        <i
                            className={
                                tempType === "password"
                                    ? "icon-show text-primary-300"
                                    : "icon-hide text-primary-300"
                            }
                        ></i>
                    </button>
                ) : null}
            </div>

            {errorMessage ? (
                <span className="text-sm text-status-100">{errorMessage}</span>
            ) : null}
        </div>
    );
}

export default DefaultField;
