import Text from './text';

const getStock = (stock, qty, lang) => {
    let bg = "";

    switch (stock) {
        case 'in_stock':
            bg = "bg-status-300";
            break;

        default:
            bg = "bg-status-200";
            break;
    }

    return <span className={"p-2 rounded text-secondary font-bold inline-block "+bg}> { qty > 0 ? Text(stock, lang) +' : '+ qty : Text(stock, lang)} </span>;
};

export default getStock;