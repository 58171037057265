import React, { useState, useContext } from "react";

import { EmailField, PasswordField, Button} from '../components';
import { getToken, get } from '../server/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { LangContext } from '../contexts/LangContext';
import logo from '../logo.png';
import logoDubreuil from '../assets/images/logo-dubreuil.png';
import Text from '../utils/text';

function Login() {

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({defaultValues:{
        username: localStorage.getItem("park-save-login") ? JSON.parse(localStorage.getItem("park-save-login")).username : '',
        password: localStorage.getItem("park-save-login") ? JSON.parse(localStorage.getItem("park-save-login")).password : '',
    }});

    const [user, setUser] = useContext(UserContext);
    const [lang] = useContext(LangContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [saveLogin, setSaveLogin] = useState(null);

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        if(saveLogin){
            localStorage.setItem("park-save-login", JSON.stringify({username: data.username, password: data.password}));
        } else {
            if(localStorage.getItem("park-save-login")){
                localStorage.removeItem("park-save-login");
            }
        }

        const result = await getToken(data.username, data.password);
        setIsLoading(false);

        if (result.hasOwnProperty("error")) {
            switch (result.error) {
                case 'err_02':
                    setErrorMsg(Text("login_error_2", lang));
                    break;
                case 'err_03':
                    setErrorMsg(Text("login_error_3", lang));
                    break;
                case 'err_04':
                    setErrorMsg(Text("login_error_4", lang));
                    break;
                case 'err_05':
                    setErrorMsg(Text("login_error_5", lang));
                    break;
                default:
                    setErrorMsg(Text("login_error_1", lang));
                    break;
                
            }
            
            return { success: false };
        }

        localStorage.setItem("park-authToken", result.token);

        if(result.success){
            const profileResult = await get("/user");

            if (profileResult.success) {
                let newUser = profileResult.data;
    
                setUser((user) => ({
                    ...user,
                    loggedIn: true,
                    ...newUser,
                }));
            } else {
                setUser((user) => ({
                    loggedIn: false,
                }));
            }
        }
    };

    return (
        <div className="flex flex-col h-screen w-full">
            <div className="flex flex-1 w-full justify-center items-center bg-intro">
                <div className="flex flex-col w-1/2">
                    <div className="flex justify-center align-center mb-4">
                        <img src={logo} alt="logo" width={300}/>
                    </div>
                    <div className="border-b border-solid border-primary-100 pb-1 mb-6 flex items-center">
                        <i className="icon-user icon-md text-2xl text-primary mr-2.5"></i>
                        <span className="text-xl">{Text("login", lang)}</span>
                    </div>
                    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                        <EmailField
                            name="username"
                            options={{ required: Text("required", lang) }}
                            register={register}
                            error={errors?.username}
                            className="mb-6"
                            onChange={() => setErrorMsg(null)}
                        >
                            {Text("email", lang)}
                        </EmailField>
                        <PasswordField
                            name="password"
                            options={{ required: Text("required", lang) }}
                            register={register}
                            error={errors?.password}
                            className="mb-1"
                            onChange={() => setErrorMsg(null)}
                        >
                            {Text("password", lang)}
                        </PasswordField>
                        <div className="flex items-center">
                            <input type="checkbox" name="saveLogin" id="saveLogin" onChange={() => setSaveLogin(!saveLogin)} />
                            <label  className="ml-2" htmlFor="saveLogin">{Text('save_login', lang)}</label>
                        </div>
                        <div className="flex justify-end mb-8">
                            <Button
                                skin="transparent"
                                onPress={() => history.push("/password/forgot")}
                                className="text-secondary-100 text-xs font-bold mt-1"
                            >
                                Mot de passe oublié ?
                            </Button>
                        </div>
                        {errorMsg ? <p className="error mb-6">{errorMsg}</p> : null}
                        <div className="flex justify-end mt-8" >
                            <Button type="submit" isLoading={isLoading}>
                                {Text("connection", lang)}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex items-center bg-primary p-4">
                <img src={logoDubreuil} alt="logo" className="mr-4"/>
                <span className="text-secondary">Une entreprise du groupe - Dubreuil</span>
            </div>
        </div>
    );
}

export default Login;