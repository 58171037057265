import React, { useState, useContext, useEffect } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    Checkbox,
    Button,
    Box,
    Message
} from '../../components';
import { post } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import Text from '../../utils/text';

import Rights from '../../constants/Rights';

function UserAdd() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ user, setUser ] = useContext(UserContext);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);

    const history = useHistory();

    let roles = [
        {
            value:'ROLE_OPERATOR', 
            label:'Opérateur'
        },
    ]

    if(user && user.hasOwnProperty('role')){
        // eslint-disable-next-line default-case
        switch (user.role) {
            case 'ROLE_ADMIN':
                if(menu.context === 'TOPAZ'){
                    roles = [
                        {
                            value:'ROLE_ADMIN', 
                            label:'Administrateur'
                        },
                    ]
                } else {
                    roles = [
                        ...roles,
                        {
                            value:'ROLE_COMPANY_MANAGER', 
                            label:'Manager Société'
                        },
                        {
                            value:'ROLE_SUBSIDIARY_MANAGER', 
                            label:'Manager Filiale'
                        },
                        {
                            value:'ROLE_SITE_MANAGER', 
                            label:'Manager Site'
                        },
                    ]
                }
                break;
            
            case 'ROLE_COMPANY_MANAGER':
                roles = [
                    ...roles,
                    {
                        value:'ROLE_SUBSIDIARY_MANAGER', 
                        label:'Manager Filiale'
                    },
                    {
                        value:'ROLE_SITE_MANAGER', 
                        label:'Manager Site'
                    },
                ]
                break;

            case 'ROLE_SUBSIDIARY_MANAGER':
                roles = [
                    ...roles,
                    {
                        value:'ROLE_SITE_MANAGER', 
                        label:'Manager Site'
                    },
                ]
                break;
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        clearErrors,
        unregister
    } = useForm({
        defaultValues: {
            role: menu.context === 'TOPAZ' ? 'ROLE_ADMIN' : 'ROLE_OPERATOR',
            // add_address: true,
            // update_address: true,
            // delete_address: true,
            add_machine: true,
            update_machine: true,
            add_kit: true,
            update_kit: true,
            delete_kit: true,
            //add_quote: true,
            //update_quote: true,
            delete_quote: true,
            //validate_quote: false,
            see_order: true,
        }
    });

    const role = watch("role");

    useEffect(() => {
        clearErrors('site');
        clearErrors('subsidiary');

        switch (role) {
            case 'ROLE_ADMIN':
                unregister('site');
                unregister('subsidiary');
                break;
            case 'ROLE_COMPANY_MANAGER':
                unregister('site');
                unregister('subsidiary');
                break;

            case 'ROLE_SUBSIDIARY_MANAGER':
                unregister('site');
                break;

            case 'ROLE_SITE_MANAGER':
                unregister('subsidiary');
                break;

            default:
                unregister('subsidiary');
                break
        }
    }, [role])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            first_name: data.firstname,
            last_name: data.lastname,
            email: data.email,
            phone: data.phone,
            role: data.role,
        }

        // eslint-disable-next-line default-case
        switch (data.role) {
            case 'ROLE_COMPANY_MANAGER':
                params = {
                    ...params,
                    company: menu.company  
                }
                break;

            case 'ROLE_SUBSIDIARY_MANAGER':
                params = {
                    ...params,
                    subsidiary: menu.subsidiary ? menu.subsidiary : data.subsidiary
                }
                break;

            case 'ROLE_SITE_MANAGER':
                params = {
                    ...params,
                    site: menu.site ? menu.site : data.site
                }
                break;

            case 'ROLE_OPERATOR':
                let rights = [];

                Rights.forEach(R => {
                    if(data[R]){
                        rights.push(R);
                    }
                });

                params = {
                    ...params,
                    site: menu.site ? menu.site : data.site,
                    rights
                }
                break;
        }

        const result = await post("/user/create", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/users")
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_create_user', lang));
            }
        }
    };

    return (
        <ScreenContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('add_user', lang)}</h1>
                    </div>
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="mr-4 mb-4 md:mb-0 flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="lastname"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.lastname}
                            className="mb-6"
                        >
                            {Text('lastname', lang)}
                        </TextField>
                        <TextField
                            name="firstname"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.firstname}
                            className="mb-6"
                        >
                            {Text('firstname', lang)}
                        </TextField>
                        <TextField
                            name="email"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.email}
                            className="mb-6"
                        >
                            {Text('email', lang)}
                        </TextField>
                        <TextField
                            name="phone"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.phone}
                            className="mb-6"
                        >
                            {Text('phone', lang)}
                        </TextField>
                    </Box>
                    <Box className={"flex-1 "+ (role === 'ROLE_OPERATOR' ? "md:mr-4 mb-4 md:mb-0" : null)}>
                        <div className="mb-4">
                            <span className="text-xl title">{Text('role_affiliations', lang)}</span>
                        </div>
                        <SelectField
                            name="role"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.role}
                            className="mb-6"
                            choices={roles}
                            setValue={setValue}
                            value={menu.context === 'TOPAZ' ? 'ROLE_ADMIN' : 'ROLE_OPERATOR'}
                        >
                            {Text('role', lang)}
                        </SelectField>
                        { role === 'ROLE_SUBSIDIARY_MANAGER' ? (
                            <SelectField
                                name="subsidiary"
                                options={role === 'ROLE_SUBSIDIARY_MANAGER' ? { required: Text('required', lang) } : { required: false }}
                                register={register}
                                error={errors?.subsidiary}
                                className="mb-6"
                                choices={menu.subsidiaries}
                                setValue={setValue}
                            >
                                {Text('subsidiary', lang)}
                            </SelectField>
                        ) : null}
                        { role === 'ROLE_SITE_MANAGER' || role === 'ROLE_OPERATOR'? (
                            <SelectField
                                name="site"
                                options={role === 'ROLE_SITE_MANAGER' || role === 'ROLE_OPERATOR' ? { required: Text('required', lang) } : { required: false }}
                                register={register}
                                error={errors?.site}
                                className="mb-6"
                                choices={menu.sites}
                                setValue={setValue}
                            >
                                {Text('site', lang)}
                            </SelectField>
                        ) : null}
                    </Box>
                    { role === 'ROLE_OPERATOR' ? (
                        <Box className="flex-1">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('rights', lang)}</span>
                            </div>
                            {/* <div>
                                <span className="text-xl">{Text('users', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_user"
                                        register={register}
                                        error={errors?.add_user}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_user"
                                        register={register}
                                        error={errors?.update_user}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_user"
                                        register={register}
                                        error={errors?.delete_user}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div> */}
                            {/* <div>
                                <span className="text-xl">{Text('addresses', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_address"
                                        register={register}
                                        error={errors?.add_address}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_address"
                                        register={register}
                                        error={errors?.update_address}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_address"
                                        register={register}
                                        error={errors?.delete_address}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div> */}
                            <div>
                                <span className="text-xl">{Text('machines', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_machine"
                                        register={register}
                                        error={errors?.add_machine}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_machine"
                                        register={register}
                                        error={errors?.update_machine}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_machine"
                                        register={register}
                                        error={errors?.delete_machine}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('kits', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_kit"
                                        register={register}
                                        error={errors?.add_kit}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_kit"
                                        register={register}
                                        error={errors?.update_kit}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_kit"
                                        register={register}
                                        error={errors?.delete_kit}
                                        className="mb-6"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('quotes', lang)}</span>
                                <div className="flex">
                                    {/* <Checkbox
                                        name="add_quote"
                                        register={register}
                                        error={errors?.add_quote}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_quote"
                                        register={register}
                                        error={errors?.update_quote}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('update', lang)}
                                    </Checkbox> */}
                                    <Checkbox
                                        name="delete_quote"
                                        register={register}
                                        error={errors?.delete_quote}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                    {/* <Checkbox
                                        name="validate_quote"
                                        register={register}
                                        error={errors?.validate_quote}
                                        className="mb-6"
                                    >
                                        {Text('validate', lang)}
                                    </Checkbox> */}
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('orders', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="see_order"
                                        register={register}
                                        error={errors?.see_order}
                                        className="mb-6 mr-4"
                                    >
                                        {Text('see', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                        </Box>
                    ) : null}
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default UserAdd;