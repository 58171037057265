import React, { useState, useContext, useEffect} from "react";

import { ScreenContainer, Tab, Button, DateField, Box, SelectField } from '../../components';
import { useHistory } from 'react-router-dom';
import { MenuContext } from '../../contexts/MenuContext';
import { LangContext } from '../../contexts/LangContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { get, getAuthToken, getDocumentUrl } from '../../server/api';
import getStatus from '../../utils/statusOrder';

function Quotes() {
    const history = useHistory();
    const [ menu ] = useContext(MenuContext);
    const [ lang ] = useContext(LangContext);
    const [ user ] = useContext(UserContext);

    const [ dateStart, setDateStart ] = useState('');
    const [ dateEnd, setDateEnd ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ subsidiary, setSubsidiary ] = useState('');
    const [ site, setSite ] = useState('');

    const [ companies, setCompanies ] = useState([{value: '', label: Text('select', lang)}]);
    const [ subsidiaries, setSubsidiaries ] = useState([{value: '', label: Text('select', lang)}]);
    const [ sites, setSites ] = useState([{value: '', label: Text('select', lang)}]);

    useEffect(() => {
        const load = async () =>{
            let data = {
                company,
                subsidiary,
                site,
                all: true
            }

            let dataCompanies = await get('/companies', data);
            if(dataCompanies && dataCompanies.success){
                let newCompanies = [{value: '', label: Text('select', lang)}];
                dataCompanies.items.forEach(element => {
                    newCompanies.push({value: element.id, label: element.name})
                });
                setCompanies(newCompanies);
            }
            let dataSubsidiaries = await get('/subsidiaries', data);
            if(dataSubsidiaries && dataSubsidiaries.success){
                let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                dataSubsidiaries.items.forEach(element => {
                    newSubsidiaries.push({value: element.id, label: element.name})
                });
                setSubsidiaries(newSubsidiaries);
            }
            let dataSites = await get('/sites', data);
            if(dataSites && dataSites.success){
                let newSites = [{value: '', label: Text('select', lang)}];
                dataSites.items.forEach(element => {
                    newSites.push({value: element.id, label: element.name})
                });
                setSites(newSites);
            }
        }

        if(user.role === 'ROLE_ADMIN'){
            load();
        }

    }, [user, company, subsidiary, site])

    const handleAction = (action, element) => {
        let url = false;
        if(element.hasOwnProperty('document') && element.document){
            url = getDocumentUrl('/document/orders/'+ element.id +'/'+ getAuthToken() + '/' + element.document);
        }
        
        if(url){
            window.open(url);
        }
    }

    const renderStatus = (e) => {
        return getStatus(e, lang);
    }

    const config = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "user_firstname",
                label: Text('firstname', lang),
            },
            {
                name: "user_lastname",
                label: Text('lastname', lang),
            },
            {
                name: "status",
                label: Text('status', lang),
                //render: renderStatus
            },
            {
                name: "total_price",
                label: Text('total_price', lang),
            },
            {
                name: "total_price_incl_tax",
                label: Text('total_price_incl_tax', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
            {
                name: "created_at",
                label: Text('created_at', lang),
            },
        ],
        actions: [
            {
                label: <i className={"icon-doc text-l"}></i>,
                name: "facture",
                active: true
            },
        ],
        error: Text('order_list_error', lang),
        no_results: Text('no_order', lang),
        fetchUrl: '/orders',
        handleAction: handleAction,
        params: { 
            company: menu.context === 'TOPAZ' ? company : menu.company, 
            subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary, 
            site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site, 
            start_date: dateStart,
            end_date: dateEnd
        }
    };

    return (
        <ScreenContainer>
            <div className="flex items-center w-full">
                <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                    {Text('back', lang)}
                </Button>
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('orders', lang)}</h1>
                </div>
            </div>
            <div className="flex">
                { !menu.subsidiary || !menu.site ? (
                    <Box className="mr-4 w-1/5">
                        { menu.context !== 'TOPAZ' ? null : (
                            <SelectField 
                                className="mb-4" 
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                choices={companies}
                                search
                            >
                                {Text('company', lang)}
                            </SelectField>
                        )}
                        { menu.subsidiary !== '' ? null : (
                            <SelectField 
                                className="mb-4" 
                                value={subsidiary}
                                onChange={(e) => setSubsidiary(e.target.value)}
                                choices={menu.context === 'TOPAZ' ? subsidiaries : menu.subsidiaries}
                            >
                                {Text('subsidiary', lang)}
                            </SelectField>
                        )}
                        { menu.site !== '' ? null : (
                            <SelectField 
                                className="mb-4" 
                                value={site}
                                onChange={ (e) => setSite(e.target.value)}
                                choices={menu.context === 'TOPAZ' ? sites : menu.sites}
                            >
                                {Text('site', lang)}
                            </SelectField>
                        )}
                        <DateField className="mb-4" onChange={(e) => setDateStart(e.target.value)}>
                            {Text('date_start', lang)}
                        </DateField>
                        { dateStart ? (
                            <DateField className="mb-4" onChange={(e) => setDateEnd(e.target.value)}>
                                {Text('date_end', lang)}
                            </DateField>
                        ) : null}
                    </Box>
                ) : null}
                <Box className="flex flex-1">
                    <Tab {...{config}} />
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Quotes;