import React, { useContext, useState, useEffect} from "react";

import { ScreenContainer, Tab, Button, SelectField, Box, TextField } from '../../components';
import { LangContext } from '../../contexts/LangContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { useHistory } from 'react-router-dom';
import { get } from '../../server/api';

function Companies() {
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const [search, setSearch] = useState('');
    const [city, setCity]  = useState('');
    const [zipCode, setZipCode] = useState('');
    
    const history = useHistory();

    const [cityFilters, setCityFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [zipCodeFilters, setZipCodeFilters] = useState([{label: Text('select', lang), value: ''}]);

    useEffect(() => {
        const loadFilters = async () => {
            const params = {
                search,
                city,
                zip_code: zipCode
            }

            const result = await get('/company/filters', params);

            if(result.success){
                let filters = result.data;

                if(filters.hasOwnProperty('cities') && filters.cities.length){
                    let newCityFilters = [{label: Text('select', lang), value: ''}];

                    filters.cities.forEach((city) => {
                        newCityFilters.push(city);
                    })

                    setCityFilters(newCityFilters);
                }

                if(filters.hasOwnProperty('zip_codes') && filters.zip_codes.length){
                    let newZipCodeFilters = [{label: Text('select', lang), value: ''}];

                    filters.zip_codes.forEach((zip_code) => {
                        newZipCodeFilters.push(zip_code);
                    })

                    setZipCodeFilters(newZipCodeFilters);
                }

            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        loadFilters();
    }, [search, city, zipCode])

    const config = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "siret",
                label: Text('siret', lang),
            },
            {
                name: "tva_number",
                label: Text('tva_number', lang),
            },
            {
                name: "city",
                label: Text('city', lang),
            },
            {
                name: "zip_code",
                label: Text('zip_code', lang),
            },
            {
                name: "country",
                label: Text('country', lang),
            },
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "company/:id",
                active: true
            },
        ],
        error: Text('company_list_error', lang),
        no_results: Text('no_company', lang),
        fetchUrl: '/companies',
        params: {
            search,
            city,
            zip_code: zipCode
        }
    };

    return (
        <ScreenContainer>
            <div className="flex items-center w-full">
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('companies', lang)}</h1>
                </div>
                <Button className="mb-7.5" onPress={() => history.push('/company/add')}>
                    {Text('add_company', lang)}  
                </Button>
            </div>
            <div className="flex">
                <Box className="mr-4 w-1/5">
                    <TextField className="mb-4" onChange={(e) => setSearch(e.target.value)}>
                        {Text('search', lang)}
                    </TextField>
                    <SelectField 
                        className="mb-4" 
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        choices={cityFilters}
                    >
                        {Text('city', lang)}
                    </SelectField>
                    <SelectField 
                        className="mb-4"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        choices={zipCodeFilters}
                    >
                        {Text('zip_code', lang)}
                    </SelectField>
                </Box> 
                <Box className="flex flex-1">
                    <Tab {...{config}}/>
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Companies;