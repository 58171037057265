import React from "react";
import { Button } from "../components";
import { useHistory } from "react-router-dom";

function NotFound() {
    const history = useHistory();

    return (
        <div className="h-screen flex justify-center items-center flex-col text-center">
            <h1 className="text-3xl font-bold">Page introuvable</h1>
            <p className="mb-4">La page que vous recherchez n'existe pas.</p>
            <Button onPress={() => history.push('/dashboard')}>
                Retour à l'accueil
            </Button>
        </div>
    );
}

export default NotFound;