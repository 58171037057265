import Text from './text';

const getStatus = (status, lang) => {
    let bg = "";

    switch (status) {
        case 'pending':
            bg = "bg-status-200";
            break;
        case 'validated':
            bg = "bg-status-200";
            break;
        case 'complete':
            bg = "bg-status-300";
            break;
        case 'refuse':
            bg = "bg-status-100";
            break;
    
        default:
            bg = "bg-status";
            break;
    }

    return <span className={"p-2 rounded text-secondary font-bold inline-block "+bg}>{Text(status, lang)}</span>;
};

export default getStatus;