import React from 'react';

function Box({children, className, padding}) {
    let p = "p-7.5";

    if(padding){
        p = padding;
    }

    return (
        <div className={"rounded bg-grid border-t-2 border-line " + className + " " + p}>
           {children}
        </div>
    )
    
}

export default Box;