import React, { useState, useContext, useEffect } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    TextAreaField,
    Button,
    Box,
    Message,
    NumberField
} from '../../components';
import { post, get } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';

function MachineAdd() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectCustomBrand, setSelectCustomBrand ] = useState(false);
    const [ selectCustomModel, setSelectCustomModel ] = useState(false);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const history = useHistory();

    const [brandFilters, setBrandFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [modelFilters, setModelFilters] = useState([{label: Text('select', lang), value: ''}]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
        unregister
    } = useForm({
        defaultValues: {
            unity_type: 'hour'
        }
    });

    const brand = watch('brand');
    const unity_type = watch('unity_type');

    useEffect(() => {
        clearErrors('number_hours');
        unregister('kilometer');

        switch (unity_type) {
            case 'number_hours':
                unregister('kilometer');
                break;
            default:
                unregister('number_hours');
                break;
        }
    }, [unity_type])

    useEffect(() => {
        const loadBrands = async () => {
            const result = await get('/brands');

            if(result.success){
                let brands = result.data;

                if(brands && brands.length){
                    let newBrandFilters = [{label: Text('select', lang), value: ''}];

                    brands.forEach((brand) => {
                        newBrandFilters.push(brand);
                    })

                    setBrandFilters(newBrandFilters);
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        loadBrands();
    }, [])

    useEffect(() => {
        const loadModels = async () => {
            const params = {
                brand,
            }

            const result = await get('/models', params);

            if(result.success){
                let models = result.data;

                if(models && models.length){
                    let newModelFilters = [{label: Text('select', lang), value: ''}];

                    models.forEach((model) => {
                        newModelFilters.push(model);
                    })

                    setModelFilters(newModelFilters);
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        if(brand){
            loadModels();
        }
    }, [brand])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data,
            site: menu.site ? menu.site : data.site
        }

        const result = await post("/machine/create", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/machines")
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_create_machine', lang));
            }
        }
    };

    const setCustomBrand = () => {
        if(selectCustomBrand){
            unregister('custom_brand');
            unregister('custom_model');
        } else {
            unregister('brand');
            unregister('model');
        }
        
        setSelectCustomBrand(!selectCustomBrand);
    }

    const setCustomModel = () => {
        if(selectCustomModel){
            unregister('custom_model');
        } else {
            unregister('model');
        }
        setSelectCustomModel(!selectCustomModel)
    }

    return (
        <ScreenContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('add_machine', lang)}</h1>
                    </div>
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap">
                    <Box className="mr-4 mb-4 md:mb-0 flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        { menu.site !== '' ? null : (
                            <SelectField
                                name="site"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.site}
                                className="mb-6"
                                choices={menu.sites}
                                setValue={setValue}
                            >
                                {Text('site', lang)}
                            </SelectField>
                        )}
                        { !selectCustomBrand ? (
                            <SelectField
                                name="brand"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.brand}
                                className="mb-6"
                                choices={brandFilters}
                                setValue={setValue}
                                search
                            >
                                {Text('brand', lang)}
                            </SelectField>
                        ) : (
                            <TextField
                                name="custom_brand"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.custom_brand}
                                className="mb-6"
                            >
                                {Text('brand', lang)}
                            </TextField>
                        )}
                        <div className="flex justify-end">
                            <Button className="mb-7.5 mr-4" onPress={() => setCustomBrand()}>
                                {!selectCustomBrand ? Text('select_custom_brand', lang) : Text('select_brand', lang)}
                            </Button>
                        </div>
                        {brand && !selectCustomModel ? (
                            <SelectField
                                name="model"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.model}
                                className="mb-6"
                                choices={modelFilters}
                                setValue={setValue}
                                search
                            >
                                {Text('model', lang)}
                            </SelectField>
                        ) : null}
                        {(brand && selectCustomModel) || selectCustomBrand ? (
                            <TextField
                                name="custom_model"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.custom_brand}
                                className="mb-6"
                            >
                                {Text('model', lang)}
                            </TextField>
                        ) : null}
                        {!selectCustomBrand && brand ? (
                            <div className="flex justify-end">
                                <Button className="mb-7.5 mr-4" onPress={() => setCustomModel()}>
                                    {!selectCustomModel ? Text('select_custom_model', lang) : Text('select_model', lang)}
                                </Button>
                            </div>
                        ) : null}
                        
                        <TextField
                            name="engine"
                            options={{}}
                            register={register}
                            error={errors?.engine}
                            className="mb-6"
                        >
                            {Text('engine', lang)}
                        </TextField>
                        <TextField
                            name="serial_number"
                            options={{}}
                            register={register}
                            error={errors?.serial_number}
                            className="mb-6"
                        >
                            {Text('serial_number', lang)}
                        </TextField>
                        <TextField
                            name="park_number"
                            options={{}}
                            register={register}
                            error={errors?.park_number}
                            className="mb-6"
                        >
                            {Text('park_number', lang)}
                        </TextField>
                    </Box>
                    <Box className="mr-4 mb-4 md:mb-0 flex-1">
                        <TextField
                            name="year"
                            options={{}}
                            register={register}
                            error={errors?.year}
                            className="mb-6"
                        >
                            {Text('year', lang)}
                        </TextField>
                        <TextField
                            name="additionals_informations"
                            register={register}
                            className="mb-6"
                        >
                            {Text('additionals_informations', lang)}
                        </TextField>
                        <SelectField
                            name="unity_type"
                            value="hour"
                            options={{}}
                            register={register}
                            error={errors?.unity_type}
                            className="mb-6"
                            choices={[
                                {value: 'hour', label: Text('number_hours', lang)},
                                {value: 'kilometer', label: Text('kilometer', lang)},
                            ]}
                            setValue={setValue}
                        >
                            {Text('unity_type', lang)}
                        </SelectField>
                        {unity_type === 'kilometer' ? (
                            <NumberField
                                name="kilometer"
                                options={{}}
                                register={register}
                                error={errors?.kilometer}
                                className="mb-6"
                            >
                                {Text('kilometer', lang)}
                            </NumberField>
                        ) : (
                            <NumberField
                                name="number_hours"
                                options={{}}
                                register={register}
                                error={errors?.number_hours}
                                className="mb-6"
                            >
                                {Text('number_hours', lang)}
                            </NumberField>
                        )}
                        <TextAreaField
                            name="comment"
                            register={register}
                            className="mb-6"
                        >
                            {Text('comment', lang)}
                        </TextAreaField>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default MachineAdd;