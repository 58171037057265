import React, { useState, useContext, useEffect, useRef, isValidElement } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    Button,
    Box,
    Message,
    Modal,
    Tab,
    Badge,
    TextAreaField,
    NumberField,
    FileUpload
} from '../../components';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import { QuoteContext } from '../../contexts/QuoteContext';
import Text from '../../utils/text';
import { get, getAuthToken, getDocumentUrl, remove, post } from '../../server/api';
import getStock from '../../utils/productStock';
import TabSelection from "../../components/TabSelection";

function MachineLight() {
    const [ menu ] = useContext(MenuContext);
    const [ quote, setQuote ] = useContext(QuoteContext);
    const [ user, setUser ] = useContext(UserContext);

    const [ errorMsg, setErrorMsg ] = useState();
    const [ errorMsg2, setErrorMsg2 ] = useState();
    const [ errorMsg3, setErrorMsg3 ] = useState();
    const [ message, setMessage ] = useState();
    const [ loadMachine, setLoadMachine] = useState();
    const [ loadPieces, setLoadPieces] = useState();
    const [ loadKits, setLoadKits] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ isLoadingType, setIsLoadingType ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [ pieceRemove, setPieceRemove ] = useState(null);
    const [ kitRemove, setKitRemove] = useState(null);
    const [ documentRemove, setDocumentRemove ] = useState(null);
    const [ isLoadingRemove, setIsLoadingRemove ] = useState(false);
    const [ unityType, setUnityType ] = useState('');
    const [ pieces, setPieces ] = useState([]);
    const [ selectSite, setSelectSite  ] = useState('');
    const [ moveSite, setMoveSite ] = useState('');
    const [ toggle, setToggle ] = useState(['others']);
    const [ selection, setSelection ] = useState([]);
    const [ lang ] = useContext(LangContext);
    //const [ loadQuotes, setLoadQuotes] = useState([{label: Text('select_quote', lang), value: ''}]);
    
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const modalRef = useRef();
    const moveModalRef = useRef();
    const uploadModalRef = useRef();
    const orderModalRef = useRef();
    const modalDeletePieceRef = useRef();
    const modalDeleteKitRef = useRef();
    const modalDeleteDocRef = useRef();
    const addSuccessModalRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm({});

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        reset,
        clearErrors,
        unregister
    } = useForm({});

    const unity_type = watch('unity_type')

    useEffect(() => {
        setTimeout(() => {
            setMessage(false)
        }, 3000);
    }, [message])

    useEffect(() => {
        clearErrors('number_hours');
        unregister('kilometer');

        switch (unity_type) {
            case 'number_hours':
                unregister('kilometer');
                break;
            default:
                unregister('number_hours');
                break;
        }

        if(loadMachine){
            if(unity_type === 'kilometer'){
                setValue('kilometer', loadMachine.kilometer);
            } else {
                setValue('number_hours', loadMachine.number_hours) 
            }
        }
    }, [unity_type])

    useEffect(() => {
        const load = async () =>{
            setIsLoadingData(true);
            let result = await get('/machine/'+id);

            if(result.success){
                setLoadMachine(result.data);
                let newPieces = sortPieces(result.data.pieces)
                setLoadPieces(newPieces);
                //setValue('name', result.data.name);
                setValue('engine', result.data.engine);
                setValue('serial_number', result.data.serial_number);
                setValue('park_number', result.data.park_number);
                setValue('year', result.data.year);
                setValue('additionals_informations', result.data.additionals_informations);
                setValue('kilometer', result.data.kilometer ? result.data.kilometer : 0);
                setValue('number_hours', result.data.number_hours ? result.data.number_hours : 0);
                setValue('comment', result.data.comment);
                setValue('version', result.data.version);
                setValue('unity_type', result.data.unity_type ? result.data.unity_type : 'hour');
                setUnityType(result.data.unity_type ? result.data.unity_type : 'hour');
                //let parts = Object.keys(result.data.pieces);
                //setToggle(parts);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoadingData(false);
        }

        // const loadQuote = async () =>{
        //     setIsLoadingData(true);
        //     let result = await get('/quotes');

        //     if(result.success && result){
        //         let newQuotes = [
        //             ...loadQuotes,
        //         ]

        //         result.items.forEach((item) => {
        //             newQuotes.push({label: item.name, value: item.id})
        //         })
                

        //         setLoadQuotes(newQuotes);
        //     } else {
        //         if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
        //             setUser({loggedIn: false});
        //         }
        //     }
        //     setIsLoadingData(false);
        // }

        const loadKit = async () =>{
            setIsLoadingData(true);
            let result = await get('/machine/'+id+'/kits');

            if(result.success){
                setLoadKits(result.items);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoadingData(false);
        }

        if(!loadMachine){
            load();
            //loadQuote();
            loadKit();
        }
        
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data
        }

        const result = await post("/machine/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setLoadMachine(result.data);
            setMessage(Text('saved', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_machine', lang));
            }
        }
    };

    const onDelete = async () => {
        const result = await post('/machine/toggle/'+id);
        if(result.success){
            modalRef.current.toggle();
            history.push('/machines')
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const renderProducts = (element) => {
        let products = [];

        element.forEach(product => {
            products.push(<>- {product.title}<br/></>);
        });

        return products;
    }

    const removeKit = async (kitId) => {
        setIsLoadingRemove(true);
        const result = await post('/machine/'+id+'/kit/delete/'+kitId);
        setIsLoadingRemove(false);
        if(result.success){
            modalDeleteKitRef.current.toggle();
            setLoadMachine(result.data);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const handleAction = async (action, element) => {
        switch (action) {
            case 'delete':
                onDeleteItem('kit', element.id);
                break;

            case 'order':
                selectPieces(element.products, 'kit');
                break;
        
            default:
                history.push(action.replace(":id", element.id));;
                break;
        }
    }

    const renderImage = (e) => {
        if(e){
            return <img src={e} width={50}/>
        }

        return null;
    }

    const renderStock = (e) => {
        if(e > 0){
            return  getStock('in_stock');
        }else{
            return  getStock('no_stock');
        }
    }

    const renderComment = (e) => {
        if(!e){
            return '-';
        }else{
            return e;
        }
    }

    const config2 = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "sku",
                label: Text('sku', lang),
            },
            {
                name: "price",
                label: Text('price', lang),
            },
            {
                name: "quantity",
                label: Text('quantity', lang),
            },
            {
                name: "is_in_stock",
                label: Text('is_in_stock',lang),
                render: renderStock
            },
            {
                name: "comment",
                label: Text('comment', lang),
                render: renderComment
            }
        ],
        error: Text('product_list_error', lang),
        no_results: Text('no_product', lang),
        fetchUrl: '/machine/'+id+'/pieces',
        handleAction: handleAction,
        params: {
        }
    };

    const config = {
        headers: [
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "products",
                label: Text('products', lang),
                render: renderProducts
            },
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "/machine/"+id+"/kit/:id",
                active: !(user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('update_kit')))
            },
            {
                label: <i className={"icon-trash text-l"}></i>,
                name: "delete",
                active: !(user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('delete_kit')))
            },
            {
                label: <i className={"icon-order text-l"}></i>,
                name: "order",
                active: menu.context === 'TOPAZ' ? false : true
            },
        ],
        error: Text('kit_list_error', lang),
        no_results: Text('no_kit', lang),
        fetchUrl: '/machine/'+id+'/kits',
        handleAction: handleAction,
        params: {
        }
    };

    const clearSiteList = () => {
        if(menu.sites && loadMachine){
            const index = menu.sites.findIndex(e => e.label === loadMachine.site);
            if(index !== -1){
                let tab = [ ...menu.sites ];
                tab.splice(index, 1);
                return tab;
            }
        }   

        return menu.sites;
    }

    const moveMachine = async () => {
        const params = {
            site: moveSite
        };

        const result = await post('/machine/history/'+id, params);
        if(result.success){
            moveModalRef.current.toggle();
            setLoadMachine(result.data);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const removePiece = async (pieceId) => {
        setPieceRemove(pieceId);
        setIsLoadingRemove(true);
        const result = await post('/machine/'+id+'/remove_piece/'+pieceId);
        if(result.success){
            modalDeletePieceRef.current.toggle();
            setLoadMachine(result.data);
            let newPieces = sortPieces(result.data.pieces)
            setLoadPieces(newPieces);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
        setPieceRemove(null);
        setIsLoadingRemove(false);
    }

    const uploadDocuments = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            file: data.document[0],
        }

        const result = await post("/machine/"+id+"/document/import", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            uploadModalRef.current.toggle();
            setLoadMachine(result.data);
            reset({ document: null });
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            setErrorMsg2(Text('form_error_import_document', lang));
        }
    }

    const deleteDocument = async (documentId) => {
        setIsLoadingRemove(true);

        const result = await remove("/machine/document/delete/"+documentId);

        setDocumentRemove(null);
        setIsLoadingRemove(false);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setLoadMachine(result.data);
            modalDeleteDocRef.current.toggle()
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }        
    }

    const getDocument = (document) => { 
        let url = getDocumentUrl('/document/machines/'+id+'/' + getAuthToken() + '/' + document.id);
        window.open(url);
    }

    const onToggle = (name) => {
        let newToggle = [...toggle];

        if(isToggle(name)){
            let index = toggle.findIndex(e => e === name);
            newToggle.splice(index, 1);
        } else {
            newToggle.push(name);
        }

        setToggle(newToggle);
    }

    const isToggle = (name) => {
        let index = toggle.findIndex(e => e === name);
        return index !== -1;
    }

    const orderPieces = async (totalPieces, type) => {
        setErrorMsg(null);
        setErrorMsg3(null);
        setIsLoading2(true);
        setIsLoadingType(type);

        let paramPieces = [];

        totalPieces.forEach(piece => {
            paramPieces.push({
                sku: piece.sku,
                quantity: piece.quantity
            })
        });

        let params = {
            machine: id,
            site: menu.site ? menu.site : selectSite,
            items: JSON.stringify(paramPieces)
        }

        const result = await post("/quote/add_items", params);
        
        setIsLoading2(false);

        if (result.hasOwnProperty("success") && result.success) {
            if(type === 'select'){
                orderModalRef.current.toggle();
            }
            
            setQuote({ 
                id: result.data.id,
                name: result.data.name
            });

            addSuccessModalRef.current.toggle()
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(type === 'select' && !selectSite){
                setErrorMsg3(Text('select', lang));
            } else {
                if(type === 'select'){
                    setErrorMsg3(Text('form_error_add_item', lang));
                } else {
                    setErrorMsg(Text('form_error_add_item', lang));
                }
            }
        }
    }

    const orderKits = () => {
        let newPieces = [];
        loadKits.forEach(kit => {
            newPieces = [
                ...newPieces,
                ...kit.products
            ]
        });
        selectPieces(newPieces, 'allKits');
    }

    const selectPieces = (newPieces, type) => {
        if(menu.site){
            orderPieces(newPieces, type);
        } else {
            setPieces(newPieces);
            orderModalRef.current.toggle();
        }
    }

    const onDeleteItem = (type, id) => {
        switch (type) {
            case 'piece':
                setPieceRemove(id);
                modalDeletePieceRef.current.toggle()
                break;
            case 'kit':
                setKitRemove(id);
                modalDeleteKitRef.current.toggle()
                break;
            default:
                setDocumentRemove(id);
                modalDeleteDocRef.current.toggle()
                break;
        }
    }

    const sortPieces = (pieces) => {
        let newPieces = {};

        Object.keys(pieces).forEach((part, i) => {
            if(
                pieces[part].name.toLowerCase().includes('filtre') || 
                pieces[part].name.toLowerCase().includes('filtres') || 
                pieces[part].name.toLowerCase().includes('filtration')
            ){
                if(!newPieces.hasOwnProperty('filters')){
                    newPieces.filters = { name: 'Filtres', items: []};
                }
                newPieces.filters.items = newPieces.filters.items.concat(pieces[part].items);
            } else {     
                if(!newPieces.hasOwnProperty('others')){
                    newPieces.others = { name: 'Autres pièces', items: []};
                }         
                newPieces.others.items = newPieces.others.items.concat(pieces[part].items);
            }
        });

        return newPieces;
    }

    const selectMultiProducts = (piece) =>{
        let newSelection = [...selection];

        if(!selection.length){
            newSelection.push(piece);
            setSelection(newSelection);
        } else {
            let isSelected = false;
            let index = null;
            
            newSelection.forEach((p, i)=> {
                if(p.id === piece.id){
                    isSelected = true;
                    index = i;
                }
            })

            if(isSelected){
                newSelection.splice(index, 1);
            } else {
                newSelection.push(piece);
            }

            setSelection(newSelection);
        }
    }

    return (
        <ScreenContainer isLoading={isLoadingData}>
            <div>
                <Message type="error">{errorMsg}</Message>
                <Message type="success">{message}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{loadMachine ? loadMachine.name : null}</h1>
                    </div>
                </div>
                <div className="flex justify-between flex-wrap">
                    <div>
                        <Box className="mr-4 mb-4 min-w-1/4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('params', lang)}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-xs font-bold mb-6">{Text('unity_type', lang).toUpperCase()} : {loadMachine ? loadMachine.unity_type : null}</span>
                                { loadMachine && loadMachine.unity_type ? (
                                    <span className="text-xs font-bold mb-6">{Text('brand', lang).toUpperCase()} : {loadMachine ? loadMachine.kilometer : loadMachine.number_hours}</span>
                                ): null }
                                
                            </div>
                        </Box>
                        <Box className="mr-4 min-w-1/4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('infos', lang)}</span>
                            </div>
                            <div className="flex items-center mb-6">
                                <span className="text-xs font-bold mr-4">{Text('state', lang).toUpperCase()}</span>
                                <Badge type={loadMachine && loadMachine.active ? "enable" : "error"}>
                                    { loadMachine && loadMachine.active ? Text('activated', lang) : Text('disabled', lang)}
                                </Badge>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-xs font-bold mb-6">{Text('site', lang).toUpperCase()} : {loadMachine ? loadMachine.site : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('brand', lang).toUpperCase()} : {loadMachine ? loadMachine.brand : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('model', lang).toUpperCase()} : {loadMachine ? loadMachine.model : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('name', lang).toUpperCase()} : {loadMachine ? loadMachine.name : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('engine', lang).toUpperCase()} : {loadMachine ? loadMachine.engine : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('serial_number', lang).toUpperCase()} : {loadMachine ? loadMachine.serial_number : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('park_number', lang).toUpperCase()} : {loadMachine ? loadMachine.park_number : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('year', lang).toUpperCase()} : {loadMachine ? loadMachine.year : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('additionals_informations', lang).toUpperCase()} : {loadMachine ? loadMachine.additionals_informations : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('comment', lang).toUpperCase()} : {loadMachine ? loadMachine.comment : null}</span>
                                <span className="text-xs font-bold mb-6">{Text('version', lang).toUpperCase()} : {loadMachine ? loadMachine.version : null}</span>
                            </div>
                           
                            <div className="mb-4">
                                <span className="block font-bold text-xs">{Text('docs', lang).toUpperCase()}</span>
                            </div>
                            <div className="w-full h-100 bg-secondary rounded border flex flex-col overflow-auto px-4 py-2">
                                {loadMachine && loadMachine.hasOwnProperty('documents') && loadMachine.documents.length ? loadMachine.documents.map((document, i) => (
                                    <div key={i} className="flex items-center justify-between mb-2">
                                        <span className="mr-2 cursor-pointer hover:bg-grid p-2 rounded" onClick={() => getDocument(document)}>{document.original_name}</span>
                                        <Button skin={"warning"} onPress={() => onDeleteItem('doc', document.id)} isLoading={isLoadingRemove && documentRemove === document.id}>{Text('delete', lang)}</Button>
                                    </div>
                                )) : (
                                    <Message>{Text('no_doc', lang)}</Message>
                                )}
                            </div>
                        </Box>
                    </div>
                    <div className="flex flex-col flex-1">
                    {loadPieces && loadMachine && loadMachine.hasOwnProperty('pieces') ? (
                        <Box className="mb-4 md:mb-0 min-w-1/4">  
                        <div className="mb-4 flex justify-between items-center">
                            <span className="text-xl title">{Text('products', lang)}</span>
                                <div className="flex items-center">
                                </div>
                            </div>
                            <Tab config={config2} selectMulti={(product)=> selectMultiProducts(product)} />
                        </Box>
                    ) : (
                        <Message>Aucune produits trouvé</Message>
                    )}
                        <Box className="mb-4 mt-4 md:mb-0 min-w-1/4">
                            <div className="mb-4 flex justify-between items-center">
                                <span className="text-xl title">{Text('kits', lang)}</span>
                            </div>
                            <Tab {...{config}}/>
                        </Box>
                    </div>
                </div>
            </div>
        </ScreenContainer>
    );
}

export default MachineLight;