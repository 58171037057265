import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Button } from "../";
import humanFileSize from "../../utils/file-size";
import { getUrl, getAuthToken } from "../../server/api";

function ImageDropZone({ onSubmit, skin = "standalone" }) {
    let [uploadFile, setUploadFile] = useState(false);
    let [isUploading, setIsUploading] = useState(false);
    let [hasError, setHasError] = useState(false);
    let [isUploadSuccess, setIsUploadSuccess] = useState(false);
    let [fileData, setFileData] = useState(false);
    let [progressValue, setProgressValue] = useState(0);

    const styles = {
        'standalone': {
            'dropzone-default': 'border-2 border-dashed rounded-lg w-full p-5 border-secondary-50',
            'dropzone-error': 'border-2 border-dashed rounded-lg w-full p-5 border-red bg-secondary-50 bg-opacity-10',
            'dropzone-hover': 'border-2 border-dashed rounded-lg w-full p-5 border-primary bg-primary-100',
        },
        'editor': {
            'dropzone-default': 'border-2 border-dashed rounded-lg w-full p-5 border-white border-opacity-15 text-white',
            'dropzone-error': 'border-2 border-dashed rounded-lg w-full p-5 border-red bg-secondary-50 bg-opacity-10 text-white',
            'dropzone-hover': 'border-2 border-dashed rounded-lg w-full p-5 border-primary bg-primary bg-opacity-15 text-white',
        }
    };

    const style = styles.hasOwnProperty(skin) ? styles[skin] : styles.standalone;

    const onImageSelected = (file) => {
        let reader = new FileReader();
        setIsUploading(false);
        setFileData(false);
        setUploadFile(file);

        reader.onloadend = () => {
            var image = new Image();
            image.src = reader.result;
            image.onload = function () {
                setFileData({
                    url: reader.result,
                    type: file.type,
                    name: file.name,
                    width: image.width,
                    height: image.height,
                    size: humanFileSize(file.size),
                    raw_size: file.size,
                });
            };
        };

        reader.readAsDataURL(file);
    };

    const onDrop = (files) => {
        if (files && files.length > 0) {
            onImageSelected(files[0]);
        }
    };

    const submit = () => {
        setIsUploading(true);
        setIsUploadSuccess(false);
        setHasError(false);

        const body = new FormData();
        body.append("image", uploadFile);
        body.append("image_filename", fileData.name);
        body.append("image_size", fileData.raw_size);
        body.append("image_height", fileData.height);
        body.append("image_width", fileData.width);
        body.append("image_type", fileData.type);

        var ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", uploadProgress, false);
        ajax.onerror = errorHandler;
        ajax.ontimeout = errorHandler;

        ajax.onload = function () {
            const result = JSON.parse(ajax.response);

            if(result && result.hasOwnProperty('success') && result.success) {
                uploadComplete();
                return true;
            }
            else {
                errorHandler();
            }
        };

        const url = getUrl("/image/upload");
        const token = getAuthToken();

        ajax.open("POST", url);
        ajax.setRequestHeader("Authorization", "Bearer " + token);
        ajax.send(body);
    };

    const uploadProgress = (event) => {
        var percent = (event.loaded / event.total) * 100;
        setProgressValue(Math.round(percent) + "%");
    };

    const uploadComplete = () => {
        onSubmit(uploadFile);
        setTimeout(function () {
            setIsUploadSuccess(true);
            setProgressValue(0);
        }, 1000);
    };

    const errorHandler = () => {
        setHasError(true);
        setProgressValue(0);
    };

    const getDropZoneClasses = (isDragActive, isDragAccept, isDragReject) => {
        if (isDragReject) {
            return style['dropzone-error'];
        }

        if (isDragActive || isDragAccept) {
            return style['dropzone-hover'];
        }
        
        return style['dropzone-default'];
    };

    const renderInner = (isDragActive, isDragAccept, isDragReject) => {
        if (isDragReject) {
            return (
                <>
                    <i className="icon-close opacity-20 block mb-1.25 text-60"></i>
                    <span className="block font-semibold text-tiny mb-5">
                        Fichier refusé
                    </span>
                </>
            );
        }

        if (isDragActive || isDragAccept) {
            return (
                <>
                    <i className="icon-drag opacity-20 block mb-1.25 text-60"></i>
                    <span className="block font-semibold text-tiny mb-5">
                        Oui, juste là !
                    </span>
                </>
            );
        }

        return (
            <>
                <i className="icon-upload opacity-20 block mb-1.25 text-60"></i>
                <span className="block font-semibold text-tiny mb-5">
                    Glissez une image ici
                </span>
            </>
        );
    };

    const renderImage = () => {
        if (isUploading) {
            if (hasError) {
                return (
                    <>
                        <i className="icon-upload opacity-20 block mb-2.5 text-60"></i>
                        <span className="text-tiny block mb-2.5">
                            Une erreur est survenue
                        </span>
                        <span className="block text-xs font-semibold mb-5">
                            L'envoi de l'image n'a pas pu aboutir
                        </span>
                        <Button onPress={submit}>Relancer</Button>
                        <Button
                            onPress={() => {
                                setFileData(false);
                                setIsUploadSuccess(false);
                            }}
                            skin="link"
                            className="mt-2.5 text-tiny"
                        >
                            Importer une nouvelle image
                        </Button>
                        
                    </>
                );
            }

            if (isUploadSuccess) {
                return (
                    <>
                        <i className="icon-success block mb-2.5 text-60 text-green"></i>
                        <span className="text-tiny block mb-5">
                            Image envoyée !
                        </span>
                        <Button
                            onPress={() => {
                                setFileData(false);
                                setIsUploadSuccess(false);
                            }}
                            skin="link"
                            className="text-tiny"
                        >
                            Importer une nouvelle image
                        </Button>
                    </>
                );
            }

            return (
                <>
                    <i className="icon-upload opacity-20 block mb-1.25 text-60"></i>
                    <span className="text-tiny block mb-5">Chargement...</span>

                    <div className="bg-primary-50 overflow-hidden h-3 w-64 rounded mb-2.5 max-w-full">
                        <div
                            className="bg-primary h-full transition"
                            style={{ width: progressValue }}
                        ></div>
                    </div>
                    <span className="block text-xs font-semibold mt-2.5">
                        un instant s'il vous plait :)
                    </span>
                </>
            );
        }

        return (
            <>
                <div className="max-w-md m-auto mb-2.5">
                    <img
                        src={fileData.url}
                        alt="preview"
                        width={fileData.width}
                        height={fileData.height}
                        className="block w-full"
                    />
                </div>
                <span className="text-tiny block mb-1.25">{fileData.name}</span>
                <span className="text-sm opacity-50">
                    {fileData.width} x {fileData.height} px
                </span>
                <span className="text-sm opacity-50">{fileData.size}</span>
                <Button onPress={() => submit()} className="mt-5">
                    Ajouter à mes images
                </Button>
                <Button
                    onPress={() => setFileData(false)}
                    skin="link"
                    className="mt-5 text-tiny"
                >
                    <i className="icon-arrow-left mr-2.5"></i> Annuler
                </Button>
            </>
        );
    };

    return (
        <Dropzone
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
            accept="image/png,image/jpg,image/jpeg"
            maxFiles={1}
            multiple={false}
            maxSize={5242880}
        >
            {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
            }) => (
                <div
                    className={getDropZoneClasses(
                        isDragActive,
                        isDragAccept,
                        isDragReject
                    )}
                    {...getRootProps()}
                >
                    <>
                        <div className="flex flex-col flex-1 justify-center items-center p-4">
                            {fileData ? (
                                renderImage()
                            ) : (
                                <>
                                    <input {...getInputProps()} />
                                    {renderInner(
                                        isDragActive,
                                        isDragAccept,
                                        isDragReject
                                    )}
                                    <span className="block text-xs font-semibold mb-2.5">
                                        Ou cliquez sur le bouton suivant :
                                    </span>
                                    <Button>Charger une image</Button>
                                </>
                            )}
                        </div>
                        {!fileData ? (
                        <div className="flex flex-col justify-center items-end text-xs font-semibold opacity-50">
                            <span>Formats recommandés: JPEG, PNG</span>
                            <span>Poids max du fichier : 5 mo</span>
                        </div>) : null}
                    </>
                </div>
            )}
        </Dropzone>
    );
}

export default ImageDropZone;
