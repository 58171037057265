import React, { useState, useContext, useEffect } from "react";
import { 
    ScreenContainer,
    TextField,
    Checkbox,
    Button,
    Box,
    Message,
} from '../../components';
import { post } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { LangContext } from '../../contexts/LangContext';
import Text from '../../utils/text';

function Account() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ user, setUser ] = useContext(UserContext);
    const [ lang ] = useContext(LangContext);
    const history = useHistory();

    const [ message, setMessage ] = useState();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    useEffect(() => {
        setTimeout(() => {
            setMessage(false)
        }, 3000);
    }, [message])

    useEffect(() => {
        const load = async () => {
            if(user){
                setValue('email', user.email  ? user.email  : null);
                setValue('firstname', user.first_name ? user.first_name : null);
                setValue('lastname', user.last_name  ? user.last_name  : null);
                setValue('phone', user.phone  ? user.phone  : null);
            } else {
                setUser({loggedIn: false});
            }
        }

        load();
    }, [history])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            first_name: data.firstname,
            last_name: data.lastname,
            email: data.email,
            phone: data.phone,
        }

        const result = await post("/user/update", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setUser((user) =>({
                ...user,
                ...result.data
            }));
            setMessage(Text('saved', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_user', lang));
            }
        }
    };

    const renderRole = () => {
        switch (user.role) {
            case 'ROLE_COMPANY_MANAGER':
                return <span>{Text('role', lang)} : {Text(user.role, lang)}</span>;

            case 'ROLE_SUBSIDIARY_MANAGER':
                return <span>{Text('role', lang)} : {Text(user.role, lang)}</span>;

            case 'ROLE_SITE_MANAGER':
                return <span>{Text('role', lang)} : {Text(user.role, lang)}</span>;

            case 'ROLE_OPERATOR':
                return <span>{Text('role', lang)} : {Text(user.role, lang)}</span>;
        
            default:
                return <span>{Text('role', lang)} : {Text(user.role, lang)}</span>;
        }
    }

    return (
        <ScreenContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <Message type="success">{message}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('account', lang)}</h1>
                    </div>
                    {/* <Button className="mb-7.5 mr-4" onPress={() => history.push('/addresses')}>
                        {Text('addresses', lang)}
                    </Button> */}
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="mr-4 mb-4 md:mb-0 flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="lastname"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.lastname}
                            className="mb-6"
                        >
                            {Text('lastname', lang)}
                        </TextField>
                        <TextField
                            name="firstname"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.firstname}
                            className="mb-6"
                        >
                            {Text('firstname', lang)}
                        </TextField>
                        <TextField
                            name="email"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.email}
                            className="mb-6"
                        >
                            {Text('email', lang)}
                        </TextField>
                        <TextField
                            name="phone"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.phone}
                            className="mb-6"
                        >
                            {Text('phone', lang)}
                        </TextField>
                    </Box>
                    <Box className={"flex-1 "+ (user.role === 'ROLE_OPERATOR' ? "md:mr-4 mb-4 md:mb-0" : null)}>
                        <div className="mb-4">
                            <span className="text-xl title">{Text('role_affiliations', lang)}</span>
                        </div>
                        <div>
                            {renderRole()}
                        </div>
                        <div className="flex flex-col"> 
                            {user.company ? (
                                <span className="mb-4">{Text('company', lang)} : {user.company}</span>
                            ) : null}
                            {user.subsidiary ? (
                                <span className="mb-4">{Text('subsidiary', lang)} : {user.subsidiary}</span>
                            ) : null}
                            {user.site ? (
                                <span className="mb-4">{Text('site', lang)} : {user.site}</span>
                            ) : null}
                        </div>
                    </Box>
                    { user.role === 'ROLE_OPERATOR' ? (
                        <Box className="flex-1">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('rights', lang)}</span>
                            </div>
                            {/* <div>
                                <span className="text-xl">{Text('users', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_user"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_user"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_user"
                                        className="mb-6"
                                        disabled
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div> */}
                            {/* <div>
                                <span className="text-xl">{Text('addresses', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        name="add_address"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_address"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="delete_address"
                                        className="mb-6"
                                        disabled
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div> */}
                            <div>
                                <span className="text-xl">{Text('machines', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        value={user.rights.includes("add_machine")}
                                        name="add_machine"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        value={user.rights.includes("update_machine")}
                                        name="update_machine"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        value={user.rights.includes("delete_machine")}
                                        name="delete_machine"
                                        className="mb-6"
                                        disabled
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('kits', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        value={user.rights.includes("add_kit")}
                                        name="add_kit"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        value={user.rights.includes("update_kit")}
                                        name="update_kit"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('update', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        value={user.rights.includes("delete_kit")}
                                        name="delete_kit"
                                        className="mb-6"
                                        disabled
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('quotes', lang)}</span>
                                <div className="flex">
                                    {/* <Checkbox
                                        name="add_quote"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('add', lang)}
                                    </Checkbox>
                                    <Checkbox
                                        name="update_quote"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('update', lang)}
                                    </Checkbox> */}
                                    <Checkbox
                                        value={user.rights.includes("delete_quote")}
                                        name="delete_quote"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('delete', lang)}
                                    </Checkbox>
                                    {/* <Checkbox
                                        name="validate_quote"
                                        className="mb-6"
                                        disabled
                                    >
                                        {Text('validate', lang)}
                                    </Checkbox> */}
                                </div>
                            </div>
                            <div>
                                <span className="text-xl">{Text('orders', lang)}</span>
                                <div className="flex">
                                    <Checkbox
                                        value={user.rights.includes("see_order")}
                                        name="see_order"
                                        className="mb-6 mr-4"
                                        disabled
                                    >
                                        {Text('see', lang)}
                                    </Checkbox>
                                </div>
                            </div>
                        </Box>
                    ) : null}
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default Account;