import React, { useState, useContext, useEffect } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    TextAreaField,
    Button,
    Box,
    Message,
    NumberField
} from '../../components';
import { post } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';

function PartAdd() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const history = useHistory();
    const {id} = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data,
            machine: id
        }

        const result = await post("/part/create", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/machine/"+id)
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_create_part', lang));
            }
        }
    };

    return (
        <ScreenContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('add_part', lang)}</h1>
                    </div>
                    <Button skin={"success"} className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('add', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="title"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.title}
                            className="mb-6"
                        >
                            {Text('name', lang)}
                        </TextField>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default PartAdd;