import React, { useState, useContext, useEffect, useRef } from "react";

import { 
    ScreenContainer,
    Button,
    Box,
    Message,
    Modal,
    Tab,
    Badge,
    TextAreaField,
    TextField,
    NumberField
} from '../../components';
import { post } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { get, remove } from '../../server/api';

function Piece() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ loadPiece, setLoadPiece] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const { id, pieceId } = useParams();
    const history = useHistory();
    const location = useLocation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
            let result = await get(location.pathname);
            if(result.success){
                setLoadPiece(result.data);
                setValue('quantity', result.data.quantity)
                setValue('comment', result.data.comment)
                setValue('manufacturer_sku', result.data.manufacturer_sku)
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data
        }

        const result = await post('/machine/'+id+'/piece/update/'+pieceId, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.goBack();
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_part', lang));
            }
        }
    };

    return (
        <ScreenContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{loadPiece ? loadPiece.name + " ( "+loadPiece.machine+" )": null} </h1>
                    </div>
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap">
                    <Box className="flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <NumberField
                            name="quantity"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.quantity}
                            className="mb-6"
                            min='1'
                        >
                            {Text('quantity', lang)}
                        </NumberField>
                        <TextAreaField
                            name="comment"
                            options={{}}
                            register={register}
                            error={errors?.comment}
                            className="mb-6"
                        >
                            {Text('comment', lang)}
                        </TextAreaField>
                        <TextField
                            name="manufacturer_sku"
                            options={{}}
                            register={register}
                            error={errors?.manufacturer_sku}
                            className="mb-6"
                        >
                            {Text('manufacturer_sku', lang)}
                        </TextField>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default Piece;