import React, { useState, useContext, useEffect } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { UserContext } from "../../contexts/UserContext";
import { LangContext } from "../../contexts/LangContext";
import { QuoteContext } from "../../contexts/QuoteContext";
import { AdminMenu, Button, SelectField, Message, CompanyManagerMenu, SubsidiaryManagerMenu, SiteManagerMenu, OperatorMenu } from "../";
import logo from '../../logo.png';
import { useHistory, useLocation } from 'react-router-dom';
import { get, getToken } from '../../server/api';
import Text from '../../utils/text';
import * as c from '../../server/config';

function ScreenContainer({children, isLoading, footer}) {
    const [menu, setMenu] = useContext(MenuContext);
    const [user, setUser] = useContext(UserContext);
    const [quote, setQuote] = useContext(QuoteContext);
    const [lang] = useContext(LangContext);
    const [checkStatus, setCheckStatus] = useState(false);
    const [message, setMessage] = useState();
    const [debug, setDebug] = useState(user.email);
    const debugUsers = [
        { value: 'sarah@internetrama.com', label: 'ROLE_ADMIN'},
        { value: 'company@internetrama.com', label: 'ROLE_COMPANY_MANAGER'},
        { value: 'subsidiary@internetrama.com', label: 'ROLE_SUBSIDIARY_MANAGER'},
        { value: 'site@internetrama.com', label: 'ROLE_SITE_MANAGER'},
        { value: 'usersite@internetrama.com', label: 'ROLE_OPERATOR'}
    ];

    const history = useHistory();
    const location = useLocation();

    const getMenu = () => {
        switch (user.role) {
            case 'ROLE_COMPANY_MANAGER':
                return <CompanyManagerMenu />
            case 'ROLE_SUBSIDIARY_MANAGER':
                return <SubsidiaryManagerMenu />
            case 'ROLE_SITE_MANAGER':
                return <SiteManagerMenu />
            case 'ROLE_OPERATOR':
                return <OperatorMenu />
            default:
                return <AdminMenu />
        }
    }

    const disconnect = () => {
        localStorage.removeItem('park-menu');
        localStorage.removeItem('park-authToken');
        setUser({loggedIn: false});
        setMenu(false);
        history.push('/login');
    }

    const cleanQuote = () => {
        localStorage.removeItem('park-quote');
        setQuote(false);
    }

    useEffect(() => {
        const loadUser = async () => {
            const result = await getToken(debug, 'Aqwzsxedc123');

            if (result.hasOwnProperty("error")) {            
                return { success: false };
            }

            localStorage.setItem("park-authToken", result.token);

            if(result.success){
                const profileResult = await get("/user");

                if (profileResult.success) {
                    let newUser = profileResult.data;
        
                    setUser((user) => ({
                        ...user,
                        loggedIn: true,
                        ...newUser,
                    }));
                } else {
                    setUser((user) => ({
                        loggedIn: false,
                    }));
                }
            }
        };

        if(c.DEBUG && user.loggedIn){
            loadUser();
        }
    }, [debug])

    useEffect(() => {
        if(localStorage.getItem('park-version') !== c.VERSION){
            localStorage.setItem('park-version', c.VERSION);
            disconnect();
        }

        const loadUser = async () => {
            const profileResult = await get("/user");

            if (profileResult.success) {
                let newUser = profileResult.data;
    
                setUser((user) => ({
                    ...user,
                    ...newUser,
                }));

                setMenu((menu) => ({
                    ...menu,
                    init: false
                }));
                
            } else {
                disconnect();
            }
        };

        const check = async () => {
            const result = await get("/machine/check_status_pending");

            if(result.success){
                setCheckStatus(result.data.status_pending);
            } else {
                setCheckStatus(false);
            }
        };

        if(!user){
            loadUser();
        }

        if(user && user.loggedIn && user.role === 'ROLE_ADMIN'){
            check();
        }        

        if(user && user.loggedIn){
            let contextAdminIndex = user.companies.findIndex(e => e.value == menu.company);
            setMenu({
                ...menu,
                context: user.role === 'ROLE_ADMIN' && contextAdminIndex !== -1 ? user.companies[contextAdminIndex].label : user.role === 'ROLE_ADMIN' && contextAdminIndex === -1 ? 'TOPAZ' : false
            })
        }
    }, [location.pathname, user])

    useEffect(() => {
        if(!menu.init){
            let token = localStorage.getItem('park-authToken');

            if(!token){
                setUser(() => ({
                    loggedIn: false
                }))
            }
    
            if(user && user.loggedIn){
                let contextAdminIndex = user.companies.findIndex(e => e.value == menu.company);
                setMenu({
                    ...menu,
                    context: user.role === 'ROLE_ADMIN' && contextAdminIndex !== -1 ? user.companies[contextAdminIndex].label : user.role === 'ROLE_ADMIN' && contextAdminIndex === -1 ? 'TOPAZ' : false
                })

                let newMenu = {}

                let newIndexes = [];
                let newCompanies = [];

                user.companies.forEach((company, indexC) => {
                    let {label, value} = company;
                    newCompanies.push({label, value});
                    newIndexes.push({type: 'company', id: value, index: indexC, label});
                    user.companies[indexC].subsidiaries.forEach((subsidiary, indexS) =>{
                        let {label, value} = subsidiary;
                        newIndexes.push({type: 'subsidiary', id: value, companyIndex: indexC, index: indexS, label});
                        user.companies[indexC].subsidiaries[indexS].sites.forEach((site, indexSi) =>{
                            let {label, value} = site;
                            newIndexes.push({type: 'site', id: value, companyIndex: indexC, subsidiaryIndex: indexS, index: indexSi, label});
                        })
                    })
                });

                let adminIndex = user.companies.findIndex(e => e.label === 'TOPAZ');

                newMenu = {
                    ...newMenu,
                    indexes: newIndexes,
                    company: menu.company ? menu.company : user.role === 'ROLE_ADMIN' && adminIndex !== -1 ? user.companies[adminIndex].value : user.companies[0].value,
                    subsidiary: menu.subsidiary ? menu.subsidiary : '',
                    site: menu.site ? menu.site : '',
                    companies: newCompanies
                };

                // eslint-disable-next-line default-case
                switch (user.role) {
                    case 'ROLE_SUBSIDIARY_MANAGER':
                        newMenu = {
                            ...newMenu,
                            subsidiary: menu.subsidiary ? menu.subsidiary : user.companies[0].subsidiaries[0].value,
                        };

                        break;
    
                    case 'ROLE_SITE_MANAGER':
                    case 'ROLE_OPERATOR':
                        newMenu = {
                            ...newMenu,
                            subsidiary: menu.subsidiary ? menu.subsidiary : user.companies[0].subsidiaries[0].value,
                            site: menu.site ? menu.site : user.companies[0].subsidiaries[0].sites[0].value
                        };
                        break;
                    
                }      

                let newSubsidiaries = [];
                let newSites = [];

                newSubsidiaries.push({label: Text('select', lang), value: ''});
                newSites.push({label: Text('select', lang), value: ''});

                let indexer = newMenu.indexes.find(company => company.id == newMenu.company && company.type == 'company');

                if(indexer && (indexer.index !== null || indexer.index !== false || indexer.index !== undefined)){
                    user.companies[indexer.index].subsidiaries.forEach((subsidiary, index) =>{
                        let {label, value} = subsidiary;
                        newSubsidiaries.push({label, value});
                        if(menu.subsidiary){
                            if(menu.subsidiary == value){
                                user.companies[indexer.index].subsidiaries[index].sites.forEach((site) =>{
                                    let {label, value} = site;
                                    newSites.push({label, value});
                                })
                            }
                        } else {
                            user.companies[indexer.index].subsidiaries[index].sites.forEach((site) =>{
                                let {label, value} = site;
                                newSites.push({label, value});
                            })
                        }
                    })
    
                    newMenu = {
                        ...newMenu,
                        subsidiaries: newSubsidiaries,
                        sites: newSites
                    };
                }

                setMenu((menu)=>({
                    ...menu,
                    ...newMenu,
                    init: true
                }))
            }
        }
    }, [user])

    useEffect(() => {
        setTimeout(() => {
            setMessage(false)
        }, 3000);
    }, [message])

    const changeCompany = (newCompany) =>{
        cleanQuote();
        newCompany = JSON.stringify(newCompany);
        let contextAdminIndex = user.companies.findIndex(e => e.value == newCompany);
        
        let newSubsidiaries = [];
        let newSites = [];

        if(newCompany && newCompany.trim()){
            let indexer = menu.indexes.find(company => company.id == newCompany && company.type == 'company');
            setMessage(Text('connected_to_company', lang)+' "'+indexer.label+'"');

            newSubsidiaries.push({label: Text('select', lang), value: ''});
            newSites.push({label: Text('select', lang), value: ''});

            user.companies[indexer.index].subsidiaries.forEach((subsidiary, index) =>{
                let {label, value} = subsidiary;
                newSubsidiaries.push({label, value});
                user.companies[indexer.index].subsidiaries[index].sites.forEach((site) =>{
                    let {label, value} = site;
                    newSites.push({label, value});
                })
            })
        } 

        setMenu({
            ...menu,
            company: newCompany,
            subsidiary: '',
            site: '',
            subsidiaries: newSubsidiaries,
            sites: newSites,
            context: contextAdminIndex !== -1 ? user.companies[contextAdminIndex].label : false
        })

        history.push('/dashboard');
    }

    const changeSubsidiary = (newSubsidiary) =>{
        cleanQuote();
        let newSites = [];

        if(newSubsidiary){
            let indexer = menu.indexes.find(subsidiary => subsidiary.id == newSubsidiary && subsidiary.type == 'subsidiary');

            newSites.push({label: Text('select', lang), value: ''});

            user.companies[indexer.companyIndex].subsidiaries[indexer.index].sites.forEach((site) =>{
                let {label, value} = site;
                newSites.push({label, value});
            })

            setMenu({
                ...menu,
                company: user.companies[indexer.companyIndex].value,
                subsidiary: newSubsidiary,
                site: '',
                sites: newSites
            })

            setMessage(Text('connected_to_subsidiary', lang)+' "'+ indexer.label +'"');
        }  else {
            let indexer = menu.indexes.find(company => company.id == menu.company && company.type == 'company');

            newSites.push({label: Text('select', lang), value: ''});

            user.companies[indexer.index].subsidiaries.forEach((subsidiary, index) =>{
                user.companies[indexer.index].subsidiaries[index].sites.forEach((site) =>{
                    let {label, value} = site;
                    newSites.push({label, value});
                })
            })

            setMenu({
                ...menu,
                subsidiary: '',
                site: '',
                sites: newSites
            })
        }

        history.push('/dashboard');
    }

    const changeSite = (newSite) =>{
        cleanQuote();
        let newSites = [];

        if(newSite){
            let indexer = menu.indexes.find(site => site.id == newSite && site.type == 'site');

            newSites.push({label: Text('select', lang), value: ''});

            user.companies[indexer.companyIndex].subsidiaries[indexer.subsidiaryIndex].sites.forEach((site) =>{
                let {label, value} = site;
                newSites.push({label, value});
            })

            setMenu({
                ...menu,
                company: user.companies[indexer.companyIndex].value,
                subsidiary: user.companies[indexer.companyIndex].subsidiaries[indexer.subsidiaryIndex].value,
                site: newSite,
                sites: newSites
            })

            setMessage(Text('connected_to_site', lang)+' "'+indexer.label+'"');
        } else {
            setMenu({
                ...menu,
                site: ''
            })
        }

        history.push('/dashboard');
    }

    if(user){
        return (
            <> 
                {c.DEBUG ? (
                    <div className="flex items-center p-2 bg-primary text-secondary font-bold">
                        <span className="mr-4">DEBUG MODE</span>
                        <SelectField 
                            value={user.email}
                            className="mr-4 text-primary" 
                            onChange={(e) => setDebug(e.target.value)}
                            choices={debugUsers}
                        >
                            {Text('user', lang)}
                        </SelectField>
                    </div>
                ) : null}
                <div className="h-screen w-full flex flex-col">
                    <div className="flex  items-center">
                        <img src={logo} alt="logo" className="mx-4" width={170} onClick={() => history.push('/dashboard')}/>
                        <div className="flex flex-col w-full">
                            <div className="flex h-20 pr-4 bg-secondary-300 justify-between items-center">
                                <div className="flex justify-between items-center">
                                    { user && user.role === "ROLE_ADMIN" ? (
                                        <SelectField 
                                            value={menu.company}
                                            className="mr-4" 
                                            onChange={(e) => changeCompany(e.target.value)}
                                            choices={menu.companies}
                                            search
                                        >
                                            {Text('company', lang)}
                                        </SelectField>
                                    ) : (
                                        <div className="flex flex-col mr-4 justify-center">
                                            <span className="block font-bold text-xs">{Text('company', lang).toUpperCase()}</span>
                                            <span className="py-2 mt-3">{user.companies ? user.companies[0].label : null}</span>
                                        </div>
                                    )}
                                    {(!(menu.subsidiaries && menu.subsidiaries.length ) || menu.context === 'TOPAZ') ? null : user && (user.role === "ROLE_ADMIN" || user.role === "ROLE_COMPANY_MANAGER") ? (
                                        <SelectField 
                                            className="mr-4" 
                                            value={menu.subsidiary}
                                            onChange={(e) => changeSubsidiary(e.target.value)}
                                            choices={menu.subsidiaries}
                                            search
                                        >
                                            {Text('subsidiary', lang)}
                                        </SelectField>
                                    ) : (
                                        <div className="flex flex-col mr-4 justify-center">
                                            <span className="block font-bold text-xs">{Text('subsidiary', lang).toUpperCase()}</span>
                                            <span className="py-2 mt-3">{user.companies ? user.companies[0].subsidiaries[0].label : null}</span>
                                        </div>
                                    )}
                                    {(!(menu.sites && menu.sites.length) || menu.context === 'TOPAZ') ? null : user && (user.role === "ROLE_ADMIN" || user.role === "ROLE_COMPANY_MANAGER" || user.role === "ROLE_SUBSIDIARY_MANAGER") ? (
                                        <SelectField 
                                            className="mr-4" 
                                            value={menu.site}
                                            onChange={(e) => changeSite(e.target.value)}
                                            choices={menu.sites}
                                            search
                                        >
                                            {Text('site', lang)}
                                        </SelectField>
                                    ) : (
                                        <div className="flex flex-col mr-4 justify-center">
                                            <span className="block font-bold text-xs">{Text('site', lang).toUpperCase()}</span>
                                            <span className="py-2 mt-3">{user.companies ? user.companies[0].subsidiaries[0].sites[0].label : null}</span>
                                        </div>
                                    )}
                                    { user.role === 'ROLE_ADMIN' && checkStatus && (!menu.context || menu.context === 'TOPAZ' || menu.context === undefined)? (
                                        <Button skin='error' onPress={() => history.push("/machines/pending")} isLoading={!menu.context}>
                                            <i className={"icon-settings icon-md text-2xl"}></i>
                                        </Button>
                                    ) : null}
                                </div>
                                <div className="flex justify-between items-center cursor-pointer" onClick={() => history.push('/account')}>
                                    <i className={"icon-user icon-md text-2xl mr-2.5"}></i>
                                    <span className="w-40 mr-4">
                                    {user.first_name} {user.last_name} {Text("connected_company", lang)} { menu && menu.hasOwnProperty('indexes') && user && user.hasOwnProperty('companies') && user.companies.length ? user.companies[menu.indexes.find(company => company.id == menu.company && company.type == 'company').index].label : null}
                                    </span>
                                    <Button skin='warning' onPress={() => disconnect()}>
                                        <i className={"icon-logout icon-md text-2xl"}></i>
                                        {/* {Text('logout', lang)} */}
                                    </Button>
                                </div>
                            </div>
                            {getMenu()}
                        </div>
                    </div>
                    <div className={ isLoading ? "is-loading-tab flex-1" : "flex-1 p-4 overflow-auto"}>
                        { isLoading ? null : (
                            <>
                                <Message type="success">{message}</Message>
                                {children}
                            </>
                        )}
                    </div>
                    { footer ? 
                        <div>
                            {footer}
                        </div>
                    :null}
                </div>
            </>
        );
    }

    return (
        <div className="flex justify-center items-center bg-white flex-col h-screen">
            <img src={logo} alt="logo" width="60" className="mr-4" />
            <span className="text-tiny block mt-5">
                Chargement...
            </span>
        </div>
    );
    
}

export default ScreenContainer;