import React from "react";

import { Button, Link } from '../components';


function StyleGuide() {
    return (
        <div className="py-40 px-20">

            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Couleurs
            </span>
            <div className="flex justify-between items-start">
                <div className="m-auto font-body w-full mb-7.5">
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-primary mr-2.5"></span>
                        Principale - 100% (primary)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-primary-300 mr-2.5"></span>
                        Principale - 50% (primary-300)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-primary-200 mr-2.5"></span>
                        Principale - 25% (primary-200)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-primary-100 mr-2.5"></span>
                        Principale - 15% (primary-100)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-primary-50 mr-2.5"></span>
                        Principale - 5% (primary-50)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-red mr-2.5"></span>
                        Rouge (red)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-red-light mr-2.5"></span>
                        Rouge clair (red-light)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-green mr-2.5"></span>
                        Vert (green)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-green-light mr-2.5"></span>
                        Vert clair (green-light)
                    </div>
                </div>
                <div className="m-auto font-body w-full mb-7.5">
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-black mr-2.5"></span>
                        Noir (black)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-secondary mr-2.5"></span>
                        Secondaire - Gris très foncé (secondary)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-secondary-300 mr-2.5"></span>
                        Secondaire - Gris foncé (secondary-300)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-secondary-200 mr-2.5"></span>
                        Secondaire - Gris moyen foncé (secondary-200)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-secondary-100 mr-2.5"></span>
                        Secondaire - Gris moyen clair (secondary-100)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-grey mr-2.5"></span>
                        Gris (grey)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-grey-100 mr-2.5"></span>
                        Gris clair (grey-100)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-grey-50 mr-2.5"></span>
                        Gris très clair (grey-50)
                    </div>
                    <div className="flex items-center">
                        <span className="block w-10 h-10 bg-white mr-2.5"></span>
                        Blanc (white)
                    </div>
                </div>

            </div>

            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Titres
            </span>

            <div className="m-auto font-title font-bold w-full mb-7.5">
                <span className="text-3xl block"> 46px - (text-3xl)</span>
                <span className="text-2xl block">Comfortaa 32px - (text-2xl)</span>
                <span className="text-xl block">Comfortaa 24px - (text-xl)</span>
                <span className="text-l block">Comfortaa 20px - (text-l)</span>
                <span className="text-m block">Comfortaa 16px - (text-m)</span>
            </div>

            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Textes
            </span>

            <div className="m-auto font-body w-full mb-7.5">
                <span className="text-3xl block">M PLUS Rounded 1c  46px - (text-3xl)</span>
                <span className="text-2xl block">M PLUS Rounded 1c 32px - (text-2xl)</span>
                <span className="text-xl block">M PLUS Rounded 1c 24px - (text-xl)</span>
                <span className="text-l block">M PLUS Rounded 1c 20px - (text-l)</span>
                <span className="text-m block">M PLUS Rounded 1c 16px - (text-m)</span>
                <span className="text-base block">M PLUS Rounded 1c 14px - (text-base)</span>
                <span className="text-s block">M PLUS Rounded 1c 12px - (text-s)</span>
                <span className="text-xs block">M PLUS Rounded 1c 11px - (text-xs)</span>
            </div>
            <div className="m-auto font-body w-full mb-7.5">
                Faucibus irure montes rerum quam! Excepteur omnis beatae curae voluptatem distinctio, laboriosam! Fames quibusdam! Facere! Ad adipiscing. Blandit wisi ea ipsa voluptatum, nibh porta, natus.<br />

                Luctus potenti, illo eleifend eu delectus risus odit. Lacus volutpat labore vel montes taciti, aliqua repellendus, mollis. Natus tempore minus porttitor molestie eaque id! Fugiat.<br />

                Eveniet laudantium maiores sequi nesciunt auctor qui excepteur vivamus dolorum, perspiciatis pariatur laborum arcu, nisl consequat, accusantium feugiat eius perferendis. Netus mollitia! Urna alias! Varius.
            </div>

            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Espaces
            </span>

            <div className="m-auto font-body w-full mb-7.5">
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-1.25 h-5 bg-grey"></span>
                    </div>
                    5px - 1.25
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-2.5 h-5 bg-grey"></span>
                    </div>
                    10px - 2.5
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-3.25 h-5 bg-grey"></span>
                    </div>
                    15px - 3.25
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-5 h-5 bg-grey"></span>
                    </div>
                    20px - 5
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-7.5 h-5 bg-grey"></span>
                    </div>
                    30px - 7.5
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-10 h-5 bg-grey"></span>
                    </div>
                    40px - 10
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-15 h-5 bg-grey"></span>
                    </div>
                    60px - 15
                </div>
                <div className="flex items-center mb-2.5">
                    <div className="w-35">
                        <span className="block w-27 h-5 bg-grey"></span>
                    </div>
                    90px - 27
                </div>
            </div>
            
            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                En-têtes
            </span>

            <div className="m-auto font-body justify-between w-full mb-7.5">
                <div class="title title-decoration-right mb-7.5">
                    Titre avec décoration à droite
                </div>
                <div class="title title-decoration-left mb-7.5">
                    Titre avec décoration à gauche
                </div>
                <div class="title title-decoration-both mb-7.5">
                    Titre avec décoration des deux côtés
                </div>

                <div className="flex items-center mb-2.5 rounded bg-grey-50 p-7.5">
                    <span className="font-title font-bold text-xl">
                        Mon bloc
                    </span>
                </div>
            </div>

            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Boutons
            </span>

            <div className="m-auto font-body justify-between w-full mb-7.5">
                <Button onPress={() => console.log('clicked!')}>
                    Bouton par défaut
                </Button>
            </div>
            
            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Boutons
            </span>

            <div className="m-auto font-body justify-between w-full mb-7.5">
                <Link href="https://topaz.pro">
                    Lien par défaut
                </Link>
            </div>

            <span className="font-bold border-solid border-b-2 border-t-2 block py-3.25 mb-7.5">
                Elements divers
            </span>

            <div className="m-auto font-body justify-between w-full mb-7.5">
                <div className="flex items-center mb-2.5 rounded bg-grey-50 p-7.5">
                    <span className="font-title font-bold text-xl">
                        Mon bloc
                    </span>
                </div>
            </div>
        </div>
    );
}

export default StyleGuide;