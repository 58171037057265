import React, { useContext, useState, useEffect, useRef} from "react";

import { ScreenContainer, Button, Box, Modal, Message, SelectField } from '../components';
import { useHistory } from 'react-router-dom';
import { MenuContext } from '../contexts/MenuContext';
import { LangContext } from '../contexts/LangContext';
import { UserContext } from '../contexts/UserContext';
import Text from '../utils/text';
import { get, post } from '../server/api';
import { useForm } from "react-hook-form";

function Configurations() {
    const history = useHistory();
    const [ menu ] = useContext(MenuContext);
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const [ successMsg, setSuccessMsg ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ isLoading3, setIsLoading3 ] = useState(false);
    const [ oldId, setOldId ] = useState();
    const [ newId, setNewId ] = useState();
    const [ oldModelId, setOldModelId ] = useState();
    const [ newModelId, setNewModelId ] = useState();
    const [ products, setProducts ] = useState([{value: '', label: Text('select', lang)}]);
    const [ allProducts, setAllProducts ] = useState([{value: '', label: Text('select', lang)}]);
    const [ oldModels, setOldModels ] = useState([{value: '', label: Text('select', lang)}]);
    const [ newModels, setNewModels ] = useState([{value: '', label: Text('select', lang)}]);
    const [ char_filters_products, setCharFiltersProducts] = useState('');
    const [ char_filters_all_products, setCharFiltersAllProducts] = useState('');
    const [ char_filters_new_model, setCharFiltersNewModel] = useState('');
    const [ char_filters_old_model, setCharFiltersOldModel] = useState('');


    const {
        unregister,
        reset
    } = useForm({});

    useEffect(() => {
        if(successMsg){
            setTimeout(() => {
                setSuccessMsg(false)
            }, 5000);
        }

        if(errorMsg){
            setTimeout(() => {
                setErrorMsg(false)
            }, 5000);
        }
    },[successMsg, errorMsg])

    useEffect(() => {
        const loadProducts = async () => {
            const params = {
                char_filters_products
            }

            let dataProducts = await get('/products/skus', params);

            if(dataProducts && dataProducts.success){
                let newProducts = [{value: '', label: Text('select', lang)}];
                dataProducts.items.forEach(element => {
                    newProducts.push({value: element.value, label: element.label})
                });
                setProducts(newProducts);
            }
        }

        if(!products || products.length < 2){

            loadProducts();
            
        }
        loadProducts();

    }, [char_filters_products])

    useEffect(() => {
        
        const loadNewModels = async () => {

            const params = {
                char_filters_new_model
            }

            let dataModels = await get('/all-models', params);

            if(dataModels && dataModels.success){
                let newModels = [{value: '', label: Text('select', lang)}];
                dataModels.data.forEach(element => {
                    newModels.push({value: element.value, label: element.label})
                });

                setNewModels(newModels);
            }
        }

        if(!newModels || newModels.length < 2){
            loadNewModels();
        }

        loadNewModels();
        
    }, [char_filters_new_model])


    useEffect(() => {
        
        const loadOldModels = async () => {

            const params = {
                char_filters_old_model
            }

            let dataModels = await get('/all-models', params);

            if(dataModels && dataModels.success){
                let oldModels = [{value: '', label: Text('select', lang)}];
                dataModels.data.forEach(element => {
                    oldModels.push({value: element.value, label: element.label})
                });

                setOldModels(oldModels);
            }
        }

        if(!oldModels || oldModels.length < 2){
            loadOldModels();
        }

        loadOldModels();
        
    }, [char_filters_old_model])

    useEffect(() => {
    
        const loadAllProducts = async () => {


            const params = {
                char_filters_all_products,
            }

            let dataProducts = await get('/products/skus', params);
            if(dataProducts && dataProducts.success){
                let newProducts = [{value: '', label: Text('select', lang)}];
                dataProducts.items.forEach(element => {
                    newProducts.push({value: element.value, label: element.label})
                });
                setAllProducts(newProducts);
            }
        }
        if(!allProducts || allProducts.length < 2){

            loadAllProducts();
            
        }
        loadAllProducts();
        
    }, [char_filters_all_products])

    const searchValue = async(charFilters, typeFilters) =>{
        switch (typeFilters) {
            case 'products':
                setCharFiltersProducts(charFilters);
                break;
            case 'all_products':
                setCharFiltersAllProducts(charFilters);
                break;
            case 'new_model':
                setCharFiltersNewModel(charFilters);
                break;
            case 'old_model':
                setCharFiltersOldModel(charFilters);
                break;
            default:
                break;
        }
    }

    const validate = async () => {
        setIsLoading2(true);
        let data = {
            old_product_id: oldId,
            new_product_id: newId
        };

        let result = await post('/sku/update', data);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('success_change_reference', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('error_change_reference', lang));
            }
        }

        setIsLoading2(false);
    }

    
    const submitModel = async () => {
        setIsLoading3(true);
        let data = {
            old_model_machine_id: oldModelId,
            new_model_machine_id: newModelId
        };

        let result = await post('/model/update', data);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('success_change_model', lang));

            unregister('old_model_machine_id');
            unregister('new_model_machine_id');

            reset({
                oldModelId,
                newModelId
            })
        } else {
            if(result.hasOwnProperty("error")) {
                switch (result.error) {
                    case 'err_01':
                        setErrorMsg(Text("success_error_model_1", lang));
                        break;
                    case 'invalid_grant':
                        setUser({loggedIn: false});
                        break;
                    default:
                        setErrorMsg(Text("success_error_model", lang))
                }
            }
           
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            }
        }

        setIsLoading3(false);
    }
    
    return (
        <ScreenContainer>
            <Message type="error">{errorMsg}</Message>
            <Message type="success">{successMsg}</Message>
            <div className="flex items-center w-full">
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('configurations', lang)}</h1>
                </div>

                <Button className="mb-7.5 mr-4" onPress={() => history.push('/massive-machines-products')}>
                    {Text('add_products_to_machines', lang)}  
                </Button>
            </div>
            <div className="flex-col mb-4">
                <Box className={ isLoading ? "is-loading-tab flex-1 mr-4 mb-4" : "flex-1 mr-4 mb-4"}>
                {  isLoading ? null :(
                    <>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-xl title">{Text('reference_change', lang)}</span>
                            <Button onPress={() => history.push('/references-history')}>
                                {Text('reference_history', lang)}  
                            </Button>
                        </div>
                        <div className="flex flex-1 justify-start items-center">
                            <SelectField 
                                className="mb-4 w-96" 
                                value={oldId}
                                onChange={(e) => setOldId(e.target.value)}
                                choices={products}
                                search
                                searchByRequest
                                searchValue={(char) => searchValue(char, 'products')}
                            >
                                {Text('old_reference', lang)}
                            </SelectField>
                            <i className={"icon-validation text-2xl mr-4 ml-4"}></i>
                            <SelectField 
                                className="mb-4 w-96" 
                                value={newId}
                                onChange={(e) => setNewId(e.target.value)}
                                choices={allProducts}
                                search
                                searchByRequest
                                searchValue={(charAllProducts) => searchValue(charAllProducts, 'all_products')}
                            >
                                {Text('new_reference', lang)}
                            </SelectField>
                        </div>
                        <div className="flex justify-end">
                            <Button skin={"success"} onPress={() => validate()} isLoading={isLoading2} disabled={!(oldId && newId)}>
                                {Text('validate', lang)}  
                            </Button>
                        </div>
                    </>
                )}
                </Box>
                <Box className={ isLoading ? "is-loading-tab flex-1 mr-4" : "flex-1 mr-4"}>
                {  isLoading ? null :(
                    <>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-xl title">{Text('model_change', lang)}</span>
                            <Button onPress={() => history.push('/model-history')}>
                                {Text('model_history', lang)}  
                            </Button>
                        </div>
                        <div className="flex flex-1 justify-start items-center">
                            <SelectField 
                                className="mb-4 w-96" 
                                value={oldModelId}
                                onChange={(e) => setOldModelId(e.target.value)}
                                choices={oldModels}
                                search
                                searchByRequest
                                searchValue={(char) => searchValue(char, 'old_model')}
                            >
                                {Text('old_model', lang)}
                            </SelectField>
                            <i className={"icon-validation text-2xl mr-4 ml-4"}></i>
                            <SelectField 
                                className="mb-4 w-96" 
                                value={newModelId}
                                onChange={(e) => setNewModelId(e.target.value)}
                                choices={newModels}
                                search
                                searchByRequest
                                searchValue={(char) => searchValue(char, 'new_model')}
                            >
                                {Text('new_model', lang)}
                            </SelectField>
                        </div>
                        <div className="flex justify-end">
                            <Button skin={"success"} onPress={() => submitModel()} isLoading={isLoading3} disabled={!(oldModelId && newModelId)}>
                                {Text('validate', lang)}  
                            </Button>
                        </div>
                    </>
                )}
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Configurations;