import { trad } from '../trad';

const Text = (label, lang) => {
    if(lang){
        return trad[lang][label];
    }

    return trad['fr'][label];
};

export default Text;