import React, { useState, useContext } from "react";

import { EmailField, PasswordField, Button} from '../components';
import { post } from '../server/api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { LangContext } from '../contexts/LangContext';
import logo from '../logo.png';
import logoDubreuil from '../assets/images/logo-dubreuil.png';
import Text from '../utils/text';

function PasswordForgot() {

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [user, setUser] = useContext(UserContext);
    const [lang] = useContext(LangContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await post('/magento/reset_password', {email: data.username});
        setIsLoading(false);

        if (result.hasOwnProperty("error")) {
            switch (result.error) {
                case 'err_02':
                    setErrorMsg(Text("reset_error_2", lang));
                    break;
                case 'err_03':
                    setErrorMsg(Text("reset_error_3", lang));
                    break;
                default:
                    setErrorMsg(Text("login_error_1", lang));
                    break;
            }
            
            return { success: false };
        }

        if(result.success){
            setSuccessMsg(Text("request_success", lang));
            setTimeout(() =>{
                history.goBack()
            }, 3000)
        }
    };

    return (
        <div className="flex flex-col h-screen w-full">
            <div className="flex flex-1 w-full justify-center items-center bg-intro">
                <div className="flex flex-col w-1/2">
                    <div className="flex justify-center align-center mb-4">
                        <img src={logo} alt="logo" width={300}/>
                    </div>
                    <div className="border-b border-solid border-primary-100 pb-1 mb-6 flex items-center">
                        {/* <i className="icon-user icon-md text-2xl text-primary mr-2.5"></i> */}
                        <span className="text-xl">{Text("forgot_password", lang)}</span>
                    </div>
                    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                        <EmailField
                            name="username"
                            options={{ required: Text("required", lang) }}
                            register={register}
                            error={errors?.username}
                            className="mb-6"
                            onChange={() => setErrorMsg(null)}
                        >
                            {Text("email", lang)}
                        </EmailField>
                        {errorMsg ? <p className="error mb-6">{errorMsg}</p> : null}
                        {successMsg ? <p className="success mb-6">{successMsg}</p> : null}
                        <div className="flex justify-between mt-8" >
                            <Button onPress={() => history.goBack()}>
                                {Text("cancel", lang)}
                            </Button>
                            <Button type="submit" isLoading={isLoading}>
                                {Text("send", lang)}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex items-center bg-primary p-4">
                <img src={logoDubreuil} alt="logo" className="mr-4"/>
                <span className="text-secondary">Une entreprise du groupe - Dubreuil</span>
            </div>
        </div>
    );
}

export default PasswordForgot;