import React, { useContext } from "react";
import { Button, BaseMenu } from "..";
import { useHistory } from 'react-router-dom';
import { LangContext } from "../../contexts/LangContext";
import { MenuContext } from "../../contexts/MenuContext";
import Text from '../../utils/text';

function AdminMenu() {
    const history = useHistory();
    const [lang] = useContext(LangContext);
    const [menu] = useContext(MenuContext);

    return (
        <BaseMenu>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/dashboard')} >
                <i className={"icon-dashboard text-2xl mr-2.5"}></i>
                {Text('dashboard', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/users')} >
                <i className={"icon-user text-2xl mr-2.5"}></i>
                {Text('users', lang)}
            </Button>
            { menu.context === 'TOPAZ' ? (
                <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/companies')}>
                    <i className={"icon-company text-2xl mr-2.5"}></i>
                    {Text('companies', lang)}
                </Button>
            ) : null }
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/subsidiaries')}>
                <i className={"icon-subsidiary text-2xl mr-2.5"}></i>
                {Text('subsidiaries', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/sites')}>
                <i className={"icon-site text-2xl mr-2.5"}></i>
                {Text('sites', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/machines')}>
                <i className={"icon-machine text-2xl mr-2.5"}></i>
                {Text('machines', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/quotes')}>
                <i className={"icon-quote icon-md text-2xl mr-2.5" }></i>
                {Text('quotes', lang)}
            </Button>
            <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/orders')}>
                <i className={"icon-order icon-md text-2xl mr-2.5" }></i>
                {Text('orders', lang)}
            </Button>
            { menu.context === 'TOPAZ' ? (
                <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/configurations')}>
                    <i className={"icon-settings text-2xl mr-2.5"}></i>
                    {Text('configurations', lang)}
                </Button>
            ) : null }
        </BaseMenu>
    );
}

export default AdminMenu;