import React, { useState, useContext, useEffect, useRef } from "react";

import { 
    ScreenContainer,
    TextField,
    SelectField,
    Button,
    Box,
    Message,
    Modal
} from '../../components';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { get, post, remove } from '../../server/api';

function Subsidiary() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ loadSubsidiary, setLoadSubsidiary ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [ checkDelete, setCheckDelete ] = useState(false);
    const [ country, setCountry ] = useState('FR');
    const [ countries, setCountries ] = useState([
        {label:'Sélectionnez un pays', value:''},
    ]);
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const { id } = useParams();
    const history = useHistory();
    const modalRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
            setIsLoadingData(true);
            let resultData = await get('/magento/countries');

            if(resultData.success){
                let dataCountries = resultData.data
                let newCountries = [{label:'Sélectionnez un pays', value:''}];
                if(dataCountries && dataCountries.length){
                    dataCountries.forEach(country => {
                        newCountries.push({value: country.id, label: country.full_name_locale})
                    });
                }

                setCountries(newCountries);
            }

            let result = await get('/subsidiary/'+id);
            if(result.success){
                setCheckDelete(result.checkDelete);
                setLoadSubsidiary(result.data);
                setValue('name', result.data.name);
                setValue('phone', result.data.phone);
                setValue('siret', result.data.siret);
                setValue('tva_number',result.data.tva_number);
                setValue('country', result.data.country);
                setCountry(result.data.country);
                setValue('address', result.data.address);
                setValue('address_2', result.data.address_2);
                setValue('city', result.data.city);
                setValue('zip_code', result.data.zip_code);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoadingData(false);
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let c = countries.find(e => e.value === data.country);

        let params = {
            ...data,
            country_full_name: c && c.label ? c.label : null
        }

        const result = await post("/subsidiary/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/subsidiaries")
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_subsidiary', lang));
            }
        }
    };

    const onDelete = async () => {
        const result = await post('/subsidiary/toggle/'+id);
        if(result.success){
            modalRef.current.toggle();
            history.push('/subsidiaries')
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    return (
        <ScreenContainer isLoading={isLoadingData}>
            <Modal ref={modalRef}>
                <span className="text-primary text-m">{checkDelete ? Text('popup_delete_subsidiary', lang) : Text('popup_not_delete_subsidiary', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalRef.current.toggle()}>
                        {checkDelete ? Text('cancel', lang) : Text('close', lang)}
                    </Button>
                    {checkDelete ?
                        <Button skin={"warning"} onPress={() => onDelete()}>
                            {Text('delete', lang)}
                        </Button>
                    : null}
                </div>
            </Modal>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('edit_subsidiary', lang)} ( {loadSubsidiary ? loadSubsidiary.name : null} )</h1>
                    </div>
                    <Button className="mb-7.5 mr-4" onPress={() => modalRef.current.toggle()}>
                        {Text('delete_subsidiary', lang)}
                    </Button> 
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="mr-4 mb-4 md:mb-0 flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <TextField
                            name="name"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.name}
                            className="mb-6"
                        >
                            {Text('name', lang)}
                        </TextField>
                        <TextField
                            name="siret"
                            options={{ 
                                required: Text('required', lang), 
                                minLength: {
                                    value: 14,
                                    message: Text('min_lenght_14', lang) 
                                },
                                maxLength: {
                                    value: 14,
                                    message: Text('max_lenght_14', lang) 
                                },
                            }}
                            register={register}
                            error={errors?.siret}
                            className="mb-6"
                        >
                            {Text('siret', lang)}
                        </TextField>
                        <TextField
                            name="tva_number"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.tva_number}
                            className="mb-6"
                        >
                            {Text('tva_number', lang)}
                        </TextField>
                        <TextField
                            name="phone"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.phone}
                            className="mb-6"
                        >
                            {Text('phone', lang)}
                        </TextField>
                        <TextField
                            name="address"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.address}
                            className="mb-6"
                        >
                            {Text('address', lang)}
                        </TextField>
                        <TextField
                            name="address_2"
                            options={{}}
                            register={register}
                            error={errors?.address_2}
                            className="mb-6"
                        >
                            {Text('address_2', lang)}
                        </TextField>
                        <TextField
                            name="city"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.city}
                            className="mb-6"
                        >
                            {Text('city', lang)}
                        </TextField>
                        <TextField
                            name="zip_code"
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.zip_code}
                            className="mb-6"
                        >
                            {Text('zip_code', lang)}
                        </TextField>
                        <SelectField
                            name="country"
                            value={country}
                            options={{ required: Text('required', lang) }}
                            register={register}
                            error={errors?.country}
                            className="mb-6"
                            choices={countries}
                            setValue={setValue}
                        >
                            {Text('country', lang)}
                        </SelectField>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default Subsidiary;