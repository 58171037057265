import React from "react";

function Message({ type, children }) {
    if (!children || children === "") {
        return null;
    }

    let wrapperClassName = "message px-4 py-2 rounded mb-4";
    let textClassName = "italic";

    switch (type) {
        case "success":
            wrapperClassName += " border-2 bg-status-300 bg-opacity-5 border-status-300";
            textClassName += " text-status-300";
            break;

        case "error":
            wrapperClassName += " border-2 bg-status-100 bg-opacity-5 border-status-100";
            textClassName += " text-status-100";
            break;
        case "notice":
            wrapperClassName += " border-2 bg-status-200 bg-opacity-5 border-status-200";
            textClassName += " text-status-200";
            break;

        case "info":
            wrapperClassName += " bg-primary bg-opacity-5";
            textClassName += " text-primary";
            break;

        default:
            wrapperClassName += " border-2 bg-primary bg-opacity-5 border-primary";
            textClassName += " text-primary";
            break;
    }

    return (
        <div className={wrapperClassName}>
            <p className={textClassName}>{children}</p>
        </div>
    );
}

export default Message;
