import Text from './text';

const getStatus = (status, lang) => {
    let bg = "";
    let state = "";

    switch (status) {
        case 'pending':
            bg = "bg-status-200";
            state = "to_validate";
            break;
        case 'complete':
            bg = "bg-status-300";
            state = "just_validated";
            break;
    
        default:
            bg = "bg-status";
            break;
    }

    return <span className={"p-2 rounded text-secondary font-bold inline-block "+bg}>{Text(state, lang)}</span>;
};

export default getStatus;