import React, { useState, useContext, useEffect, useRef } from "react";

import { 
    ScreenContainer,
    TextField,
    Button,
    Box,
    Message,
    Tab,
    NumberField,
    SelectField, 
    Modal,
    FileUpload,
    Badge
} from '../../components';
import { post } from '../../server/api';
import { useHistory, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';
import { useForm } from 'react-hook-form';
import getStock from '../../utils/productStock';


import { get } from '../../server/api';

function Pieces() {
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const history = useHistory();
    const {id} = useParams();

    const [ errorMsg, setErrorMsg ] = useState();
    const [ errorMsg2, setErrorMsg2 ] = useState();
    const [ successMsg, setSuccessMsg] = useState();
    const [ search, setSearch ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ isLoading3, setIsLoading3 ] = useState(false);
    const [ isLoading4, setIsLoading4 ] = useState(false);
    const [ pieces, setPieces ] = useState([]);
    const [ categories, setCategories ] = useState([{value: '', label: Text('select', lang)}]);
    
    const modalAddPieceRef = useRef();
    const modalAddPiecesRef = useRef();

    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [isCompatible, setIsCompatible] = useState(false);

    const [brandFilters, setBrandFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [categoryFilters, setCategoryFilters] = useState([{label: Text('select', lang), value: ''}]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
    } = useForm({});

    useEffect(() => {
        const loadFilters = async () => {
            let params = {
                search,
                brand,
                category,
                compatible_machine: isCompatible ? id : null
            }

            const brandResult = await get('/products/brands', params);
            if(brandResult.success){
                let newBrandFilters = [{label: Text('select', lang), value: ''}];
                brandResult.data.forEach((b) => {
                    newBrandFilters.push(b);
                })

                setBrandFilters(newBrandFilters);
            }

            const categoryResult = await get('/products/categories', params);
            if(categoryResult.success){
                let newCategoryFilters = [{label: Text('select', lang), value: ''}];
                categoryResult.data.forEach((b) => {
                    newCategoryFilters.push(b);
                })

                setCategoryFilters(newCategoryFilters);
            }
        }

        loadFilters();
    }, [brand, category, isCompatible, search])

    const onSubmit = async () => {
        setErrorMsg(null);
        setIsLoading(true);

        let piecesLight = [];

        pieces.forEach( piece =>{
            piecesLight.push({id: piece.id, quantity: piece.quantity ? parseInt(piece.quantity) : 1, comment: piece.comment ? piece.comment : '', manufacturer_sku: piece.manufacturer_sku ? piece.manufacturer_sku : ''});
        })

        let params = {
            pieces: JSON.stringify(piecesLight),
            machine: id
        }

        const result = await post("/machine/add_pieces", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.push("/machine/"+id)
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_add_pieces', lang));
            }
        }
    };

    const renderImage = (e) => {
        if(e){
            return (
                <img src={e} className="object-contain h-24"/>
            )
        }

        return null;
    }

    const handleAction = (action, element) => {
        let index = pieces.findIndex(p => p.id === element.id)

        let newPieces = [ ...pieces ];
        element.quantity = 1;
        newPieces.push(element);

        if(index === -1){
            setPieces(newPieces);
        }
    }

    const onRemove = (index) => {
        let newPieces = [ ...pieces ];

        newPieces.splice(index, 1);

        setPieces(newPieces);
    }

    const renderStock = (e) => {
        if(e){
            return getStock('in_stock', e);
        }

        return getStock('no_stock', e);
    }

    const renderState = (e) => {
        return (
            <Badge type={e ? "enable" : "error"}>
                { e ? Text('activated', lang) : Text('disabled', lang)}
            </Badge>
        )
    }

    const config = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "sku",
                label: Text('sku', lang),
            },
            {
                name: "supplier_sku",
                label: Text('supplier_sku', lang),
            },
            {
                name: "price",
                label: Text('price', lang),
            },
            {
                name: "brand",
                label: Text('product_brand', lang),
            },
            {
                name: "category",
                label: Text('product_category', lang),
            },
            {
                name: "is_in_stock",
                label: Text('is_in_stock', lang),
                render: renderStock
            },
            {
                name: "active",
                label: Text('state', lang),
                render: renderState
            },
        ],
        actions: [
            {
                label: <i className={"icon-more text-l"}></i>,
                name: 'add',
                active: true, 
                skin: 'success'
            },
        ],
        error: Text('piece_list_error', lang),
        no_results: Text('no_piece', lang),
        fetchUrl: '/products',
        handleAction: handleAction,
        params: {
            search,
            category,
            brand,
            compatible_machine: isCompatible ? id : null
        }
    };

    const changeQuantity = (quantity, index) => {
        let newPieces = [...pieces];
        newPieces[index]['quantity'] = quantity;
        setPieces(newPieces);
    }

    const changeComment = (comment, index) => {
        let newPieces = [...pieces];
        newPieces[index]['comment'] = comment;
        setPieces(newPieces);
    }

    const changeManufacturerSku = (manufacturer_sku, index) => {
        let newPieces = [...pieces];
        newPieces[index]['manufacturer_sku'] = manufacturer_sku;
        setPieces(newPieces);
    }

    const uploadPieces = async (data) => {
        setErrorMsg(null);
        setIsLoading2(true);

        let params = {
            pieces: data.pieces[0],
        }

        const result = await post("/pieces/import", params);

        setIsLoading2(false);

        if (result.hasOwnProperty("success") && result.success) {
            let newPieces = [ 
                ...pieces,
                ...result.data
            ];
            setPieces(newPieces);
            setSuccessMsg(Text('form_success_import_pieces', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                switch (result.error) {
                    case 'err_02':
                        setErrorMsg(Text('form_error_import_pieces_2', lang));
                        break;
                
                    default:
                        setErrorMsg(Text('form_error_import_pieces_1', lang));
                        break;
                }
    
            }
        }
    }

    const addPiece = async (data) => {
        setErrorMsg2(null);
        setIsLoading3(true);

        let params = {
            ...data,
            media_gallery_entries: data.media_gallery_entries[0],
        }

        const result = await post("/piece/add", params);

        setIsLoading3(false);

        if (result.hasOwnProperty("success") && result.success) {
            let newPieces = [ ...pieces];
            newPieces.push(result.data);
            setPieces(newPieces);
            setSuccessMsg(Text('form_success_add_piece', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                setErrorMsg2(Text('form_error_add_piece', lang));
            }

            switch (result.error) {
                case 'err_01':
                    setErrorMsg2(Text("popup_error_add_piece_1", lang));
                    break;
                
                default:
                    setErrorMsg2(Text("popup_error_add_piece", lang));
                    break;
            }
        }
    }

    const clearSuccessMsg = (type) => {
        switch (type) {
            case 'modalAddPiecesRef':
                modalAddPiecesRef.current.toggle()
                break;
        
            default:
                modalAddPieceRef.current.toggle()
                break;
        }

        setSuccessMsg(null);
    }

    const changeBrand = (newBrand) => {
        setBrand(newBrand); 
        setCategory('');
    }

    const openModal = async () => {
        setIsLoading4(true);
        const result = await get("/categories");
        setIsLoading4(false);
        if(result && result.success){
            let newCategories = [
                {value: '', label: Text('select', lang)},
                ...result.items
            ]
            setCategories(newCategories);
            clearSuccessMsg('modalAddPieceRef')
        }
    }

    return (
        <ScreenContainer>
            <Modal ref={modalAddPiecesRef}>
                <span className="text-primary text-m">{Text('popup_import_piece', lang)}</span>
                <i className={"icon-infos text-m ml-2.5 p-2 border-2 rounded-full"}></i>
                <span className="infos text-primary text-m bg-grid p-2 w-60">{Text('infos_pieces_csv', lang)}</span>
                <form onSubmit={handleSubmit2(uploadPieces)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalAddPiecesRef')}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg}</Message>
                            <FileUpload 
                                name="pieces"
                                options={{ required: Text('required', lang) }}
                                register={register2}
                                error={errors2?.pieces}
                                className="mb-6"
                            >
                                {Text('pieces', lang)}
                            </FileUpload>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalAddPiecesRef')}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" skin={"success"} isLoading={isLoading2}>
                                    {Text('import', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal>
            <Modal ref={modalAddPieceRef}>
                <span className="text-primary text-m">{Text('popup_add_piece', lang)}</span>
                <form onSubmit={handleSubmit(addPiece)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalAddPieceRef')}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg2}</Message>
                            <TextField
                                name="name"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.name}
                                className="mb-6"
                            >
                                {Text('name', lang)}
                            </TextField>
                            <TextField
                                name="sku"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.sku}
                                className="mb-6"
                            >
                                {Text('sku', lang)}
                            </TextField>
                            <TextField
                                name="supplier_sku"
                                options={{}}
                                register={register}
                                error={errors?.supplier_sku}
                                className="mb-6"
                            >
                                {Text('supplier_sku', lang)}
                            </TextField>
                            <TextField
                                name="price"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.price}
                                className="mb-6"
                            >
                                {Text('price', lang)}
                            </TextField>
                            <SelectField 
                                name="category"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.category}
                                className="mb-6"
                                choices={categories}
                                setValue={setValue}
                                search
                            >
                                {Text('product_category', lang)}
                            </SelectField>
                            <FileUpload 
                                name="media_gallery_entries"
                                options={{}}
                                register={register}
                                error={errors?.media_gallery_entries}
                                className="mb-6"
                            >
                                {Text('image', lang)}
                            </FileUpload>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalAddPieceRef')}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" skin={"success"} isLoading={isLoading3}>
                                    {Text('add', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal>
            <div>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('add_pieces', lang)}</h1>
                    </div>
                    { user.role === 'ROLE_ADMIN' ? (
                        <>
                            <Button  className="mb-7.5 mr-4" onPress={() => openModal()} isLoading={isLoading4}>
                                {Text('create_piece', lang)}  
                            </Button>
                            <Button className="mb-7.5 mr-4" onPress={() => clearSuccessMsg('modalAddPiecesRef')}>
                                {Text('import_pieces', lang)}  
                            </Button>
                        </>
                    ) : null}
                    <Button className="mb-7.5" skin={"success"} onPress={() => onSubmit()} isLoading={isLoading}>
                        {Text('add', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="w-full mb-4 flex-col flex">
                        <div className="flex flex-row mb-2">
                            <div>
                                <TextField className="mb-4" onChange={(e) => setSearch(e.target.value)}>
                                    {Text('search', lang)}
                                </TextField>
                            </div>
                            <div className="ml-2">
                                <SelectField 
                                    name="brand"
                                    className="mb-4" 
                                    value={brand}
                                    onChange={(e) => changeBrand(e.target.value)}
                                    choices={brandFilters}
                                    search
                                >
                                    {Text('product_brand', lang)}
                                </SelectField>
                            </div>
                            <div className="ml-2">
                                <SelectField 
                                    name="category"
                                    className="mb-4" 
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    choices={categoryFilters}
                                    search
                                >
                                    {Text('product_category', lang)}
                                </SelectField>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <input type="checkbox" name="isCompatible" id="isCompatible" defaultChecked={isCompatible} onChange={() => setIsCompatible(!isCompatible)} />
                            <label  className="ml-2" htmlFor="isCompatible">{Text('product_compatibility', lang)}</label>
                        </div>
                    </Box>
                </div>
                <div className="flex justify-between flex-wrap">
                    <Box className="flex-1 mr-4">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('pieces', lang)}</span>
                        </div>
                        <Tab {...{config}} params={config.params}/>
                    </Box>
                    <Box className="flex-1">
                        <div className="mb-4 ">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                        <div className="mb-4">
                            <span className="block font-bold text-xs">{Text('piece_added', lang).toUpperCase()}</span>
                        </div>
                        <div className="sticky top-0">
                            <div className="w-full h-100 bg-secondary rounded border flex flex-col overflow-auto px-4 py-2">
                                {pieces && pieces.length ? (
                                    <table className={"w-full"}>
                                        <thead>
                                            <tr className="border-b border-gray-400">
                                                <th className="px-4 py-2 text-left text-gray-500">
                                                    {Text('name', lang)}
                                                </th>
                                                <th className="px-4 py-2 text-left text-gray-500">
                                                    {Text('quantity', lang)}
                                                </th>
                                                <th className="px-4 py-2 text-left text-gray-500">
                                                    {Text('comment', lang)}
                                                </th>
                                                <th className="px-4 py-2 text-left text-gray-500">
                                                    {Text('manufacturer_sku', lang)}
                                                </th>
                                                <th className="px-4 py-2 text-left text-gray-500">
                                                    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { pieces.map((piece, i) => (
                                                <tr key={i} className={i === pieces.length - 1? null : "border-b border-gray-400"} >
                                                    <td className="px-4 py-2">
                                                        <span>{piece.title}</span>
                                                    </td>
                                                    <td className="px-4 py-2">
                                                        <NumberField
                                                            className="mb-6"
                                                            defaultValue={1}
                                                            onChange={(e) => changeQuantity(e.target.value, i)}
                                                            min='1'
                                                            value={piece.quantity ? piece.quantity : 1}
                                                        />
                                                    </td>
                                                    <td className="px-4 py-2">
                                                        <TextField
                                                            className="mb-6"
                                                            onChange={(e) => changeComment(e.target.value, i)}
                                                            value={piece.comment ? piece.comment : []}
                                                        />
                                                    </td>
                                                    <td className="px-4 py-2">
                                                        <TextField
                                                            className="mb-6"
                                                            onChange={(e) => changeManufacturerSku(e.target.value, i)}
                                                            value={piece.manufacturer_sku ? piece.manufacturer_sku : []}
                                                        />
                                                    </td>
                                                    <td className="flex flex-row px-4 py-2">
                                                        <Button  skin={"warning"} onPress={() => onRemove(i)}><i className={"icon-less text-l"}></i></Button>
                                                    </td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                ) : (
                                    <Message>{Text('no_piece_add', lang)}</Message>
                                )}
                                
                            </div>
                            <div className="flex justify-end mt-4">
                                <Button className="mb-7.5" skin={"success"} onPress={() => onSubmit()} isLoading={isLoading}>
                                    {Text('add', lang)}
                                </Button>
                            </div>
                        </div>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"}  onPress={() => onSubmit()} isLoading={isLoading}>
                        {Text('add', lang)}
                    </Button>
                </div>
            </div>
        </ScreenContainer>
    );
}

export default Pieces;