import * as c from './config';
import serialize from './serialize';

export async function getToken(username, password){
    //let { client_id, client_secret} = window.api_config;
    //let grant_type = c.GRANT_TYPE;

    /*if(!client_id || !client_secret){
        window.alert('Error client_id client_secret');
    }*/

    const requestOptions = {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded",
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
        },
        body: serialize({
            /*client_id,
            client_secret,
            grant_type,*/
            username: username,
            password: password
        })
    };
    
    let url = getUrl('/magento/login');
    const response = await fetch(url, requestOptions);
    const data = response.json()
    return data;
}

export async function refreshToken(){
    let { client_id, client_secret} = window.api_config;

    let grant_type = c.GRANT_TYPE_REFRESH;

    if(!client_id || !client_secret){
        window.alert('Error client_id client_secret');
    } 

    const refreshToken = localStorage.getItem("refreshToken");

    const requestOptions = {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: serialize({
            client_id,
            client_secret,
            grant_type,
            refresh_token: refreshToken
        })
    };
    
    let url = getServerDomain() + '/oauth/v2/token';
    const response = await fetch(url, requestOptions);
    const data = response.json()
    return data;
}

export async function get(path, params){
    let token = getAuthToken();

    const requestOptions = {
        method: "GET",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": 'Bearer ' + token,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
        }
    };

    let url = getUrl(path);
    
    if(params){
        if(!url.includes('?')) {
            url = url + '?timestamp='+ new Date().getTime()+'&'
        }
        else {
            url = url + '&timestamp='+ new Date().getTime()+'&';
        }
        
        url = url + serialize(params)
    }

    const response = await fetch(url, requestOptions);
    if(response){
        const data = response.json()
        return data;
    }
    
    return false;
}

export async function post(path, params, rawBody = false){
    let token = getAuthToken();
    var query = "";

    if(rawBody) {
        query = params;
    } else {
        query = new FormData();
    
        if(params && typeof params !== "undefined") {
            let keys = Object.keys(params);
            keys.forEach(key => {
                let param = params[key]

                if(typeof param === 'boolean'){
                    param = JSON.stringify(params[key]);
                }

                query.append(key, param)   
            });            
        }
    }

    const requestOptions = {
        method: "POST",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": 'Bearer ' + token,
        },
        body: query
    };

    let url = getUrl(path);    
    const response = await fetch(url, requestOptions);

    if(response){
        const data = response.json()
        return data;
    }
    
    return false;
}

export async function remove(path){
    let token = localStorage.getItem('park-authToken');

    const requestOptions = {
        method: "DELETE",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": 'Bearer ' + token,
        },
        mode: 'cors',
    };

    let url = getUrl(path);
    const response = await fetch(url, requestOptions);
    if(response){
        const data = response.json()
        return data;
    }
    
    return false;
}

export function getServerDomain() {
    if(process.env.NODE_ENV === "development") {
        return c.LOCAL_DOMAIN;
    }

    return c.PROD_DOMAIN;
}

export function getUrl(path, params = false) {
    let url = getServerDomain() + '/api/v1'  + path;

    if(params){
        if(!url.includes('?')) {
            url = url + '?'
        }
        else {
            url = url + '&';
        }
        
        url = url + serialize(params)
    }

    return url;
}

export function getDocumentUrl(path, params) {
    let url = getServerDomain() + path;

    if(params){
        if(!url.includes('?')) {
            url = url + '?'
        } else {
            url = url + '&';
        }
        
        url = url + serialize(params)
    }

    return url;
}

export function getAuthToken() {
    let token = localStorage.getItem('park-authToken');
    return token;
}