import React, { useState, useEffect } from 'react';

const LangContext = React.createContext([{}, () => {}]);

const LangProvider = ({children}) => {

    const [lang, setLang] = useState();

    useEffect(() => {
        if(!lang){
            let newLang = JSON.parse(localStorage.getItem("park-lang"));
            if(!newLang){
                newLang = 'fr'
            }

            localStorage.setItem("park-lang", JSON.stringify(newLang));
            setLang(newLang);
        } else {
            localStorage.setItem("park-lang", JSON.stringify(lang));
        }
    }, [lang])

    return (
        <LangContext.Provider value={[lang, setLang]}>
            {children}
        </LangContext.Provider>
    );
}

export { LangContext, LangProvider };