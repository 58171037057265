import React from 'react';

import DefaultField from './DefaultField';

function TextField(props) {    

    return (
        <DefaultField type="text" { ...props} />
    )
}

export default TextField;