import { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as c from '../server/config';

import { UserContext } from '../contexts/UserContext';
function RouteManager({children }) {
    const [user] = useContext(UserContext);
    
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        let path = location.pathname.split('/');

        if(c.MAINTENANCE){
            history.push('/maintenance');
        } else {
            if(location.pathname === '/login'){
                let version = localStorage.getItem('park-version');
                if(!version){
                    localStorage.setItem('park-version', c.VERSION);
                }
            }
    
            if(user){
                if(!user.loggedIn && location.pathname !== '/password/forgot'){
                    history.push('/login');
                } else {
                    switch (path[1]) {
                        case 'dashboard':
                            break;
                        default:
                            break;
                    }
            
                    if(location.pathname === '/' || location.pathname === '/login' || location.pathname === '/maintenance' ){
                        history.push('/dashboard');
                    }
                }
            }
        }   

       
       
    }, [location.pathname, user.loggedIn]);

    return children;
}

export default RouteManager;