import React, { useState, useEffect } from 'react';

const QuoteContext = React.createContext([{}, () => {}]);

const QuoteProvider = ({children}) => {

    const [quote, setQuote] = useState(false);

    useEffect(() => {
        if(!quote){
            let newQuote = JSON.parse(localStorage.getItem("park-quote"));
            if(!newQuote){
                setQuote(newQuote);
            }

            localStorage.setItem("park-quote", JSON.stringify(newQuote));
            setQuote(newQuote);
        } else {
            localStorage.setItem("park-quote", JSON.stringify(quote));
        }
    }, [quote])

    return (
        <QuoteContext.Provider value={[quote, setQuote]}>
            {children}
        </QuoteContext.Provider>
    );
}

export { QuoteContext, QuoteProvider };