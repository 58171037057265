import React from 'react';

function Button({ children, width = 'auto', onPress, type = 'button' , isLoading, className, value, skin, disabled, title }) {

    let finalClassName = " ";
    switch (skin) {
        case "link":
            finalClassName += "w-" + width;            
            break;
        case "transparent":
            finalClassName += "min-h-45 font-title font-bold w-" + width;            
            break;
        default:
            finalClassName += "min-h-45 font-bold px-5 rounded w-" + width;
            break;
    }

    if(isLoading) {
        finalClassName += " is-loading";
    }

    if(className && className !== "") {
        finalClassName += " " +className;
    }

    if(disabled) {
        finalClassName += " opacity-50";
    }

    if(!disabled && !isLoading) {
        switch (skin) {
            case "primary-outline":
                finalClassName += " border-2 border-primary bg-secondary text-primary hover:bg-primary hover:text-white";            
                break;
            case "transparent":
                finalClassName += " bg-secondary text-primary hover:text-button-300";            
                break;
            case "link":
                finalClassName += " text-secondary hover:text-button-300";            
                break;
            case "error":
                finalClassName += " bg-status-200 text-secondary hover:bg-opacity-1";            
                break;
            case "warning":
                finalClassName += " bg-status-100 text-secondary hover:bg-opacity-1";            
                break;
            case "success":
                finalClassName += " bg-status-300 text-secondary hover:bg-opacity-50";            
                break;
            default:
                finalClassName += " bg-primary text-secondary hover:bg-button-300";
                break;
        }
    }
    

    return (
        <div className="button-area flex min-w-max">
            <button className={"inline-flex items-center leading-none " + finalClassName} value={value} onClick={onPress} type={type} disabled={isLoading || disabled}>
                <span className={"btn-inner inline-flex items-center leading-none"}>
                    {children}
                </span>
            </button>
        </div>
        
    )
}

export default Button;