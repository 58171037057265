import React from "react";

function Checkbox({
    children,
    name,
    register,
    defaultValue,
    error,
    options,
    className,
    onChange,
    inputClassName,
    disabled
}) {
    className = "form-field relative " + className;
    let errorMessage = error ? error.message : null;

    const inputRegister = register ? register(name, options) : null;
    const handleOnChange = (e) => {
        if (typeof onChange !== "undefined") {
            onChange(e);
        }

        if(inputRegister){
            inputRegister.onChange(e);
        }
    };

    if (options && options.hasOwnProperty("required")) {
        className += " required";
    }

    return (
        <div key={"checkbox-" + name} className={className}>
            <div className="relative flex items-start">
                <input
                    id={"checkbox-" + name}
                    className={inputClassName ? inputClassName : ""}
                    name={name}
                    type="checkbox"
                    defaultChecked={defaultValue}
                    ref={register}
                    onChange={handleOnChange}
                    onBlur={inputRegister ? inputRegister.onBlur : null}
                    ref={inputRegister ? inputRegister.ref : null}
                    name={inputRegister ? inputRegister.name : null}
                    disabled={disabled ? disabled : false}
                />
                {children ? (
                    <label
                        htmlFor={"checkbox-" + name}
                        className="ml-2.5 block text-sm"
                    >
                        {children}
                    </label>
                ) : null}
            </div>
            {errorMessage ? <span className="text-sm text-red">{errorMessage}</span> : null}
        </div>
    );
}

export default Checkbox;
