import React, { useContext } from "react";

import { ScreenContainer, Tab, Button, Box } from '../../components';
import { LangContext } from '../../contexts/LangContext';
import Text from '../../utils/text';
import { useHistory, useParams } from 'react-router-dom';

function HistorySite() {
    const [ lang ] = useContext(LangContext);
    const history = useHistory();
    const { id } = useParams();

    const config = {
        headers: [
            {
                name: "machine",
                label: Text('machine', lang),
            },
            {
                name: "start_at",
                label: Text('arrived_on', lang),
            },
            {
                name: "end_at",
                label: Text('departure_on', lang),
            },
        ],
        actions: [
        ],
        error: Text('history_list_error', lang),
        no_results: Text('no_history', lang),
        fetchUrl: '/site/history/'+id,
    };

    return (
        <ScreenContainer>
            <div className="flex items-center w-full">
                <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                    {Text('back', lang)}
                </Button>
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('history', lang)}</h1>
                </div>
            </div>
            <div className="flex">
                <Box className="flex flex-1">
                    <Tab {...{config}}/>
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default HistorySite;