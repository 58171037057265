import React, { useContext, useState, useEffect } from "react";

import { ScreenContainer, Tab, Button, Box, Message } from '../components';
import { LangContext } from '../contexts/LangContext';
import Text from '../utils/text';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { post } from '../server/api';

function ReferencesHistory() {
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);

    const history = useHistory();
    const [ successMsg, setSuccessMsg ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);

    useEffect(() => {
        if(successMsg){
            setTimeout(() => {
                setSuccessMsg(false)
            }, 5000);
        }

        if(errorMsg){
            setTimeout(() => {
                setErrorMsg(false)
            }, 5000);
        }
    },[successMsg, errorMsg])


    const handleAction = async (action, element) => {
        setIsLoading(true);

        let data = {
            history_sku_id: element.id
        }

        let result = await post('/backup', data);

        if (result.hasOwnProperty("success") && result.success) {

            setIsLoadingData(true);

            setSuccessMsg(Text('success_change_reference', lang));

            setIsLoadingData(false);

        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('error_change_reference', lang));
            }
        }

        setIsLoading(false);
    }

    const config = {
        headers: [
            {
                name: "old_product_id",
                label: Text('old_reference', lang),
            },
            {
                name: "new_product_id",
                label: Text('new_reference', lang),
            },
            {
                name: "user",
                label: Text('user', lang),
            },
            {
                name: "created_at",
                label: Text('created_at', lang),
            },
            {
                name: "updated_at",
                label: Text('updated_at', lang),
            },
            {
                name: "user_backup",
                label: Text('user_backup', lang),
            },
            {
                name: "backup",
                label: Text('backup', lang),
            },
        ],
        actions: [
            {
                label: Text('cancel', lang),
                name: "backup",
                active: "pas effectué", 
                index: "backup"
            },
        ],
        handleAction: handleAction,
        error: Text('history_list_error', lang),
        no_results: Text('no_history', lang),
        fetchUrl: '/sku/history',
    };

    return (
        <ScreenContainer isLoading={isLoadingData}>
            <Message type="error">{errorMsg}</Message>
            <Message type="success">{successMsg}</Message>
            <div className="flex items-center w-full">
                <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                    {Text('back', lang)}
                </Button>
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('reference_history', lang)}</h1>
                </div>
            </div>
            <div className="flex">
                <Box className="flex flex-1">
                    <Tab {...{config}}/>
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default ReferencesHistory;