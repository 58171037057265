import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Message, Paging } from ".";
import { UserContext } from "../contexts/UserContext";
import { get } from "../server/api";

function TabSelection({config, onPageChange, urlPage, selectMulti, selectAll}) {
    const [dataTab, setDataTab] = useState([]);
    const [page, setPage] = useState(urlPage ? urlPage : 1);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [ user, setUser ] = useContext(UserContext);
    const history = useHistory();
    const [queryCacheKey, setQueryCacheKey] = useState('');
    const [fullQueryCacheKey, setFullQueryCacheKey] = useState('');
    const [tabIndex, setTabIndex] = useState([]);
    const [isCheck, setIsCheck] = useState(false);

    const getParams = () => {
        let paramKeys = config.params ? Object.keys(config.params) : [];

        let params = {};

        paramKeys.forEach(key => {
            if(config.params[key] && config.params[key] !== ''){
                params[key] = config.params[key];
            }
        });

        return params;
    }

    const generateFullQueryCacheKey = () => {
        const params = getParams();
        params['page'] = page;
        const cacheKeys = [];
        Object.keys(params).forEach((paramKey) => {
            cacheKeys.push(paramKey+"="+params[paramKey]);
        })
        
        return cacheKeys.join('|');
    }

    const generateQueryCacheKey = () => {
        const params = getParams();
        const cacheKeys = [];
        Object.keys(params).forEach((paramKey) => {
            cacheKeys.push(paramKey+"="+params[paramKey]);
        })
        
        return cacheKeys.join('|');
    }

    useEffect(() => {
        const newQueryCacheKey = generateQueryCacheKey();
        if(newQueryCacheKey !== queryCacheKey) {
            setQueryCacheKey(newQueryCacheKey);
        }
        if(newQueryCacheKey !== queryCacheKey && page !== 1) {
            setPage(1);
            return true;
        }

        const newFullQueryCacheKey = generateFullQueryCacheKey();
        if(newFullQueryCacheKey === fullQueryCacheKey) {
            return true;
        }

        setQueryCacheKey(queryCacheKey);
        setFullQueryCacheKey(newFullQueryCacheKey);
    })

    useEffect(() => {
        setTabIndex([]);
        setIsCheck(false);
        selectAll([], [], true)
    }, [page, dataTab])

    useEffect(() => {        
        if(config){
            const fetchData = async () => {
                setLoading(true);
                const result = await get(config.fetchUrl, { page, ...getParams()});
                setLoading(false);

                if (result.success) {
                    setDataTab(result);
                } else {
                    if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                        setUser({loggedIn: false});
                    }
                    setErrorMsg(config.error);
                }
            };

            fetchData();

    
            /*if(urlPage){
                fetchData();
                onPageChange(page);
            } else {
                setPage(1);
                fetchData();
            }*/
        }

    }, [fullQueryCacheKey]);

    const { items, totalPage, total } = dataTab;

    const localHandleAction = async (action, element) => {
        setLoading(true);
        if (typeof config.handleAction !== "undefined") {
            let result = await config.handleAction(action, element);
            if(result && result.hasOwnProperty('success') && result.success){
                const newtab = await get(config.fetchUrl, { page, ...getParams() });
                if (newtab.success) {
                    setDataTab({...newtab});
                } else {
                    if(newtab.hasOwnProperty('error') && newtab.error === 'invalid_grant'){
                        setUser({loggedIn: false});
                    }
                    setErrorMsg(config.error);
                }
            } else {
                setLoading(false);
            }
        } else {
            history.push(action.replace(":id", element.id));
        }
        setLoading(false);
    };

    const renderActions = (actions, element) => {
        return actions.map((action,i) => 
            action.hasOwnProperty('active') && action.active
            ? (
                <Button className="mr-4" key={action.name+i} skin={action.skin ? action.skin : ''} onPress={() => localHandleAction(action.name, element)} disabled={action.hasOwnProperty('index') && action.index ? action.active !== element[action.index] : false}>
                    {action.label}
                </Button>
            ) : null
        );
    };

    const renderField = (header, value) => {
        if(value !== false && value !== null){
            if (typeof header.render !== "undefined") {
                return header.render(value);
            }
    
            return value;
        }
        
        return '-';
    };

    let currentPage = null;
    let maxPage = null;
    let currentItems = null;
    let maxItems = null;

    if (items && items.length) {
        currentPage = page;
        maxPage = totalPage;
        currentItems = items.length;
        maxItems = total;
    }
   
   
    const onSelect =(element, i) => {

        let newTabIndex = [...tabIndex]

        selectMulti(element, i);

        if(newTabIndex.includes(i)) {
            let index = newTabIndex.findIndex(e => e === i);
            if( index !== -1){
                newTabIndex.splice(index, 1);
            }
        }else{
            newTabIndex.push(i);
        }

        setTabIndex(newTabIndex);
    }

    const onSelectAll = () => {

        let newTabIndex = [...tabIndex];
        
        items.forEach((item, i)=> {
            if(!isCheck){
                if(!newTabIndex.includes(i)){
                    //selectMulti(item, i);
                    newTabIndex.push(i);
                }
            }else{
                if(newTabIndex.includes(i)){
                    //selectMulti(item,i);
                    let index =newTabIndex.findIndex(e => e === i)
                    if( index !== -1){
                        newTabIndex.splice(index, 1);
                    }
                    
                }
            }
        })

        selectAll(newTabIndex, items, isCheck);
        setIsCheck(!isCheck);
        setTabIndex(newTabIndex);
   
    }


    if(errorMsg) {
        return (
            <div className="overflow-hidden rounded-lg w-full">
                <Message type="error">{errorMsg}</Message>
            </div>
        )
    }

    return (
        <div className="rounded-lg overflow-x-auto">
                <div className={loading ? "is-loading-tab" : null}>
                    {items && items.length ? (
                        <>
                            <table className={"tab-inner w-full"}>
                                <thead>
                                    <tr className="border-b border-gray-400">
                                        <th className="px-4 py-2 text-left text-gray-500">
                                            <input type="checkbox" onChange={() => onSelectAll()} checked={isCheck}  />
                                        </th>
                                        {config.headers.map((header, index) => (
                                            <th
                                                key={index}
                                                className="px-4 py-2 text-left text-gray-500"
                                            >
                                                {header.label}
                                            </th>
                                        ))}
                                        {config.customActions ? config.customActions.map((action, i) => (
                                            <th key={i} className="px-4 py-2 text-left text-gray-500">{action.label}</th>
                                        )) : null}
                                        {config.actions ? (
                                            <th key="actions">&nbsp;</th>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody> 
                                    {items.map((element, i) => (
                                        <tr
                                            key={element.id}
                                            className={i === items.length - 1? 
                                                null : "border-b border-gray-400"
                                            }
                                        >
                                            <td className="px-4 py-2">
                                                <input type="checkbox" onChange={(e) => onSelect(element, i)} checked={tabIndex.includes(i)}/>
                                            </td>
                                            {config.headers.map((header, j) => (
                                                <td
                                                    key={i + "" + j}
                                                    className="px-4 py-2 text-left"
                                                >
                                                    {renderField(header,element[header.name])}
                                                </td>
                                            ))}
                                            {config.customActions ? config.customActions.map((action, i ) => (
                                                <td key={i} className="px-4 py-2 text-right">
                                                    {action.render(element)}
                                                </td>
                                            )) : null}
                                            {config.actions ? (
                                                <td className={config.actions.length > 1 ? "px-4 py-2 flex" : "px-4 py-2"}>
                                                    {renderActions(config.actions, element)}
                                                </td>
                                            ) : null}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {maxPage > 1 && !loading ? (
                                <Paging
                                    {...{
                                        setPage,
                                        currentPage,
                                        maxPage,
                                        currentItems,
                                        maxItems,
                                    }}
                                />
                            ) : null}
                        </>
                    ):  !loading ? <Message type="info">{config && config.hasOwnProperty('no_results') ? config.no_results : null}</Message> : null}
                </div>
           
        </div>
    );
}

export default TabSelection;