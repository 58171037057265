import React, { useState, useContext, useEffect, useRef, isValidElement } from "react";
import logoAdblue from '../../assets/images/adblue.jpg';
import logoAirMoteur from '../../assets/images/air_moteur.jpg';
import logoAspirationHydraulique from '../../assets/images/aspiration_hydraulique.jpg';
import logoCabine from '../../assets/images/cabine.jpg';
import logoCarburantMoteur from '../../assets/images/carburant_moteur.jpg';
import logoClimatisation from '../../assets/images/climatisation.jpg';
import logoFreins from '../../assets/images/freins.jpg';
import logoHuileMoteur from '../../assets/images/huile_moteur.jpg';
import logoHydraulique from '../../assets/images/hydraulique.jpg';
import logoPilotageEtTransmission from '../../assets/images/pilotage_et_transmission.jpg';
import logoPneumatique from '../../assets/images/pneumatique.jpg';
import logoRefroidissement from '../../assets/images/refroidissement.jpg';
import logoReniflardHydraulique from '../../assets/images/reniflard_hydraulique.jpg';
import logoRetourHydraulique from '../../assets/images/retour_hydraulique.jpg';
import logoVentilationCarterMoteur from '../../assets/images/ventilation_carter_moteur.jpg';


import { 
    ScreenContainer,
    TextField,
    SelectField,
    Button,
    Box,
    Message,
    Modal,
    Tab,
    Badge,
    TextAreaField,
    NumberField,
    FileUpload
} from '../../components';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import { QuoteContext } from '../../contexts/QuoteContext';
import Text from '../../utils/text';
import { get, getAuthToken, getDocumentUrl, remove, post } from '../../server/api';
import getStock from '../../utils/productStock';
import TabSelection from "../../components/TabSelection";

function Machine() {
    const [ menu ] = useContext(MenuContext);
    const [ quote, setQuote ] = useContext(QuoteContext);
    const [ user, setUser ] = useContext(UserContext);
    const [ errorMsg, setErrorMsg ] = useState();
    const [ errorMsg2, setErrorMsg2 ] = useState();
    const [ errorMsg3, setErrorMsg3 ] = useState();
    const [ message, setMessage ] = useState();
    const [ loadMachine, setLoadMachine] = useState();
    const [ loadPieces, setLoadPieces] = useState();
    const [ loadKits, setLoadKits] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ isLoadingType, setIsLoadingType ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [ pieceRemove, setPieceRemove ] = useState(null);
    const [ kitRemove, setKitRemove] = useState(null);
    const [ documentRemove, setDocumentRemove ] = useState(null);
    const [ isLoadingRemove, setIsLoadingRemove ] = useState(false);
    const [ unityType, setUnityType ] = useState('');
    const [ brandCode, setBrandCode ] = useState('');
    const [ modelId, setModelId ] = useState('');
    const [ pieces, setPieces ] = useState([]);
    const [ selectSite, setSelectSite  ] = useState('');
    const [ moveSite, setMoveSite ] = useState('');
    const [ toggle, setToggle ] = useState(['others']);
    const [ selection, setSelection ] = useState([]);
    const [ lang ] = useContext(LangContext);
    const [ selectBrand, setSelectBrand ] = useState(false);
    const [ selectModel, setSelectModel ] = useState(false);
    const [ newBrand, setNewBrand  ] = useState('');
    const [ newModel, setNewModel ] = useState('');
    const [brandFilters, setBrandFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [modelFilters, setModelFilters] = useState([{label: Text('select', lang), value: ''}]);
    //const [ loadQuotes, setLoadQuotes] = useState([{label: Text('select_quote', lang), value: ''}]);
    
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const modalRef = useRef();
    const moveModalRef = useRef();
    const uploadModalRef = useRef();
    const orderModalRef = useRef();
    const modalDeletePieceRef = useRef();
    const modalDeleteKitRef = useRef();
    const modalDeleteDocRef = useRef();
    const addSuccessModalRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm({});

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        reset,
        clearErrors,
        unregister
    } = useForm({});

    const unity_type = watch('unity_type');
    const brand = watch('brand');
    const model = watch('model');
    const new_brand = watch('new_brand');
    const kilometer = watch('kilometer');
    const number_hours = watch('number_hours');

    useEffect(() => {
        setTimeout(() => {
            setMessage(false)
        }, 3000);
    }, [message])

    useEffect(() => {
        clearErrors('number_hours');
        unregister('kilometer');

        switch (unity_type) {
            case 'number_hours':
                unregister('kilometer');
                break;
            default:
                unregister('number_hours');
                break;
        }

        if(loadMachine){
            if(unity_type === 'kilometer'){
                setValue('kilometer', loadMachine.kilometer);
            } else {
                setValue('number_hours', loadMachine.number_hours) 
            }
        }
    }, [unity_type])

    useEffect(() => {

        const loadBrands = async () => {

            const result = await get('/brands');

            if(result.success){
                let brands = result.data;

                if(brands && brands.length){
                    let newBrandFilters = [{label: Text('select', lang), value: ''}];

                    brands.forEach((brand) => {
                        newBrandFilters.push(brand);
                    })
                    setBrandFilters(newBrandFilters);
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }
        
        const load = async () =>{

            setIsLoadingData(true);
            let result = await get('/machine/'+id);

            if(result.success){
                setLoadMachine(result.data);
                let newPieces = sortPieces(result.data.pieces)
                setLoadPieces(newPieces);
                //setValue('name', result.data.name);
                setValue('engine', result.data.engine);
                setValue('name', result.data.name);
                setValue('brand', result.data.brand);
                setValue('model_id', result.data.model_id);
                setValue('model', result.data.model_id);
                setValue('new_brand', result.data.new_brand);
                setValue('new_model', result.data.new_model);
                setValue('serial_number', result.data.serial_number);
                setValue('park_number', result.data.park_number);
                setValue('year', result.data.year);
                setValue('additionals_informations', result.data.additionals_informations);
                setValue('kilometer', result.data.kilometer ? result.data.kilometer : 0);
                setValue('number_hours', result.data.number_hours ? result.data.number_hours : 0);
                setValue('comment', result.data.comment);
                setValue('version', result.data.version);
                setValue('unity_type', result.data.unity_type ? result.data.unity_type : 'hour');
                setUnityType(result.data.unity_type ? result.data.unity_type : 'hour');
                setModelId(result.data.model_id)
                setNewModel(result.data.new_model)
                setNewBrand(result.data.new_brand)
                //let parts = Object.keys(result.data.pieces);
                //setToggle(parts);

            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoadingData(false);
        }

        // const loadQuote = async () =>{
        //     setIsLoadingData(true);
        //     let result = await get('/quotes');

        //     if(result.success && result){
        //         let newQuotes = [
        //             ...loadQuotes,
        //         ]

        //         result.items.forEach((item) => {
        //             newQuotes.push({label: item.name, value: item.id})
        //         })
                

        //         setLoadQuotes(newQuotes);
        //     } else {
        //         if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
        //             setUser({loggedIn: false});
        //         }
        //     }
        //     setIsLoadingData(false);
        // }

        const loadKit = async () =>{
            setIsLoadingData(true);
            let result = await get('/machine/'+id+'/kits');

            if(result.success){
                setLoadKits(result.items);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoadingData(false);
        }

        if(!loadMachine){
            load();
            //loadQuote();
            loadKit();
        }

        loadBrands();
        
    }, [])


    useEffect(() => {
        const loadModels = async () => {

            const params = {
                brand : new_brand
            }
           
            const result = await get('/models', params);

            if(result.success){
                let models = result.data;

                if(models && models.length){
                    let newModelFilters = [{label: Text('select', lang), value: ''}];

                    models.forEach((model) => {
                        newModelFilters.push(model);
                    })

                    setModelFilters(newModelFilters);
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        if(brand){
            loadModels();
        }

    }, [new_brand])

   
    const setBrands = () => {
        unregister('new_brand');
        unregister('new_model');

        if(selectBrand){
            if(brand){
                unregister('new_brand');
            }
            if(model){
                unregister('new_model');
            }
            
        } 
    
        setSelectBrand(!selectBrand);
    }

    const setModels = () => {
        unregister('new_model');
        if(selectModel){
            if(model){
                unregister('new_model');
            }
        } 
        setSelectModel(!selectModel)
    }

    

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        //console.log(data);
        let params = {
            ...data,
        }

        const result = await post("/machine/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setLoadMachine(result.data);
            setMessage(Text('saved', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_machine', lang));
            }
        }
    };

    const onDelete = async () => {
        const result = await post('/machine/toggle/'+id);
        if(result.success){
            modalRef.current.toggle();
            history.push('/machines')
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const renderProducts = (element) => {
        let products = [];

        element.forEach(product => {
            products.push(<>- {product.title}<br/></>);
        });

        return products;
    }

    const removeKit = async (kitId) => {
        setIsLoadingRemove(true);
        const result = await post('/machine/'+id+'/kit/delete/'+kitId);
        setIsLoadingRemove(false);
        if(result.success){
            modalDeleteKitRef.current.toggle();
            setLoadMachine(result.data);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const handleAction = async (action, element) => {
        switch (action) {
            case 'delete':
                onDeleteItem('kit', element.id);
                break;

            case 'deletePiece':
                onDeleteItem('piece', element.id);
                break;

            case 'order':
                selectPieces(element.products, 'kit');
                break;

            case 'orderPiece':
                selectPieces([element], 'piece');
                break;
        
            default:
                history.push(action.replace(":id", element.id));;
                break;
        }
    }

    const renderImage = (e) => {
        if(e){
            return <img src={e} width={50}/>
        }

        return null;
    }

    const renderStock = (e) => {
        if(e > 0){
            return  getStock('in_stock', e);
        }else{
            return  getStock('no_stock', e);
        }
    }

    const renderComment = (e) => {
        if(!e){
            return '-';
        }else{
            return e;
        }
    }

    const renderState = (e) => {
        return (
            <Badge type={e ? "enable" : "error"}>
                { e ? Text('activated', lang) : Text('disabled', lang)}
            </Badge>
        )
    }

    let config2 = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "title",
                label: Text('name', lang),
            },
            
            {
                name: "supplier_sku",
                label: Text('supplier_sku', lang),
            },
            {
                name: "manufacturer_sku",
                label: Text('manufacturer_sku', lang),
            },
            
            {
                name: "quantity",
                label: Text('quantity', lang),
            },
            {
                name: "is_in_stock",
                label: Text('is_in_stock',lang),
                render: renderStock
            },
            {
                name: "comment",
                label: Text('comment', lang),
                render: renderComment
            },
           
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: id+"/piece/:id",
                active: true
            },
            {
                label: <i className={"icon-trash text-l"}></i>,
                name: "deletePiece",
                active: true
            },
            {
                label: <i className={"icon-order text-l"}></i>,
                name: "orderPiece",
                active: menu.context === 'TOPAZ' ? false : true
            },
        ],
        error: Text('product_list_error', lang),
        no_results: Text('no_product', lang),
        fetchUrl: '/machine/'+id+'/pieces',
        handleAction: handleAction,
        params: {
        }
    };

    if(user.role === 'ROLE_ADMIN' || user.role === 'ROLE_COMPANY_MANAGER' || user.role === 'ROLE_SUBSIDIARY_MANAGER' || user.role === 'ROLE_SITE_MANAGER'){

        config2.headers.push
        (
            {
                name: "sku",
                label: Text('sku', lang),
            },
            {
                name: "price",
                label: Text('price', lang),
            }, 
            {
                name: "active",
                label: Text('state', lang),
                render: renderState
            }
        );
    }

    

    const config = {
        headers: [
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "products",
                label: Text('products', lang),
                render: renderProducts
            },
            
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "/machine/"+id+"/kit/:id",
                active: !(user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('update_kit')))
            },
            {
                label: <i className={"icon-trash text-l"}></i>,
                name: "delete",
                active: !(user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('delete_kit')))
            },
            {
                label: <i className={"icon-order text-l"}></i>,
                name: "order",
                active: menu.context === 'TOPAZ' ? false : true
            },
        ],
        error: Text('kit_list_error', lang),
        no_results: Text('no_kit', lang),
        fetchUrl: '/machine/'+id+'/kits',
        handleAction: handleAction,
        params: {
        }
    };

    const clearSiteList = () => {
        if(menu.sites && loadMachine){
            const index = menu.sites.findIndex(e => e.label === loadMachine.site);
            if(index !== -1){
                let tab = [ ...menu.sites ];
                tab.splice(index, 1);
                return tab;
            }
        }   

        return menu.sites;
    }

    const moveMachine = async () => {
        const params = {
            site: moveSite
        };

        const result = await post('/machine/history/'+id, params);
        if(result.success){
            moveModalRef.current.toggle();
            setLoadMachine(result.data);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const removePiece = async (pieceId) => {
        setPieceRemove(pieceId);
        setIsLoadingRemove(true);
       
        const result = await post('/machine/'+id+'/remove_piece/'+pieceId);
        if(result.success){
            modalDeletePieceRef.current.toggle();
            setLoadMachine(result.data);
            let newPieces = sortPieces(result.data.pieces)
            setLoadPieces(newPieces);
            setIsLoadingData(true);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
        setPieceRemove(null);
        setIsLoadingRemove(false);
        setIsLoadingData(false);
    }

    const uploadDocuments = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            file: data.document[0],
        }

        const result = await post("/machine/"+id+"/document/import", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            uploadModalRef.current.toggle();
            setLoadMachine(result.data);
            reset({ document: null });
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            setErrorMsg2(Text('form_error_import_document', lang));
        }
    }

    const deleteDocument = async (documentId) => {
        setIsLoadingRemove(true);

        const result = await remove("/machine/document/delete/"+documentId);

        setDocumentRemove(null);
        setIsLoadingRemove(false);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setLoadMachine(result.data);
            modalDeleteDocRef.current.toggle()
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }        
    }

    const getDocument = (document) => { 
        let url = getDocumentUrl('/document/machines/'+id+'/' + getAuthToken() + '/' + document.id);
        window.open(url);
    }

    const onToggle = (name) => {
        let newToggle = [...toggle];

        if(isToggle(name)){
            let index = toggle.findIndex(e => e === name);
            newToggle.splice(index, 1);
        } else {
            newToggle.push(name);
        }

        setToggle(newToggle);
    }

    const isToggle = (name) => {
        let index = toggle.findIndex(e => e === name);
        return index !== -1;
    }

    const orderPieces = async (totalPieces, type) => {
        setErrorMsg(null);
        setErrorMsg3(null);
        setIsLoading2(true);
        setIsLoadingType(type);

        let paramPieces = [];

        totalPieces.forEach(piece => {
            paramPieces.push({
                sku: piece.sku,
                quantity: piece.quantity
            })
        });

        let params = {
            machine: id,
            site: menu.site ? menu.site : selectSite,
            items: JSON.stringify(paramPieces)
        }

        const result = await post("/quote/add_items", params);
        
        setIsLoading2(false);

        if (result.hasOwnProperty("success") && result.success) {
            if(type === 'select'){
                orderModalRef.current.toggle();
            }
            
            setQuote({ 
                id: result.data.id,
                name: result.data.name
            });

            addSuccessModalRef.current.toggle()
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(type === 'select' && !selectSite){
                setErrorMsg3(Text('select', lang));
            } else {
                if(type === 'select'){
                    setErrorMsg3(Text('form_error_add_item', lang));
                } else {
                    setErrorMsg(Text('form_error_add_item', lang));
                }
            }
        }
    }

    const orderKits = () => {
        let newPieces = [];
        loadKits.forEach(kit => {
            newPieces = [
                ...newPieces,
                ...kit.products
            ]
        });
        selectPieces(newPieces, 'allKits');
    }

    const selectPieces = (newPieces, type) => {
        if(menu.site){
            orderPieces(newPieces, type);
        } else {
            setPieces(newPieces);
            orderModalRef.current.toggle();
        }
    }

    const onDeleteItem = (type, id) => {
        switch (type) {
            case 'piece':
                setPieceRemove(id);
                modalDeletePieceRef.current.toggle()
                break;
            case 'kit':
                setKitRemove(id);
                modalDeleteKitRef.current.toggle()
                break;
            default:
                setDocumentRemove(id);
                modalDeleteDocRef.current.toggle()
                break;
        }
    }



    const sortPieces = (pieces) => {
        let newPieces = {};

        //console.log(pieces);

        Object.keys(pieces).forEach((part, i) => {

            if(pieces[part].filter_type !== null){
                if(pieces[part].filter_type.toLowerCase().includes('filtre huile moteur')){
                    if(!newPieces.hasOwnProperty('huile_moteur_filter')){
                        newPieces.huile_moteur_filter = { name: 'Huile Moteur', items: [], image: logoHuileMoteur, order: 1};
                    }
                    newPieces.huile_moteur_filter.items = newPieces.huile_moteur_filter.items.concat(pieces[part]);
                }
                
                if(pieces[part].filter_type.toLowerCase().includes('filtre à air moteur')){
                    if(!newPieces.hasOwnProperty('air_moteur_filter')){
                        newPieces.air_moteur_filter = { name: 'Air Moteur', items: [], image: logoAirMoteur, order: 2};
                    }
                    newPieces.air_moteur_filter.items = newPieces.air_moteur_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre carburant moteur')){
                    if(!newPieces.hasOwnProperty('carburant_moteur_filter')){
                        newPieces.carburant_moteur_filter = { name: 'Carburant Moteur', items: [], image: logoCarburantMoteur, order: 3};
                    }
                    newPieces.carburant_moteur_filter.items = newPieces.carburant_moteur_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre hydraulique') ||
                    pieces[part].filter_type.toLowerCase().includes('filtre hydraulique de direction') || 
                    pieces[part].filter_type.toLowerCase().includes('filtre hydraulique de pilotage')){
                    if(!newPieces.hasOwnProperty('hydraulique_filter')){
                        newPieces.hydraulique_filter = { name: 'Hydraulique', items: [], image: logoHydraulique, order: 4};
                    }
                    newPieces.hydraulique_filter.items = newPieces.hydraulique_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre freins')){
                    if(!newPieces.hasOwnProperty('freins_filter')){
                        newPieces.freins_filter = { name: 'Freins', items: [], image: logoFreins, order: 5};
                    }
                    newPieces.freins_filter.items = newPieces.freins_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre transmission')){
                    if(!newPieces.hasOwnProperty('transmission_filter')){
                        newPieces.transmission_filter = { name: 'Transmission', items: [], image: logoPilotageEtTransmission, order: 6};
                    }
                    newPieces.transmission_filter.items = newPieces.transmission_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre cabine') || 
                pieces[part].filter_type.toLowerCase().includes('pré-filtre cabine')){
                    if(!newPieces.hasOwnProperty('cabine_filter')){
                        newPieces.cabine_filter = { name: 'Cabine', items: [], image: logoCabine, order: 7};
                    }
                    newPieces.cabine_filter.items = newPieces.cabine_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre adblue')){
                    if(!newPieces.hasOwnProperty('adblue_filter')){
                        newPieces.adblue_filter = { name: 'AdBlue', items: [], image: logoAdblue, order : 8};
                    }
                    newPieces.adblue_filter.items = newPieces.adblue_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre climatisation')|| 
                pieces[part].filter_type.toLowerCase().includes('déshydrateur de climatisation')){
                    if(!newPieces.hasOwnProperty('climatisation_filter')){
                        newPieces.climatisation_filter = { name: 'Climatisation', items: [], image: logoClimatisation, order: 9};
                    }
                    newPieces.climatisation_filter.items = newPieces.climatisation_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre refroidissement')){
                    if(!newPieces.hasOwnProperty('refroidissement_filter')){
                        newPieces.refroidissement_filter = { name: 'Refroidissement', items: [], image: logoRefroidissement, order: 10};
                    }
                    newPieces.refroidissement_filter.items = newPieces.refroidissement_filter.items.concat(pieces[part]);
                }
    
                if(pieces[part].filter_type.toLowerCase().includes('filtre pneumatique')){
                    if(!newPieces.hasOwnProperty('pneumatique_filter')){
                        newPieces.pneumatique_filter = { name: 'Pneumatique', items: [], image: logoPneumatique, order: 11};
                    }
                    newPieces.pneumatique_filter.items = newPieces.pneumatique_filter.items.concat(pieces[part]);
                }
            }else{

                if(!newPieces.hasOwnProperty('others'))
                {
                    newPieces.others = { name: 'Autres pièces', items: [], order: 12};
                }
                newPieces.others.items = newPieces.others.items.concat(pieces[part]);    
            }
            
        });

        const orderedNewPieces = Object.values(newPieces).sort(function(a, b) { return a['order'] - b['order']; });
        //console.log(orderedNewPieces);
        return orderedNewPieces; 

    }

    const selectMultiProducts = (piece) =>{
        let newSelection = [...selection];

        if(!selection.length){
            newSelection.push(piece);
            setSelection(newSelection);
        } else {
            let isSelected = false;
            let index = null;
            
            newSelection.forEach((p, i)=> {
                if(p.id === piece.id){
                    isSelected = true;
                    index = i;
                }
            })

            if(isSelected){
                newSelection.splice(index, 1);
            } else {
                newSelection.push(piece);
            }

            setSelection(newSelection);
        }
    }
    

    return (
        <ScreenContainer isLoading={isLoadingData}>
            <Modal ref={addSuccessModalRef}>
                <span className="text-primary text-m">{Text('success_add_quote', lang)}</span>
                <div className="mt-4 flex flex-col items-center">
                    <div className="w-full flex items-center justify-between mt-4">
                        <Button onPress={() => addSuccessModalRef.current.toggle()}>
                            {Text('close', lang)}
                        </Button>
                        { quote ? (
                            <Button className="ml-20" onPress={() => history.push('/quote/'+quote.id)}>
                                {Text('current_quote', lang)} { quote.name ? "("+quote.name+")" : null}
                            </Button>
                        ) : false}
                    </div>
                </div>
            </Modal>
            <Modal ref={orderModalRef}>
                <span className="text-primary text-m">{Text('select_popup_quote', lang)}</span>
                <div className="mt-4 flex flex-col items-center">
                <Message type="error">{errorMsg3}</Message>
                    <div className="flex">
                        <SelectField 
                            className="mb-4 mr-4" 
                            value={selectSite}
                            onChange={(e) => setSelectSite(e.target.value)}
                            choices={menu.sites}
                        />
                    </div>
                    <div className="w-full flex items-center justify-between mt-4">
                        <Button onPress={() => orderModalRef.current.toggle()}>
                            {Text('cancel', lang)}
                        </Button>
                        <Button onPress={() => orderPieces(pieces, 'select')} isLoading={isLoading2 && isLoadingType === 'select'}>
                            {Text('validate', lang)}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal ref={uploadModalRef}>
                <span className="text-primary text-m">{Text('import_doc', lang)}</span>
                <form onSubmit={handleSubmit2(uploadDocuments)} className="mt-4">
                    <Message type="error">{errorMsg2}</Message>
                    <FileUpload 
                        name="document"
                        options={{ required: Text('required', lang) }}
                        register={register2}
                        error={errors2?.document}
                        className="mb-6"
                    >
                        {Text('doc', lang)}
                    </FileUpload>
                    <div className="flex items-center justify-between mt-4">
                        <Button onPress={() => uploadModalRef.current.toggle()}>
                            {Text('cancel', lang)}
                        </Button>
                        <Button type="submit" isLoading={isLoading}>
                            {Text('import', lang)}
                        </Button>
                    </div>
                </form>
            </Modal>
            <Modal ref={moveModalRef}>
                <span className="text-primary text-m">{Text('popup_move_machine', lang)}</span>
                <SelectField 
                    className="mb-4 mt-4" 
                    value={moveSite}
                    options={{ required: Text('required', lang) }}
                    onChange={(e) => setMoveSite(e.target.value)}
                    choices={clearSiteList()}
                >
                    {Text('site', lang)}
                </SelectField>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => moveModalRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"success"} onPress={() => moveMachine()}>
                        {Text('move', lang)}
                    </Button>
                </div>
            </Modal>
            <Modal ref={modalRef}>
                <span className="text-primary text-m">{Text('popup_deactivate_machine', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"warning"} onPress={() => onDelete()}>
                        {Text('deactivate', lang)}
                    </Button>
                </div>
            </Modal>
            <Modal ref={modalDeletePieceRef}>
                <span className="text-primary text-m">{Text('popup_delete_piece', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalDeletePieceRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"warning"} onPress={() => removePiece(pieceRemove)} isLoading={isLoadingRemove}>
                        {Text('remove', lang)}
                    </Button>
                </div>
            </Modal>
            <Modal ref={modalDeleteKitRef}>
                <span className="text-primary text-m">{Text('popup_delete_kit', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalDeleteKitRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"warning"} onPress={() => removeKit(kitRemove)} isLoading={isLoadingRemove}>
                        {Text('remove', lang)}
                    </Button>
                </div>
            </Modal>
            <Modal ref={modalDeleteDocRef}>
                <span className="text-primary text-m">{Text('popup_delete_document', lang)}</span>
                <div className="flex items-center justify-between mt-4">
                    <Button onPress={() => modalDeleteDocRef.current.toggle()}>
                        {Text('cancel', lang)}
                    </Button>
                    <Button skin={"warning"} onPress={() => deleteDocument(documentRemove)}>
                        {Text('remove', lang)}
                    </Button>
                </div>
            </Modal>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <Message type="success">{message}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{loadMachine ? loadMachine.name : null}</h1>
                    </div>
                    {user.role !== 'ROLE_OPERATOR' && user.role !== 'ROLE_SITE_MANAGER' ?  (
                        <Button className="mb-7.5 mr-4" onPress={() => history.push(location.pathname+'/history')}>
                            {Text('history', lang)}
                        </Button>
                    ) : null }
                    {user.role !== 'ROLE_OPERATOR' && user.role !== 'ROLE_SITE_MANAGER' && menu.context !== 'TOPAZ' ? (
                        <Button className="mb-7.5 mr-4" onPress={() => moveModalRef.current.toggle()}>
                            {Text('move_machine', lang)}
                        </Button>
                    ) : null}
                    
                    {user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('delete_machine')) ? null : (
                        <Button className="mb-7.5 mr-4" onPress={() => modalRef.current.toggle()}>
                            {Text('deactivate_machine', lang)}
                        </Button>
                    )}
                    {user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('add_kit')) ? null : (
                        <Button className="mb-7.5 mr-4" onPress={() => history.push(location.pathname+'/add-kit')}>
                            {Text('add_kit', lang)}
                        </Button>
                    )}
                    {user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('update_machine')) ? null : (
                        <>
                            <Button className="mb-7.5 mr-4" onPress={() => history.push(location.pathname+'/pieces')}>
                                {Text('add_pieces', lang)}
                            </Button>
                            <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                                {Text('save', lang)}
                            </Button>
                        </>
                    )}
                     
                </div>
                <div className="flex justify-between flex-wrap">
                    <div>
                        <Box className="mr-4 mb-4 min-w-1/4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('params', lang)}</span>
                            </div>
                            <SelectField
                                name="unity_type"
                                value={unity_type}
                                options={{}}
                                register={register}
                                error={errors?.unity_type}
                                className="mb-6"
                                choices={[
                                    {value: 'hour', label: Text('number_hours', lang)},
                                    {value: 'kilometer', label: Text('kilometer', lang)},
                                ]}
                                setValue={setValue}
                            >
                                {Text('unity_type', lang)}
                            </SelectField>
                            {unity_type === 'kilometer' ? (
                                <NumberField
                                    name="kilometer"
                                    value={kilometer}
                                    options={{}}
                                    register={register}
                                    error={errors?.kilometer}
                                    className="mb-6"
                                >
                                    {Text('kilometer', lang)}
                                </NumberField>
                            ) : (
                                <NumberField
                                    name="number_hours"
                                    value={number_hours}
                                    options={{}}
                                    register={register}
                                    error={errors?.number_hours}
                                    className="mb-6"
                                >
                                    {Text('number_hours', lang)}
                                </NumberField>
                            )}
                        </Box>
                        <Box className="mr-4 min-w-1/4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('infos', lang)}</span>
                            </div>
                            <div className="flex items-center mb-6">
                                <span className="text-m font-bold mr-4">{Text('state', lang).toUpperCase()}</span>
                                <Badge type={loadMachine && loadMachine.active ? "enable" : "error"}>
                                    { loadMachine && loadMachine.active ? Text('activated', lang) : Text('disabled', lang)}
                                </Badge>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-m font-bold mb-6">{Text('site', lang).toUpperCase()} : {loadMachine ? loadMachine.site : null}</span>
                            </div>
                            {(selectBrand) ? (
                                <SelectField
                                    name="new_brand"
                                    options={{ required: Text('required', lang) }}
                                    register={register}
                                    error={errors?.brand}
                                    className="mb-6"
                                    choices={brandFilters}
                                    setValue={setValue}
                                    search
                                >
                                    {Text('brand', lang)}
                                </SelectField>
                            ) : null}
                            {(!selectBrand) ? (
                                <div className="text-m font-bold mb-6">{Text('brand', lang).toUpperCase()} : {loadMachine ? loadMachine.brand : null}</div>
                            ) : null}
                            <Button className="mb-7.5 mr-4" onPress={() => setBrands()}>
                                {selectBrand ? Text('cancel', lang) : Text('update_brand', lang)}
                            </Button>
                            {new_brand && selectBrand? (
                                <SelectField
                                    name="model"
                                    options={{ required: Text('required', lang) }}
                                    register={register}
                                    error={errors?.model}
                                    className="mb-6"
                                    choices={modelFilters}
                                    setValue={setValue}
                                    search
                                >
                                    {Text('model', lang)}
                                </SelectField>
                            ) : null}
                            { (brand && !selectBrand)  ? (
                                <div className="text-m font-bold mb-6">{Text('model', lang).toUpperCase()} : {loadMachine ? loadMachine.model : null}</div>
                            ) : null}
                           
                            <TextField
                                name="name"
                                options={{}}
                                register={register}
                                error={errors?.name}
                                className="mb-6"
                            >
                                {Text('name', lang)}
                            </TextField>
                            {/* <TextField
                                name="name"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.name}
                                className="mb-6"
                            >
                                {Text('name', lang)}
                            </TextField> */}
                            <TextField
                                name="engine"
                                options={{}}
                                register={register}
                                error={errors?.engine}
                                className="mb-6"
                            >
                                {Text('engine', lang)}
                            </TextField>
                            <TextField
                                name="serial_number"
                                options={{}}
                                register={register}
                                error={errors?.serial_number}
                                className="mb-6"
                            >
                                {Text('serial_number', lang)}
                            </TextField>
                            <TextField
                                name="park_number"
                                options={{}}
                                register={register}
                                error={errors?.park_number}
                                className="mb-6"
                            >
                                {Text('park_number', lang)}
                            </TextField>
                            <TextField
                                name="year"
                               options={{}}
                                register={register}
                                error={errors?.year}
                                className="mb-6"
                            >
                                {Text('year', lang)}
                            </TextField>
                            <TextField
                                name="additionals_informations"
                                options={{}}
                                register={register}
                                error={errors?.additionals_informations}
                                className="mb-6"
                            >
                                {Text('additionals_informations', lang)}
                            </TextField>
                            <TextAreaField
                                name="comment"
                                options={{}}
                                register={register}
                                error={errors?.comment}
                                className="mb-6"
                            >
                                {Text('comment', lang)}
                            </TextAreaField>
                            <TextAreaField
                                name="version"
                                options={{}}
                                register={register}
                                error={errors?.version}
                                className="mb-6"
                            >
                                {Text('version', lang)}
                            </TextAreaField>
                            <div className="mb-4">
                                <span className="block font-bold text-xs">{Text('docs', lang).toUpperCase()}</span>
                            </div>
                            <Button className="mb-4" onPress={() => uploadModalRef.current.toggle() }>{Text('import_doc', lang)}</Button>
                            <div className="w-full h-100 bg-secondary rounded border flex flex-col overflow-auto px-4 py-2">
                                {loadMachine && loadMachine.hasOwnProperty('documents') && loadMachine.documents.length ? loadMachine.documents.map((document, i) => (
                                    <div key={i} className="flex items-center justify-between mb-2">
                                        <span className="mr-2 cursor-pointer hover:bg-grid p-2 rounded" onClick={() => getDocument(document)}>{document.original_name}</span>
                                        <Button skin={"warning"} onPress={() => onDeleteItem('doc', document.id)} isLoading={isLoadingRemove && documentRemove === document.id}>{Text('delete', lang)}</Button>
                                    </div>
                                )) : (
                                    <Message>{Text('no_doc', lang)}</Message>
                                )}
                            </div>
                        </Box>
                    </div>
                    <div className="flex flex-col flex-1 w-4/6">
                        
                            
                        
                        <Box className="mb-4 md:mb-0 min-w-1/4 overflow-x-auto" isLoading={isLoadingData}>
                            <div className="mb-4 flex justify-between items-center">
                            <span className="text-xl title">{Text('products', lang)}</span>
                                <div className="flex items-center">
                                    { menu.context === 'TOPAZ' ? null : (
                                        <div className="flex">
                                            <Button  className="mb-7.5 mr-4" onPress={() => selectPieces(selection, loadPieces.name)} isLoading={isLoading2 && isLoadingType === loadPieces.name} disabled={!selection.length}>{Text('order_all_pieces', lang)}</Button>
                                        </div>
                                    )}
                                </div>
                            </div>

                        {loadPieces && loadMachine && loadMachine.hasOwnProperty('pieces') && loadMachine.pieces.length > 0 ? 
                    
                            Object.keys(loadPieces).map((part, i) => loadPieces[part].order ? (
                                <div className="flex flex-col mb-4" toggleActive={true} toggle={isToggle(part)}>
                                    <div className="mb-4 flex justify-between items-center ">
                                        <div className="flex items-center">
                                            <i className={isToggle(part) ? "icon-more icon-md text-2xl mr-4 hover:bg-primary hover:text-secondary rounded-full px-2 cursor-pointer" : "icon-less icon-md text-2xl mr-4 hover:bg-primary hover:text-secondary rounded-full px-2 cursor-pointer"} onClick={() => onToggle(part)}></i>
                                            <img className="mr-2" src={loadPieces[part].image} width={25}/>
                                            <span className="text-xl title">{loadPieces[part].name} ({loadPieces[part].items.length})</span>
                                        </div>
                                    </div>
                                    { !isToggle(part) ? (
                                        <div className="flex">
                                            <table className={"tab-inner w-full"}>
                                                <thead>
                                                    <tr className="border-b border-gray-400">
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                        </th>
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('image', lang)}
                                                        </th>
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('name', lang)}
                                                        </th>
                                                        {user.role !== 'ROLE_OPERATOR' ? (
                                                            <th className="px-4 py-2 text-left text-gray-500">
                                                                {Text('sku', lang)}
                                                            </th>
                                                        ) : null }
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('supplier_sku', lang)}
                                                        </th>
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('manufacturer_sku', lang)}
                                                        </th>
                                                        {user.role !== 'ROLE_OPERATOR' ? (
                                                            <th className="px-4 py-2 text-left text-gray-500">
                                                                {Text('price', lang)}
                                                            </th>
                                                        ): null }
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('quantity', lang)}
                                                        </th>
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('is_in_stock', lang)}
                                                        </th>
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            {Text('comment', lang)}
                                                        </th>
                                                        {user.role !== 'ROLE_OPERATOR' ? (
                                                            <th className="px-4 py-2 text-left text-gray-500">
                                                                {Text('state', lang)}
                                                            </th>
                                                        ): null }
                                                        <th className="px-4 py-2 text-left text-gray-500">
                                                            
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {loadPieces[part].hasOwnProperty('items') && loadPieces[part].items ? 
                                                        loadPieces[part].items.map((piece, j) => (
                                                            <tr key={i+"-"+j} className={j === loadPieces[part].length - 1? null : "border-b border-gray-400"} >
                                                                <td className="px-4 py-2">
                                                                    <input type="checkbox" onChange={(e) => selectMultiProducts(piece)} />
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    { piece.image ? <img src={piece.image} width={50}/> : <span>-</span>}
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    <span>{piece.title}</span>
                                                                </td>
                                                                {user.role !== 'ROLE_OPERATOR' ? (
                                                                    <td className="px-4 py-2">
                                                                        <span>{piece.sku}</span>
                                                                    </td>
                                                                ) : null }
                                                                <td className="px-4 py-2">
                                                                    <span>{piece.supplier_sku}</span>
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    <span>{piece.manufacturer_sku}</span>
                                                                </td>
                                                                {user.role !== 'ROLE_OPERATOR' ? (
                                                                    <td className="px-4 py-2">
                                                                        <span>{piece.price}</span>
                                                                    </td>
                                                                ) : null }
                                                                <td className="px-4 py-2">
                                                                    <span>{piece.quantity}</span>
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    <span>{renderStock(piece.is_in_stock)}</span>
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    <span>{piece.comment ? piece.comment : '-'}</span>
                                                                </td>
                                                                {user.role !== 'ROLE_OPERATOR' ? (
                                                                    <td className="px-4 py-2">
                                                                        <span>{renderState(piece.state)}</span>
                                                                    </td>
                                                                ) : null }
                                                                <td className="flex flex-raw px-4 py-2">
                                                                    <Button onPress={() => history.push(location.pathname+'/piece/'+piece.id)} className="mr-4"><i className={"icon-update text-l"}></i></Button>
                                                                    <Button onPress={() => onDeleteItem('piece', piece.id)} className="mr-4" isLoading={isLoadingRemove && pieceRemove === piece.id}><i className={"icon-trash text-l"}></i></Button>
                                                                    { menu.context === 'TOPAZ' ? null : (
                                                                        <Button onPress={() => selectPieces([piece], piece.id)} isLoading={isLoading2 && isLoadingType === piece.id}><i className={"icon-order text-l"} ></i></Button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )) 
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    ): null}                   
                                </div>
                            ) :
                             null
                            ) 
                        : (
                            <Message>Aucun produit trouvé</Message>
                        )}
                        </Box>
                        
                        <Box className="mt-4 mb-4 md:mb-0 min-w-1/4">
                            <div className="mb-4 flex justify-between items-center">
                                <span className="text-xl title">{Text('kits', lang)}</span>
                                <div className="flex items-center">
                                    { loadKits && loadKits.length && menu.context !== 'TOPAZ' ? (
                                        <Button className="mr-4" onPress={() => orderKits()} isLoading={isLoading2 && isLoadingType === 'allKits'}>
                                            {Text('order_all_kits', lang)}
                                        </Button>  
                                    ) : null}
                                </div>
                            </div>
                            <Tab {...{config}}/>
                        </Box>
                    </div>
                </div>
                {user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('update_machine')) ? null : (
                    <div className="flex justify-end mt-4">
                        <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                            {Text('save', lang)}
                        </Button>
                    </div>
                )}
            </form>
        </ScreenContainer>
    );
}

export default Machine;