import React, {useState, useEffect} from 'react';

function Paging({ maxPage, currentPage, setPage, maxItems, currentItems }) {
    const itemStyle="p-2 hover:bg-blue-400 hover:text- cursor-pointer";
    const [ newPage, setNewPage ] = useState(currentPage);

    useEffect(() => {
        setNewPage(currentPage);
    }, [currentPage])

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     let page = newPage;
    //     if(!page){
    //         page = 1
    //     } 

    //     setPage(page);
    // }
    
    return (
        <div className="flex justify-between items-center pb-4 px-4">
            <span></span>
            <ul className="flex justify-center items-center rounded">
                {currentPage > 1 && (<> 
                    <li className={itemStyle + " rounded-l-lg"} onClick={() => setPage(1)}>{"<<"}</li>
                    <li className={itemStyle} onClick={() => setPage(currentPage - 1)}>{"<"}</li>
                    {currentPage > 1 ? (
                         <li className={itemStyle} onClick={() => setPage(currentPage - 1)}>{currentPage - 1}</li>
                    ) : null}
                   
                    </>)}
                <li className={"p-2"}>
                    {/* <form onSubmit={(e) => handleSubmit(e)}>
                        <NumberField max={maxPage} className={"w-24 text-center py-2 px-4 rounded"} value={newPage} setValue={(value) => setNewPage(value)} isInt/>
                    </form> */}
                    <span className="bg-secondary p-2 rounded">{newPage}</span>
                </li>
                { currentPage < maxPage && (<> 
                    { currentPage + 1 < maxPage ? (
                         <li className={itemStyle} onClick={() => setPage(currentPage + 1)}>{currentPage + 1}</li>
                    ) : null }
                    <li className={itemStyle} onClick={() => setPage(currentPage + 1)}>{">"}</li>
                    <li className={itemStyle + " rounded-r-lg"} onClick={() => setPage(maxPage)}>{">>"}</li>
                    </>)}
            </ul> 
            <span className="flex justify-center rounded-lg">{maxItems} résultats</span>  
        </div>
         
    )
}

export default Paging;