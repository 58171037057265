import React, { useContext, useState, useEffect, useRef} from "react";

import { ScreenContainer, Tab, Button, Box, Modal, Message, FileUpload } from '../components';
import { useHistory } from 'react-router-dom';
import { MenuContext } from '../contexts/MenuContext';
import { LangContext } from '../contexts/LangContext';
import { QuoteContext } from '../contexts/QuoteContext';
import { UserContext } from '../contexts/UserContext';
import Text from '../utils/text';
import getStatus from '../utils/status';
import { get, getAuthToken, getServerDomain, post } from '../server/api';
import * as c from '../server/config';
import { useForm } from 'react-hook-form';

function Dashboard() {
    const history = useHistory();
    const [ menu ] = useContext(MenuContext);
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const [ quote, setQuote] = useContext(QuoteContext);
    const [ loadStat, setLoadStat ] = useState();
    const [ errorMsg, setErrorMsg ] = useState();
    const [ successMsg, setSuccessMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [contextAdmin, setContextAdmin] = useState();

    //const modalFaqRef = useRef();
    const modalWarrantyProcedureRef = useRef();
    const modalReturnRequestRef = useRef();
    const modalInstructionsForUseRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
    } = useForm({});

    const {
        register: register3,
        handleSubmit: handleSubmit3,
        formState: { errors: errors3 },
    } = useForm({});

    const {
        register: register4,
        handleSubmit: handleSubmit4,
        formState: { errors: errors4 },
    } = useForm({});

    useEffect(() => {
        if(user && user.loggedIn){
            let contextAdminIndex = user.companies.findIndex(e => e.value == menu.company);
            setContextAdmin( user.role === 'ROLE_ADMIN' && contextAdminIndex !== -1 ? user.companies[contextAdminIndex].label : false);
        }
        
        const load = async () =>{
            let params = {
                company: menu.company,
                subsidiary: menu.subsidiary,
                site: menu.site
            }

            const result = await get('/dashboard', params);

            if(result.success){
                setLoadStat(result.data);
            }
        }

        if(menu){
            load();
        }
        
    }, [menu])

    const handleAction = (action, element) => {
        setQuote({ 
            id: element.id,
            name: element.name
        });
        let url = "/quote/"+element.id;
        history.push(url);
    }

    const renderStatus = (e) => {
        return getStatus(e, lang);
    }

    const getQueryStatus = () => {
        let role = user.role;

        switch (role) {
            case 'ROLE_ADMIN':
                return 'validated';
            case 'operator':
                return 'draft';
            default:
                return 'pending';
        }
    }

    const config = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
            {
                name: "total_price_incl_tax",
                label: Text('total_price_incl_tax', lang),
            },
            {
                name: "status",
                label: Text('status', lang),
                render: renderStatus
            },
            
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "quote/:id",
                active: true
            },
        ],
        error: Text('quote_list_error', lang),
        no_results: Text('no_quote', lang),
        fetchUrl: '/quotes',
        handleAction: handleAction,
        params: { 
            company:  menu.context !== 'TOPAZ' ? menu.company : null, 
            subsidiary: menu.subsidiary ? menu.subsidiary : null, 
            site: menu.site ? menu.site : null, 
            status: getQueryStatus()
        }
    };

    const config2 = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
            {
                name: "total_price_incl_tax",
                label: Text('total_price_incl_tax', lang),
            },
            {
                name: "status",
                label: Text('status', lang),
                render: renderStatus
            },
            
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "quote/:id",
                active: true
            },
        ],
        error: Text('quote_list_error', lang),
        no_results: Text('no_quote', lang),
        fetchUrl: '/quotes',
        handleAction: handleAction,
        params: { 
            company: menu.context !== 'TOPAZ' ? menu.company : null, 
            subsidiary: menu.subsidiary ? menu.subsidiary : null, 
            site: menu.site ? menu.site : null, 
            status: "complete"
        }
    };

    // const uploadFaq = async (data) => {
    //     setErrorMsg(null);
    //     setIsLoading(true);

    //     let params = {
    //         file: data.faq[0],
    //     }

    //     const result = await post("/document/import/faq", params);

    //     setIsLoading(false);

    //     if (result.hasOwnProperty("success") && result.success) {
    //         setSuccessMsg(Text('form_success_import_document', lang));
    //     } else {
    //         if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
    //             setUser({loggedIn: false});
    //         }

    //         if(result.hasOwnProperty("error") && result.error){
    //             switch (result.error) {
    //                 default:
    //                     setErrorMsg(Text('form_error_import_document', lang));
    //                     break;
    //             }
    //         }
    //     }
    // }

    const uploadWarrantyProcedure = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            file: data.warranty_procedure[0],
        }

        const result = await post("/document/import/warranty_procedure", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('form_success_import_document', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                switch (result.error) {
                    default:
                        setErrorMsg(Text('form_error_import_document', lang));
                        break;
                }
            }
        }
    }

    const uploadReturnRequest = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            file: data.return_request[0],
        }

        const result = await post("/document/import/return_request", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('form_success_import_document', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                switch (result.error) {
                    default:
                        setErrorMsg(Text('form_error_import_document', lang));
                        break;
                }
            }
        }
    }

    const uploadInstructionsForUse = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            file: data.instructions_for_use[0],
        }

        const result = await post("/document/import/instructions_for_use", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('form_success_import_document', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                switch (result.error) {
                    default:
                        setErrorMsg(Text('form_error_import_document', lang));
                        break;
                }
            }
        }
    }

    const clearSuccessMsg = (type) => {
        switch (type) {
            // case 'modalFaqRef':
            //     modalFaqRef.current.toggle()
            //     break;

            case 'modalWarrantyProcedureRef':
                modalWarrantyProcedureRef.current.toggle()
                break;
            
            case 'modalReturnRequestRef':
                modalWarrantyProcedureRef.current.toggle()
                break;
        
            default:
                modalInstructionsForUseRef.current.toggle()
                break;
        }

        setSuccessMsg(null);
    }
    
    return (
        <ScreenContainer>
            {/* <Modal ref={modalFaqRef}>
                <span className="text-primary text-m">{Text('import_document', lang)}</span>
                <form onSubmit={handleSubmit(uploadFaq)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalFaqRef')}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg}</Message>
                            <FileUpload 
                                name="faq"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.machines}
                                className="mb-6"
                            >
                                {Text('faq', lang)}
                            </FileUpload>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalFaqRef')}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" isLoading={isLoading}>
                                    {Text('import', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal> */}
            <Modal ref={modalWarrantyProcedureRef}>
                <span className="text-primary text-m">{Text('import_document', lang)}</span>
                <form onSubmit={handleSubmit2(uploadWarrantyProcedure)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalWarrantyProcedureRef')}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg}</Message>
                            <FileUpload 
                                name="warranty_procedure"
                                options={{ required: Text('required', lang) }}
                                register={register2}
                                error={errors2?.machines}
                                className="mb-6"
                            >
                                {Text('guarantee_process', lang)}
                            </FileUpload>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalWarrantyProcedureRef')}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" isLoading={isLoading}>
                                    {Text('import', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal>
            <Modal ref={modalReturnRequestRef}>
                <span className="text-primary text-m">{Text('import_document', lang)}</span>
                <form onSubmit={handleSubmit3(uploadReturnRequest)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalReturnRequestRef')}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg}</Message>
                            <FileUpload 
                                name="warranty_procedure"
                                options={{ required: Text('required', lang) }}
                                register={register3}
                                error={errors3?.machines}
                                className="mb-6"
                            >
                                {Text('guarantee_process', lang)}
                            </FileUpload>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalReturnRequestRef')}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" isLoading={isLoading}>
                                    {Text('import', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal>
            <Modal ref={modalInstructionsForUseRef}>
                <span className="text-primary text-m">{Text('import_document', lang)}</span>
                <form onSubmit={handleSubmit4(uploadInstructionsForUse)} className="mt-4">
                    { successMsg ? (
                        <>
                            <Message type="success">{successMsg}</Message>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalInstructionsForUseRef')}>
                                    {Text('close', lang)}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Message type="error">{errorMsg}</Message>
                            <FileUpload 
                                name="instructions_for_use"
                                options={{ required: Text('required', lang) }}
                                register={register4}
                                error={errors4?.machines}
                                className="mb-6"
                            >
                                {Text('notice', lang)}
                            </FileUpload>
                            <div className="flex items-center justify-between mt-4">
                                <Button onPress={() => clearSuccessMsg('modalInstructionsForUseRef')}>
                                    {Text('cancel', lang)}
                                </Button>
                                <Button type="submit" isLoading={isLoading}>
                                    {Text('import', lang)}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </Modal>
            <div className="flex items-center w-full">
                <div className="w-full mr-4">
                    <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('dashboard', lang)}</h1>
                </div>
            </div>
            { loadStat ? (
                <div className="flex mb-4">
                    { user.role === 'ROLE_ADMIN' && contextAdmin === 'TOPAZ' ? (
                        <Box className="flex-1 mr-4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('n_company', lang)}</span>
                            </div>
                            <span className="text-xl">{loadStat.companies}</span>
                        </Box>
                    ) : null}
                    { user.role === 'ROLE_ADMIN' || user.role === 'ROLE_COMPANY_MANAGER' ? (
                        <Box className="flex-1 mr-4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('n_subsidiary', lang)}</span>
                            </div>
                            <span className="text-xl">{loadStat.subsidiaries}</span>
                        </Box>
                    ) : null}
                    { user.role === 'ROLE_ADMIN' || user.role === 'ROLE_COMPANY_MANAGER' || user.role === 'ROLE_SUBSIDIARY_MANAGER'? (
                        <Box className="flex-1 mr-4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('n_site', lang)}</span>
                            </div>
                            <span className="text-xl">{loadStat.sites}</span>
                        </Box>
                    ) : null}
                    <Box className="flex-1 mr-4">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('n_machine', lang)}</span>
                        </div>
                        <span className="text-xl">{loadStat.machines}</span>
                    </Box>
                    <Box className="flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('docs', lang)}</span>
                        </div>
                        <div className="flex items-center justify-between mb-4">
                            <span className="text-m font-bold mr-4">{Text('notice', lang)}</span>
                            <div className="flex">
                                <Button className="mr-4" onPress={() => window.open(getServerDomain() + loadStat.documents.instructions_for_use.replace('{token}', getAuthToken()))}><i className={"icon-doc text-l"}></i></Button>
                                {user.role === 'ROLE_ADMIN' ? (
                                    <Button onPress={() =>clearSuccessMsg('modalInstructionsForUseRef')}><i className={"icon-import text-l"}></i></Button>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-4">
                            <span className="text-m font-bold mr-4">{Text('back_request', lang)}</span>
                            <div className="flex">
                                <Button className="mr-4" onPress={() => window.open(getServerDomain() + loadStat.documents.return_request.replace('{token}', getAuthToken()))}><i className={"icon-doc text-l"}></i></Button>
                                {user.role === 'ROLE_ADMIN' ? (
                                    <Button onPress={() =>clearSuccessMsg('modalReturnRequestRef')}><i className={"icon-import text-l"}></i></Button>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-4">
                            <span className="text-m font-bold mr-4">{Text('guarantee_process', lang)}</span>
                            <div className="flex">
                                <Button className="mr-4" onPress={() => window.open(getServerDomain() + loadStat.documents.warranty_procedure.replace('{token}', getAuthToken()))}><i className={"icon-doc text-l"}></i></Button>
                                {user.role === 'ROLE_ADMIN' ? (
                                    <Button onPress={() =>clearSuccessMsg('modalWarrantyProcedureRef')}><i className={"icon-import text-l"}></i></Button>
                                ) : null}
                            </div>
                        </div>
                        {/* <div className="flex items-center justify-between mb-4">
                            <span className="text-m font-bold mr-4">{Text('faq', lang)}</span>
                            <div className="flex">
                                <Button className="mr-4" onPress={() => window.open(getServerDomain() + loadStat.documents.faq.replace('{token}', getAuthToken()))}><i className={"icon-doc text-l"}></i></Button>
                                {user.role === 'ROLE_ADMIN' ? (
                                    <Button onPress={() => clearSuccessMsg('modalFaqRef')}><i className={"icon-import text-l"}></i></Button>
                                ) : null}
                            </div>
                        </div> */}
                    </Box>
                </div>
            ) : null}
            <div className="flex mb-4">
                <Box className="flex-1 mr-4">
                    <div className="mb-4">
                        <span className="text-xl title">{Text('quote_pending', lang)}</span>
                    </div>
                    <Tab {...{config}} />
                </Box>
                <Box className="flex-1">
                    <div className="mb-4">
                        <span className="text-xl title">{Text('quote_complete', lang)}</span>
                    </div>
                    <Tab config={config2} />
                </Box>
            </div>
        </ScreenContainer>
    );
}

export default Dashboard;