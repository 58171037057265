import React from 'react';

function TextAreaField({ children, name, inputClassName, className, register, placeholder, onChange, defaultValue, error, options, extraContent}) {

    className = "form-field relative " + className;
    let errorMessage = error ? error.message : null;

    const inputRegister = register ? register(name, options) : false;
    const handleOnChange = (e) => {
        if(typeof onChange !== "undefined") {
            onChange(e);
        }

        if(inputRegister){
            inputRegister.onChange(e);
        }
    }

    let isRequired = options && options.hasOwnProperty("required");
    if (isRequired) {
        className += " required";
    }

    return (
        <div className={className}>
            <div className="relative">
                <div className="flex flex-row justify-between mb-2.5">
                    {children ? (
                        <label
                            htmlFor={"input-" + name}
                            className=" block font-bold text-xs"
                        >
                            {children.toUpperCase()}
                        </label>
                    ) : null}
                    {extraContent ? extraContent : !isRequired && register ? (<span className="font-bold text-xs opacity-50">Facultatif</span>) : null}
                </div>
                <textarea 
                    id={"input-" + name} 
                    className={ inputClassName ? inputClassName : "bg-primary-50 border-primary-50 focus:outline-none border w-full py-2 px-4 rounded"}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    rows="5"
                    
                    onChange={handleOnChange}
                    onBlur={inputRegister.onBlur}
                    ref={inputRegister.ref}
                    name={inputRegister.name}
                /> 
            </div>
            {errorMessage ? <span className="text-sm text-status-100">{errorMessage}</span> : null}
        </div>
    )
}

export default TextAreaField;