import { setUser } from "@sentry/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams} from 'react-router-dom';
import { ScreenContainer, SelectField, Box, TextField, Button, Modal, Message } from '../../components';
import TabSelection from "../../components/TabSelection";
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { get, post } from "../../server/api";
import Text from '../../utils/text';

function MachinesPending() {
    const [ lang ] = useContext(LangContext);
    const [ search, setSearch] = useState('');
    const [ selectCustomBrand, setSelectCustomBrand ] = useState(false);
    const [ selectCustomModel, setSelectCustomModel ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState();
    const [ successMsg, setSuccessMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ isLoadingData, setIsLoadingData ] = useState(false);

    const [parkNumber, setParkNumber] = useState('');
    const [site, setSite] = useState('');
    const [customBrand, setCustomBrand] = useState('')
    const [customModel, setCustomModel] = useState('')

    const [selection, setSelection] = useState([]);
    const [brandFilters, setBrandFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [modelFilters, setModelFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [customBrandFilters, setCustomBrandFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [customModelFilters, setCustomModelFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [siteFilters, setSiteFilters] = useState([{label: Text('select', lang), value: ''}]);
    const [parkNumberFilters, setParkNumberFilters] = useState([{label: Text('select', lang), value: ''}])
    const [char_filters, setCharFilters] = useState('');
    const [type_filters, setTypeFilters] = useState('');
    
    const modalMassiveUpdate = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors},
        watch,
        clearErrors,
        unregister,
        setValue,
        reset
    } = useForm({});

    let brand = watch('brand');
    let model = watch('model');
    let custom_model = watch('custom_models');
    let custom_brand = watch('custom_brands');
    
    useEffect(() => {
        const loadFilters = async () => {   
            const params = {
                search,
                custom_brand : customBrand,
                custom_model : customModel,
                park_number: parkNumber,
                site,
                char_filters, 
                type_filters
            }

            const result = await get('/machine/pending/filters', params);
            
            if(result.success){
                let filters = result.data;

                if(filters.hasOwnProperty('custom_brands')){
                    let newCustomBrandFilters = [{label: Text('select', lang), value: ''}];

                    filters.custom_brands.forEach((new_custom_brand) => {
                        newCustomBrandFilters.push(new_custom_brand);
                    })

                    setCustomBrandFilters(newCustomBrandFilters);
                }

                if(filters.hasOwnProperty('custom_models')){
                    let newCustomModelFilters = [{label: Text('select', lang), value: ''}];

                    filters.custom_models.forEach((new_custom_model) => {
                        newCustomModelFilters.push(new_custom_model);
                    })

                    setCustomModelFilters(newCustomModelFilters);
                }

                if(filters.hasOwnProperty('park_numbers')){
                    let newParkNumbersFilters = [{label: Text('select', lang), value: ''}];

                    filters.park_numbers.forEach((new_park_number) => {
                        newParkNumbersFilters.push(new_park_number);
                    })

                    setParkNumberFilters(newParkNumbersFilters);
                }

                if(filters.hasOwnProperty('sites')){
                    let newSiteFilters = [{label: Text('select', lang), value: ''}];

                    filters.sites.forEach((new_site) => {
                        newSiteFilters.push(new_site);
                    })

                    setSiteFilters(newSiteFilters);
                }


            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        loadFilters();
        
    }, [search, customBrand, customModel, parkNumber, site, char_filters]);

    useEffect(() => {
        const loadBrands = async () => {
            const result = await get('/brands');

            if(result.success){
                let brands = result.data;

                if(brands && brands.length){
                    let newBrandFilters = [{label: Text('select', lang), value: ''}];

                    brands.forEach((brand) => {
                        newBrandFilters.push(brand);
                    })

                    setBrandFilters(newBrandFilters);
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }
        loadBrands();
    }, [])

    useEffect(() => {
        const loadModels = async () => {
            const params = {
                brand,
            }

            const result = await get('/models', params);

            if(result.success){
                let models = result.data;

                if(models && models.length){
                    let newModelFilters = [{label: Text('select', lang), value: ''}];

                    models.forEach((model) => {
                        newModelFilters.push(model);
                    })

                    setModelFilters(newModelFilters);
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
        }

        if(brand){
            loadModels();
        }
    }, [brand])

    useEffect(() => {
        if(char_filters){
            setCharFilters('');
            setTypeFilters('');
        }
    }, [search, customBrand, customModel, parkNumber, site]);

    const config = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "model",
                label: Text('model', lang),
            },
            {
                name: "brand",
                label: Text('brand', lang),
            },
            {
                name: "site",
                label: Text('site', lang),
            },
            {
                name: "park_number",
                label: Text('park_number', lang),
            },
            {
                name: "created_by",
                label: Text('created_by', lang),
            },
        ],
        actions: [
            {
                label: <i className={"icon-update text-l"}></i>,
                name: "/machine/pending/:id",
                active: true
            },
        ],
        error: Text('machine_list_error', lang),
        no_results: Text('no_machine', lang),
        fetchUrl: '/machines',
        params: {
            status: 'pending',
            search,
            custom_brand : customBrand,
            custom_model : customModel,
            park_number: parkNumber,
            site : site,
            page_type : 'machines_pending'
        }
    };

    const setCustomBrands = () => {
        unregister('custom_brand');
        unregister('custom_model');
        unregister('brand');
        unregister('model');

        if(selectCustomBrand){
            if(custom_brand){
                unregister('custom_brand');
            }
            if(custom_model){
                unregister('custom_model');
            }
            
        } else {
            if(brand){
                unregister('brand');
            }
            if(model){
                unregister('model');
            }
        }
        setSelectCustomBrand(!selectCustomBrand);
    }

    const setCustomModels = () => {
        unregister('model');
        unregister('custom_model');
        if(selectCustomModel){
            if(model){
                unregister('model');
            }
        } else {
            if(custom_model){
                unregister('custom_model');
            }
        }
        setSelectCustomModel(!selectCustomModel)
    }


    const selectMultiMachines = (machine) =>{
        let newSelection = [...selection];

        if(!selection.length){
            newSelection.push(machine.id);
        } else {
            let isSelected = false;
            let index = null;
            
            newSelection.forEach((m, i)=> {
                if(m === machine.id){
                    isSelected = true;
                    index = i;
                }
            })

            if(isSelected){
                newSelection.splice(index, 1);
            } else {
                newSelection.push(machine.id);
            }
           
        }

        setSelection(newSelection);
    }

    const selectAllMachines = (tabIndex, items, isCheck) => {

        let newSelection = [...selection];
        if(!isCheck){
            items.forEach((machine, index) => {
                if(tabIndex.includes(index)){
                    newSelection.push(machine.id);
                }
            });
            
        }else{
            newSelection = [];
        }
        

        setSelection(newSelection);
    }

    const searchValue = async(charFilters, typeFilters) =>{
        setCharFilters(charFilters)
        setTypeFilters(typeFilters)
    }

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);
        let machinesId = [...selection];

        if(!selectCustomBrand){
            data.custom_brand = '';
        } else {
            data.brand = '';
        }

        if(!selectCustomModel){
            data.custom_model = '';
        } else {
            data.model = '';
        }

        let params = {
           ...data,
           machines: machinesId
        }

        const result = await post('/machines/validate', params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            
            setSuccessMsg(Text('form_success_massive_update', lang));
            setSelection([]);

            unregister('custom_brand');
            unregister('custom_model');
            unregister('brand');
            unregister('model');

            reset({
                custom_model,
                custom_brand
            })

            modalMassiveUpdate.current.toggle();

        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){

                setErrorMsg(Text('form_error_massive_update', lang));
            }
        }
        
    }

    useEffect(() => {
        setTimeout(() => {
            setSuccessMsg(false)
        }, 10000);
    }, [successMsg])

    return (
        <ScreenContainer isLoading={isLoadingData} >
            <Modal ref={modalMassiveUpdate}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <span className="text-primary text-m"> {selection.length} {Text('form_popup_massive_update', lang)}</span>
                <div className="mt-4 flex flex-col items-left">
                    <Message type="error">{errorMsg}</Message>
                    <div className="flex-col">
                        {(!selectCustomBrand) ? (
                            <SelectField
                                name="brand"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.brand}
                                className="mb-6"
                                choices={brandFilters}
                                setValue={setValue}
                                search
                            >
                                {Text('brand', lang)}
                            </SelectField>
                        ) : null}
                        {(selectCustomBrand) ? (
                            <TextField
                                name="custom_brand"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors}
                                className="mb-6"
                                
                            >
                                {Text('brand', lang)}
                            </TextField>
                         ) : null}
                        <Button className="mb-7.5 mr-4" onPress={() => setCustomBrands()}>
                            {!selectCustomBrand ? Text('select_custom_brand', lang) : Text('select_brand', lang)}
                        </Button>
                        {brand && !selectCustomModel ? (
                            <SelectField
                                name="model"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.model}
                                className="mb-6"
                                choices={modelFilters}
                                setValue={setValue}
                                search
                            >
                                {Text('model', lang)}
                            </SelectField>
                        ) : null}
                        {selectCustomModel || (brand && selectCustomModel) || selectCustomBrand? (
                            <TextField
                                name="custom_model"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.custom_model}
                                className="mb-6"
                            >
                                {Text('model', lang)}
                            </TextField>
                        ) : null}
                        {!selectCustomBrand && brand ? (
                            <Button className="mb-7.5 mr-4" onPress={() => setCustomModels()}>
                                {!selectCustomModel ? Text('select_custom_model', lang) : Text('select_model', lang)}
                            </Button>
                        ) : null}

                        <TextField
                            name="engine"
                            options={{}}
                            register={register}
                            error={errors?.engine}
                            className="mb-6"
                        >
                            {Text('engine', lang)}
                        </TextField>
                        <TextField
                            name="version"
                            options={{}}
                            register={register}
                            error={errors?.version}
                            className="mb-6"
                        >
                            {Text('version', lang)}
                        </TextField>
                        <TextField
                            name="name"
                            options={{}}
                            register={register}
                            error={errors?.name}
                            className="mb-6"
                        >
                            {Text('name', lang)}
                        </TextField>

                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => modalMassiveUpdate.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button type="submit" skin={"success"} isLoading={isLoading}>
                                {Text('validate', lang)}
                            </Button>
                        </div>

                        
                    </div>
                </div>
                </form>
            </Modal>
            <div className="flex-col items-center w-full">
                <div className="flex">
                    <Message type="success">{successMsg}</Message>
                </div>
                <div className="flex">
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('machines_pending', lang)}</h1>
                    </div>
                    <Button className="mb-7.5 mr-4" onPress={() => modalMassiveUpdate.current.toggle()} disabled={selection.length === 0}>
                        {Text('massive_machines_update', lang)}  
                    </Button>
                </div>
            </div>
            <div className="flex">
                <Box className="mr-4 w-96">
                    <TextField className="mb-4" onChange={(e) => setSearch(e.target.value)} >
                        {Text('search', lang)}
                    </TextField>
                        <SelectField 
                            name="custom_brand"
                            className="mb-4" 
                            value={customBrand}
                            onChange={(e) => setCustomBrand(e.target.value)}
                            choices={customBrandFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'custom_brand')  }
                        >
                            {Text('brand', lang)}
                        </SelectField>
                        <SelectField 
                            name="custom_model"
                            className="mb-4" 
                            value={customModel}
                            onChange={(e) => setCustomModel(e.target.value)}
                            choices={customModelFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'custom_model')  }
                        >
                            {Text('model', lang)}
                        </SelectField>
                        <SelectField 
                            name="park_number"
                            className="mb-4" 
                            value={parkNumber}
                            onChange={(e) => setParkNumber(e.target.value)}
                            choices={parkNumberFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'park_number')  }
                        >
                            {Text('park_number', lang)}
                        </SelectField>
                        <SelectField 
                            name="site"
                            className="mb-4" 
                            value={site}
                            onChange={(e) => setSite(e.target.value)}
                            choices={siteFilters}
                            search
                            searchByRequest
                            searchValue={(char) => searchValue(char, 'site')  }
                        >
                            {Text('site', lang)}
                        </SelectField>
                </Box>
                <Box className={ isLoading ? "flex flex-1 is-loading-tab" : "flex flex-1"}>
                    { !isLoading ? (
                    <TabSelection 
                        {...{config}} 
                        selectMulti={(machine, index) => selectMultiMachines(machine, index)} 
                        selectAll = { (tabIndex, items, isCheck) => selectAllMachines(tabIndex, items, isCheck)}
                    />
                    ) : null}
                </Box>
               

            </div>
        </ScreenContainer>
    );
}

export default MachinesPending;