const Rights = [
    "add_user",
    "update_user",
    "delete_user",
    "add_address",
    "update_address",
    "delete_address",
    "add_machine",
    "update_machine",
    "delete_machine",
    "add_kit",
    "update_kit",
    "delete_kit",
    "add_quote",
    "update_quote",
    "delete_quote",
    "validate_quote",
    "see_order"
];

export default Rights;