import React from 'react';

function Badge({children, className, type}) {
    let style = className + " p-2 text-secondary font-bold rounded ";

    switch (type) {
        case 'error':
            style += 'bg-status-100'
            break;
        case 'warning':
            style += 'bg-status-200'
            break;
        default:
            style += 'bg-status-300'
            break;
    }

    return (
        <div className="flex">
            <span className={style}>
                {children}
            </span>
        </div>
    )
}

export default Badge;