import React, { useState, useEffect } from 'react';

const UserContext = React.createContext([{}, () => {}]);

const UserProvider = ({children}) => {

    const [user, setUser] = useState(false);

    useEffect(() => {
        if(!user){
            let newUser = JSON.parse(localStorage.getItem("park-user"));
            if(!newUser){
                newUser = { loggedIn: false}
            }

            localStorage.setItem("park-user", JSON.stringify(newUser));
            setUser(newUser);
        } else {
            localStorage.setItem("park-user", JSON.stringify(user));
        }
    }, [user])

    return (
        <UserContext.Provider value={[user, setUser]}>
            {children}
        </UserContext.Provider>
    );
}

export { UserContext, UserProvider };