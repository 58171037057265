import React, { useState, forwardRef, useImperativeHandle } from 'react';

function Popup({children, visible, setVisible, onClose, onload }) {

    const handleBackgroundClick = (e) => {
        
        if(e.target.classList.contains('modal-bg') && !onload) {
            e.stopPropagation();
            setVisible(false);
            if(onClose){
                onClose();
            }
            return true;
        }

    }

    const onClick = () => {
        setVisible(false)
        if(onClose){
            onClose();
        }
    }
    
    if(visible){
        return (
            <div className="fixed inset-0 w-full h-full flex justify-center items-center z-10000000 bg-black bg-opacity-25 modal-bg" onMouseDown={(e) => handleBackgroundClick(e)}>
                <div className="bg-white w-max rounded flex flex-col items-end relative">
                    <button className="p-2 absolute top-0 right-2" onClick={()=> onClick()}>
                        <i className="icon-cross text-sm text-primary-200"></i>
                    </button>
                    <div className="p-8">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
    
    return null;
}

const Modal = forwardRef((props, ref) => {
    const [ visible, setVisible ] = useState(false); 

    useImperativeHandle(ref, () => ({
        toggle(){
            setVisible(!visible);
        }
    }));

    return (<Popup {...{...props, visible, setVisible}} />);
});

export default Modal