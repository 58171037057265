import React, { useState, useContext, useEffect } from "react";

import { 
    ScreenContainer,
    SelectField,
    TextAreaField,
    Button,
    Box,
    Message
} from '../../components';
import { post } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import Text from '../../utils/text';

function QuoteAdd() {
    const [ errorMsg, setErrorMsg ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ lang ] = useContext(LangContext);
    const [ menu ] = useContext(MenuContext);
    const [ user, setUser ] = useContext(UserContext);
    const history = useHistory();
    const {id} = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({});

    useEffect(() => {
        const load = async () =>{
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data,
            site: menu.site ? menu.site : data.site
        }

        const result = await post("/quote/create", params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            history.goBack();
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_create_quote', lang));
            }
        }
    };

    return (
        <ScreenContainer>
             <form onSubmit={handleSubmit(onSubmit)}>
                <Message type="error">{errorMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('create_quote', lang)}</h1>
                    </div>
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('create', lang)}
                    </Button>
                </div>
                <div className="flex justify-between flex-wrap ">
                    <Box className="flex-1">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('infos', lang)}</span>
                        </div>
                       
                        {menu.site !== '' ? null : (
                            <SelectField
                                name="site"
                                options={{ required: Text('required', lang) }}
                                register={register}
                                error={errors?.site}
                                className="mb-6"
                                choices={menu.sites}
                                setValue={setValue}
                            >
                                {Text('site', lang)}
                            </SelectField>
                        )}
                        <TextAreaField
                            name="comments"
                            register={register}
                            className="mb-6"
                        >
                            {Text('comments', lang)}
                        </TextAreaField>
                    </Box>
                </div>
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" type="submit" isLoading={isLoading}>
                        {Text('save', lang)}
                    </Button>
                </div>
            </form>
        </ScreenContainer>
    );
}

export default QuoteAdd;