import React from 'react';

function Link({ children, width = 'auto',  href = '#' , className, skin }) {

    let finalClassName = "";
    switch (skin) {
        case "primary-outline":
            finalClassName = "inline-flex items-center leading-none border-2 border-primary text-primary px-4 py-2  hover:bg-primary hover:text-white rounded-lg w-" + width;            
            break;
        case "transparent":
            finalClassName = "inline-flex items-center leading-none text-tiny text-primary px-4 py-2  hover:bg-primary hover:text-white rounded-lg w-" + width;            
            break;
        case "link":
            finalClassName = "inline-flex items-center leading-none text-tiny text-primary hover:text-primary-300 w-" + width;            
            break;
        default:
            finalClassName = "inline-flex items-center leading-none bg-primary text-white px-4 py-2 hover:bg-primary-300 rounded-lg w-" + width;
            break;
    }

    if(className && className !== "") {
        finalClassName += " " +className;
    }

    return (
        <a className={finalClassName} href={href} >
            {children}
        </a>        
    )
}

export default Link;