import React from 'react';

import DefaultField from './DefaultField';

function DateField(props) {
    return (
        <DefaultField type="date" { ... props} />
    )
}

export default DateField;