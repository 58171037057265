import React, { useState, useContext, useEffect, useRef } from "react";

import { 
    ScreenContainer,
    SelectField,
    TextAreaField,
    TextField,
    Button,
    Box,
    Message,
    Tab, 
    Modal,
    NumberField,
    FileUpload
} from '../../components';
import { post, get, remove, getAuthToken } from '../../server/api';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { UserContext } from '../../contexts/UserContext';
import { MenuContext } from '../../contexts/MenuContext';
import { QuoteContext } from '../../contexts/QuoteContext';
import Text from '../../utils/text';
import getStatus from "../../utils/status";

function Quote() {
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const [ menu ] = useContext(MenuContext);
    const [ quote, setQuote ] = useContext(QuoteContext);

    const [ errorMsg, setErrorMsg ] = useState();
    const [ errorMsg2, setErrorMsg2 ] = useState();
    const [ errorMsg3, setErrorMsg3 ] = useState();
    const [ successMsg, setSuccessMsg ] = useState();
    const [ message, setMessage ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ isLoading3, setIsLoading3 ] = useState(false);
    const [ isLoading4, setIsLoading4 ] = useState(false);
    const [ isLoading5, setIsLoading5 ] = useState(false);
    const [ isLoading6, setIsLoading6 ] = useState(false);
    const [ isLoading7, setIsLoading7 ] = useState(false);
    const [ isLoading8, setIsLoading8 ] = useState(false);
    const [ checkValidate, setCheckValidate ] = useState(false);
    const [ loadQuote, setLoadQuote ] = useState();
    const [ removeProduct, setRemoveProduct ] = useState();
    const [ address, setAddress ] = useState('select_address');
    const [ loadAddresses, setLoadAddresses] = useState([{label: Text('select_address', lang), value: ''}]);
    const [ deliveryMethods, setDeliveryMethods ] = useState([{label: Text('select_delivery_method', lang), value: ''}]);
    const [ deliveryMethod, setDeliveryMethod ] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const {id} = useParams();

    const requestModalRef = useRef();
    const validationModalRef = useRef();
    const refuseModalRef = useRef();
    const removeModalRef = useRef();
    const confirmModalRef = useRef();
    const addItemModalRef = useRef();
    const deleteQuoteModalRef = useRef();
    const duplicateModalRef = useRef();
    const confirmValidationModalRef = useRef();
    const confirmConfirmationModalRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        unregister,
        getValues
    } = useForm({});

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        reset
    } = useForm({});

    useEffect(() => {
        setTimeout(() => {
            setMessage(false)
        }, 3000);
    }, [message])

    useEffect(() => {
        const load = async () =>{
            setIsLoading(true);
            let result = await get(location.pathname);
            if(result.success){
                setLoadQuote(result.data);
                setCheckValidate(result.checkValidate);
                setValue('site', result.data.site)
                setValue('comments', result.data.comments !== null && result.data.comments !== 'null' ? result.data.comments : '');
                setValue('phone', result.data.phone);
                setValue('ref_command_intern', result.data.ref_command_intern);

                let params = {
                    quote_id: result.data.id
                }

                let result2 = await get('/addresses', params);
                if(result2.success){
                    let newAddresses = [
                        ...loadAddresses,
                        ...result2.items
                    ];
                    setLoadAddresses(newAddresses);
                    setValue('delivery_address', result.data.delivery_address)

                } else {
                    if(result2.hasOwnProperty('error') && result2.error === 'invalid_grant'){
                        setUser({loggedIn: false});
                    }
                }
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }
            setIsLoading(false);
        }

        load();
    }, [])

    const onSubmit = async (data) => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            ...data,
            status: loadQuote.status,
            token: getAuthToken()
        }

        const result = await post("/quote/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setMessage(Text('saved', lang));
            setLoadQuote(result.data);
            setCheckValidate(result.checkValidate);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }
    };

    const renderImage = (e) => {
        if(e){
            return <img src={e} width={50}/>
        }

        return null;
    }

    const handleAction = (action, element) => {
        setRemoveProduct(element.id);
        removeModalRef.current.toggle();
    }

    const changeItemQuantity = async (quantity, itemId) => {
        if(quantity != 0){
            const result = await post("/quote/update_item/"+itemId, {quantity});

            if (result.hasOwnProperty("success") && result.success) {
                setLoadQuote(result.data);
            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
    
                if(result.hasOwnProperty("message")){
                    setErrorMsg(result.message);
                } else {
                    setErrorMsg(Text('form_error_update_quote', lang));
                }
            }
        } else {
            setRemoveProduct(itemId);
            removeModalRef.current.toggle();
        }
        
    }

    const renderQuantity = (item) => {
        if(loadQuote && loadQuote.status !== "draft"){
            return item.quantity;
        } else {
            return (
                <NumberField
                    defaultValue={item.quantity}
                    onChange={(e) => changeItemQuantity(e.target.value, item.id)}
                    min={0}
                />
            );
        }
        
    }

    const config = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "sku",
                label: Text('sku', lang),
            },
            {
                name: "tax_rate",
                label: Text('tax_rate', lang),
            },
            {
                name: "original_unit_price",
                label: Text('original_unit_price', lang),
            },
            {
                name: "row_total_price",
                label: Text('row_total_price', lang),
            },
        ],
        customActions: [
            {
                label: Text('quantity', lang),
                render: renderQuantity,
            },         
        ],
        actions: [
            {
                label: <i className={"icon-trash text-l"}></i>,
                name: "remove",
                active: loadQuote && loadQuote.status === "draft"
            },
        ],
        error: Text('product_list_error', lang),
        no_results: Text('no_product', lang),
        fetchUrl: '/quote/'+id+'/items',
        handleAction: handleAction,
        params: {
        }
    };

    const onRemoveProduct = async () => {
        const result = await remove("/quote/remove_item/"+removeProduct);

        if (result.hasOwnProperty("success") && result.success) {
            removeModalRef.current.toggle();
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }
    }

    const requestValidation = async () => {
        setErrorMsg(null);
        setIsLoading(true);

        let data = getValues();

        let params = {
            ...data,
            status: 'pending',
            token: getAuthToken()
        }

        const result = await post("/quote/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setLoadQuote(result.data);
            setCheckValidate(result.checkValidate);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }
    }

    const refuseQuote = async () => {
        setErrorMsg(null);
        setIsLoading(true);

        let params = {
            status: 'refuse',
            token: getAuthToken()
        }

        const result = await post("/quote/update/"+id, params);

        setIsLoading(false);

        if (result.hasOwnProperty("success") && result.success) {
            setLoadQuote(result.data);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }
    }

    const preValidate = async() => {
        setErrorMsg(null);
        setIsLoading4(true);

        let params = {
            status: 'validated',
            token: getAuthToken()
        }

        const result = await post("/quote/update/"+id, params);

        setIsLoading4(false);

        if (result.hasOwnProperty("success") && result.success) {
            setDeliveryMethods(result.data);
            setDeliveryMethod(result.data[0].value);
            validationModalRef.current.toggle();
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }
    }

    const validateQuote = async () => {
        setErrorMsg(null);
        //setIsLoading5(true);
        setIsLoading4(true);

        let data = getValues();

        let params = {
            ...data,
            status: 'validated',
            token: getAuthToken()
        }

        const result = await post("/quote/update/"+id, params);

        if (result.hasOwnProperty("success") && result.success) {
            let params2 = {
                value: result.data[0].value,
                token: getAuthToken()
            }
    
            const result2 = await post("/magento/submit_shipping_quote/"+id, params2);
    
            if (result2.hasOwnProperty("success") && result2.success) {
                setLoadQuote(result2.data);
                confirmValidationModalRef.current.toggle()
                //setCheckValidate(result.checkValidate);
                //validationModalRef.current.toggle();
            } else {
                if(result2.hasOwnProperty('error') && result2.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
    
                setErrorMsg2(Text('form_error_shipping_method', lang));
            }
        } else {
            confirmValidationModalRef.current.toggle()
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }
        setIsLoading4(false);
    }

    const preConfirm = async() => {
        setErrorMsg(null);
        setIsLoading6(true);

        //const result = await post("/magento/shipping_quote/"+id);

        setIsLoading6(false);

        //if (result.hasOwnProperty("success") && result.success) {
            //setDeliveryMethods(result.data);
            setDeliveryMethod(loadQuote ? JSON.stringify({delivery_price: loadQuote.delivery_price,delivery_method: loadQuote.delivery_method}) : null);
            //confirmModalRef.current.toggle();
        /*} else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_update_quote', lang));
            }
        }*/
    }

    const confirmQuote = async () => {
        setErrorMsg(null);
        setIsLoading7(true);

        let result = false;
        /*if(deliveryMethod !== JSON.stringify({delivery_price: loadQuote.delivery_price,delivery_method: loadQuote.delivery_method})){
            let params = {
                value: deliveryMethod,
                token: getAuthToken()
            }
    
            result = await post("/magento/submit_shipping_quote/"+id, params);
        } else {*/
            let params = {
                status: 'complete',
                value: JSON.stringify({delivery_price: loadQuote.delivery_price,delivery_method: loadQuote.delivery_method}),
                token: getAuthToken()
            }
    
            result = await post("/quote/update/"+id, params);
        //}

        setIsLoading7(false);

        if (result && result.hasOwnProperty("success") && result.success) {
            setLoadQuote(result.data);
            confirmConfirmationModalRef.current.toggle()
            //setCheckValidate(result.checkValidate);
            //confirmModalRef.current.toggle();
        } else {
              
            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } 
            
            if(result.hasOwnProperty("error")) {
                switch (result.error) {
                    case 'err_01':
                        setErrorMsg(Text("form_error_update_quote_1", lang));
                        break;
                    case 'invalid_grant':
                        setUser({loggedIn: false});
                        break;
                    default:
                        setErrorMsg(Text("form_error_update_quote", lang))
                }
            }

            confirmConfirmationModalRef.current.toggle()
        }
    }

    const addItems = async (data) => {
        setErrorMsg(null);
        setIsLoading2(true);

        let params = {
            quote: id,
            items: data.items[0],
        }

        const result = await post("/items/import", params);

        setIsLoading2(false);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('form_success_import_items', lang));
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                switch (result.error) {
                    case 'err_03':
                        setErrorMsg3(Text('form_error_import_items_3', lang)+ ' ('+ Text('ref', lang) + result.ref + ', '+ Text('machine', lang) + result.machine +')');
                        break;

                    case 'err_02':
                        setErrorMsg3(Text('form_error_import_items_2', lang) + ' ' + result.ref);
                        break;
                
                    default:
                        setErrorMsg3(Text('form_error_import_items_1', lang));
                        break;
                }
    
            }
        }
    }

    const closeImport = () => {
        addItemModalRef.current.toggle();
        setErrorMsg3('');
        setSuccessMsg('');
        reset();
        setIsLoading(true);
        setTimeout(() =>{
            setIsLoading(false);
        }, 1000)
    }

    const deleteQuote = async () => {
        setErrorMsg(null);
        setIsLoading3(true);

        const result = await remove("/quote/delete/"+id);

        setIsLoading3(false);

        if (result.hasOwnProperty("success") && result.success) {
            localStorage.removeItem('park-quote');
            setQuote(false);
            history.push('/quotes');
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("error") && result.error){
                setErrorMsg(Text('form_error_delete_quote', lang));
            }
        }
    }

    const duplicateQuote = async () => {
        setErrorMsg(null);
        setIsLoading8(true);

        const result = await get("/quote/duplicate/"+id);

        setIsLoading8(false);

        if (result.hasOwnProperty("success") && result.success) {
            duplicateModalRef.current.toggle()
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }
        }
    }

    const setForm = async (type) => {
        if(type === 'select_address'){
            unregister('address');
            unregister('address');
            unregister('city');
            unregister('zip_code');
        } else {
            unregister('delivery_address');
        }
        setAddress(type);
    } 

    return (
        <>
            <ScreenContainer isLoading={isLoading} footer={ loadQuote ? (
                <div className="flex justify-between flex-wrap m-2">
                    <Box className="flex-1" padding="p-2">
                        <div className="mb-4">
                            <span className="text-xl title">{Text('summary', lang)}</span>
                        </div>
                        <div className="flex justify-between px-2">
                            <div className="flex items-center">
                                <span className="text-m font-bold mr-4">{Text('status', lang).toUpperCase()}</span>
                                {getStatus(loadQuote.status, lang)}
                            </div> 
                            <div className="flex items-center">
                                <span className="text-m font-bold mr-4">{Text('total_price', lang).toUpperCase()}</span>
                                <span className="text-m">{loadQuote.total_price} €</span>
                            </div>
                            <div className="flex items-center">
                                <span className="text-m font-bold mr-4">{Text('total_price_incl_tax', lang).toUpperCase()}</span>
                                <span className="text-m">{loadQuote.total_price_incl_tax} €</span>
                            </div>
                        </div>
                    </Box>
                </div>
            ) : null}>
                <Modal ref={duplicateModalRef}>
                    <span className="text-primary text-m">{Text('success_duplicate_quote', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-end mt-4">
                            <Button onPress={() => duplicateModalRef.current.toggle()}>
                                {Text('close', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={confirmModalRef}>
                    <span className="text-primary text-m">{Text('popup_confirm_quote', lang)} </span>
                        <SelectField
                            className="mb-6"
                            choices={deliveryMethods}
                            onChange={(e) => setDeliveryMethod(e.target.value)}
                            value={deliveryMethod}
                        />
                        <div>
                            <span className="text-primary text-m">{Text('price', lang)} : {JSON.parse(deliveryMethod).delivery_price} €</span>
                        </div>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => confirmModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button onPress={() => confirmQuote()} isLoading={isLoading7}>
                                {Text('validate', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={validationModalRef}>
                    <span className="text-primary text-m">{Text('popup_validate_quote', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <Message type="error">{errorMsg2}</Message>
                        <SelectField
                            className="mb-6"
                            choices={deliveryMethods}
                            onChange={(e) => setDeliveryMethod(e.target.value)}
                        />
                        <div>
                            <span className="text-primary text-m">{Text('price', lang)} : {JSON.parse(deliveryMethod).delivery_price} €</span>
                        </div>
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => validationModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button onPress={() => validateQuote()} isLoading={isLoading5}>
                                {Text('validate', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={refuseModalRef}>
                    <span className="text-primary text-m">{Text('popup_refuse_quote', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => refuseModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button skin={"warning"} onPress={() => refuseQuote()}>
                                {Text('refused', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={removeModalRef}>
                    <span className="text-primary text-m">{Text('popup_remove_product', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => removeModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button onPress={() => onRemoveProduct()}>
                                {Text('validate', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={requestModalRef}>
                    <span className="text-primary text-m">{Text('popup_request_validation', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => requestModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button skin={"success"} onPress={() => requestValidation()}>
                                {Text('validate', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={addItemModalRef}>
                    <span className="text-primary text-m">
                        {Text('popup_import_items', lang)}
                        <i className={"icon-infos text-m ml-2.5 p-2 border-2 rounded-full"}></i>
                        <span className="infos text-primary text-m bg-grid p-2 w-60">{Text('infos_refs_csv', lang)}</span>
                    </span>
                    <form onSubmit={handleSubmit2(addItems)} className="mt-4">
                        { successMsg ? (
                            <>
                                <Message type="success">{successMsg}</Message>
                                <div className="flex items-center justify-between mt-4">
                                    <Button onPress={() => closeImport()}>
                                        {Text('close', lang)}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <Message type="error">{errorMsg3}</Message>
                                <FileUpload 
                                    name="items"
                                    options={{ required: Text('required', lang) }}
                                    register={register2}
                                    error={errors2?.items}
                                    className="mb-6"
                                >
                                    {Text('refs', lang)}
                                </FileUpload>
                                <div className="flex items-center justify-between mt-4">
                                    <Button onPress={() => closeImport()}>
                                        {Text('cancel', lang)}
                                    </Button>
                                    <Button type="submit" skin={"success"} isLoading={isLoading2}>
                                        {Text('import', lang)}
                                    </Button>
                                </div>
                            </>
                        )}
                    </form>
                </Modal>
                <Modal ref={deleteQuoteModalRef}>
                    <span className="text-primary text-m">{Text('popup_delete_quote', lang)} </span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => deleteQuoteModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button onPress={() => deleteQuote()} skin={"warning"} isLoading={isLoading3}>
                                {Text('delete', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={confirmValidationModalRef}>
                    <span className="text-primary text-m">{Text('popup_confirm_validation', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => confirmValidationModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button onPress={() => validateQuote()} skin={"success"} isLoading={isLoading4}>
                                {Text('validate', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal ref={confirmConfirmationModalRef}>
                    <span className="text-primary text-m">{Text('popup_confirm_confirmation', lang)}</span>
                    <div className="mt-4 flex flex-col items-center">
                        <div className="w-full flex items-center justify-between mt-4">
                            <Button onPress={() => confirmConfirmationModalRef.current.toggle()}>
                                {Text('cancel', lang)}
                            </Button>
                            <Button onPress={() => confirmQuote()} skin={"success"} isLoading={isLoading7}>
                                {Text('confirm', lang)}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {checkValidate || (loadQuote && loadQuote.status === "draft" )? null : (
                        <Message type="error">{Text('validate_condition', lang)}</Message>
                    )}
                    <Message type="error">{errorMsg}</Message>
                    <Message type="success">{message}</Message>
                    <div className="flex items-center w-full">
                        <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                            {Text('back', lang)}
                        </Button>
                        <div className="w-full mr-4">
                            <h1 className="text-2xl title title-decoration-right mb-7.5">{loadQuote ? loadQuote.name : null}</h1>
                        </div>
                        {loadQuote && loadQuote.status === "draft" ? (
                            <>
                                {user.role === 'ROLE_OPERATOR' && (!user.rights || !user.rights.length || !user.rights.includes('delete_quote')) ? null : (
                                    <Button className="mb-7.5 mr-4" onPress={() => deleteQuoteModalRef.current.toggle()}>
                                        {Text('delete_quote', lang)}
                                    </Button>
                                )}
                                <Button className="mb-7.5 mr-4" onPress={() => addItemModalRef.current.toggle()}>
                                    {Text('add_ref', lang)}
                                </Button>
                                <Button className="mb-7.5 mr-4" onPress={() => requestModalRef.current.toggle()}>
                                    {Text('request_validation', lang)}
                                </Button>
                            </>
                        ) : (
                            <>
                                {menu.context === 'TOPAZ' ? null : (
                                    <Button className="mb-7.5 mr-4" onPress={() => duplicateQuote()} isLoading={isLoading8}>
                                        {Text('duplicate_quote', lang)}
                                    </Button>
                                )}
                            </>
                        )}
                        {loadQuote && loadQuote.status === "pending" && user.role !== 'ROLE_OPERATOR' && checkValidate && menu.context !== 'TOPAZ' ? (
                            <Button className="mb-7.5 mr-4" onPress={() => confirmValidationModalRef.current.toggle() /*validateQuote()*//*preValidate()*/}>
                                {Text('validate', lang)}
                            </Button>
                        ): null}
                        {loadQuote && loadQuote.status === "validated" && user.role === 'ROLE_ADMIN' ? (
                            <Button className="mb-7.5 mr-4" onPress={() => confirmConfirmationModalRef.current.toggle() /*confirmQuote()*//*preConfirm()*/}>
                                {Text('confirm', lang)}
                            </Button>
                        ): null}
                        {(loadQuote && loadQuote.status === "pending" && user.role !== 'ROLE_OPERATOR' && menu.context !== 'TOPAZ') || (loadQuote && loadQuote.status === "validated"  &&  user.role === 'ROLE_ADMIN')? (
                            <Button className={"mb-7.5 " + (loadQuote.status !== "validated" ? "mr-4":null)} onPress={() => refuseModalRef.current.toggle()}>
                                {Text('refused', lang)}
                            </Button>
                        ): null}
                        { loadQuote && (loadQuote.status === "draft" || loadQuote.status === 'pending') ? (
                            <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                                {Text('save', lang)}
                            </Button>
                        ) : null}
                    </div>
                    <div className="flex justify-between flex-wrap mb-4">
                        <Box className="mr-4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('infos', lang)}</span>
                            </div>
                            {loadQuote ? (
                                <>
                                    <div className="flex items-center mb-6">
                                        <span className="text-xs font-bold mr-4">{Text('status', lang).toUpperCase()}</span>
                                        {getStatus(loadQuote.status, lang)}
                                    </div> 
                                    <div className="flex items-center mb-6">
                                        <span className="text-xs font-bold mr-4">{Text('name', lang).toUpperCase()}</span>
                                        {loadQuote.name}
                                    </div>
                                    <div className="flex items-center mb-6">
                                        <span className="text-xs font-bold mr-4">{Text('site', lang).toUpperCase()}</span>
                                        {loadQuote.site}
                                    </div>
                                    <div className="flex items-center mb-6">
                                        <span className="text-xs font-bold mr-4">{Text('created_at', lang).toUpperCase()}</span>
                                        {loadQuote.createdAt}
                                    </div>
                                    { loadQuote.updatedAt ? (
                                        <div className="flex items-center mb-6">
                                            <span className="text-xs font-bold mr-4">{Text('updated_at', lang).toUpperCase()}</span>
                                            {loadQuote.updatedAt}
                                        </div>
                                    ) : null}
                                    { loadQuote.createdBy ? (
                                        <div className="flex items-center mb-6">
                                            <span className="text-xs font-bold mr-4">{Text('created_by', lang).toUpperCase()}</span>
                                            {loadQuote.createdBy}
                                        </div>
                                    ) : null}
                                    { loadQuote.validatedBy ? (
                                        <div className="flex items-center mb-6">
                                            <span className="text-xs font-bold mr-4">{Text('validated_by', lang).toUpperCase()}</span>
                                            {loadQuote.validatedBy}
                                        </div>
                                    ) : null}
                                   
                                    {loadQuote.delivery_method && loadQuote.delivery_price ? 
                                        <>
                                            <div className="flex items-center mb-6">
                                                <span className="text-xs font-bold mr-4">{Text('delivery_method', lang).toUpperCase()}</span>
                                                {loadQuote.delivery_method}
                                            </div>
                                            <div className="flex items-center mb-6">
                                                <span className="text-xs font-bold mr-4">{Text('delivery_price', lang).toUpperCase()}</span>
                                                {loadQuote.delivery_price} €
                                            </div> 
                                        </>
                                    : null}
                                    {loadQuote.comment ? 
                                        <div className="flex items-center mb-6">
                                            <span className="text-xs font-bold mr-4">{Text('comment', lang).toUpperCase()}</span>
                                            {loadQuote.comment}
                                        </div>
                                    : null}
                                </>
                            ) : null}
                            {loadQuote && (loadQuote.status === "draft" || loadQuote.status === 'pending') ? (
                                <>
                                    <TextField
                                        name="ref_command_intern"
                                        options={{}}
                                        register={register}
                                        error={errors?.ref_command_intern}
                                        className="mb-6"
                                    >
                                        {Text('ref_command_intern', lang)}
                                    </TextField>
                                    <TextAreaField
                                        name="comments"
                                        options={{}}
                                        register={register}
                                        error={errors?.comments}
                                        className="mb-6"
                                    >
                                        {Text('comments', lang)}
                                    </TextAreaField>
                                </>
                            ) : 
                                loadQuote ? (
                                    <>
                                        {loadQuote.ref_command_intern ? 
                                            <div className="flex items-center mb-6">
                                                <span className="text-xs font-bold mr-4">{Text('ref_command_intern', lang).toUpperCase()}</span>
                                                {loadQuote.ref_command_intern}
                                            </div>
                                        : null}
                                        {loadQuote.comments ? 
                                            <div className="flex items-center mb-6">
                                                <span className="text-xs font-bold mr-4">{Text('comments', lang).toUpperCase()}</span>
                                                {loadQuote.comments}
                                            </div>
                                        : null}
                                    </>
                                ): null
                            }
                        </Box>
                        {loadQuote ? (
                            <Box className="mr-4">
                                <div className="mb-4">
                                    <span className="text-xl title">{Text('billing_address', lang)}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span>
                                        <span className="font-bold">{Text('city', lang)}</span> : {loadQuote.billing_city}
                                    </span>
                                    <span>
                                        <span className="font-bold">{Text('address', lang)}</span> : {loadQuote.billing_address}
                                    </span>
                                    {loadQuote.billing_address_2 ? 
                                        <span>
                                            <span className="font-bold">{Text('address_2', lang)}</span> : {loadQuote.billing_address_2}
                                        </span> 
                                    : null }
                                    <span>
                                        <span className="font-bold">{Text('zip_code', lang)}</span> : {loadQuote.billing_zipcode}
                                    </span>
                                </div>
                            </Box>
                        ) : null}
                        { loadQuote ? (
                            <Box className="flex-1">
                                <div className="mb-4">
                                    <span className="text-xl title">{Text('delivery_address', lang)}</span>
                                </div>
                                { loadQuote.status === 'draft' || loadQuote.status === 'pending' ? (
                                    <>
                                        { address === "select_address" ? (
                                            <>
                                                <SelectField
                                                    name="delivery_address"
                                                    value={loadQuote ? loadQuote.delivery_address : null}
                                                    options={{ required: Text('required', lang) }}
                                                    register={register}
                                                    error={errors?.delivery_address}
                                                    className="mb-6"
                                                    choices={loadAddresses}
                                                    setValue={setValue}
                                                    search
                                                >
                                                    {Text('address', lang)}
                                                </SelectField>
                                                <Button className="mb-7.5" onPress={() => setForm('new_address')}>
                                                    {Text('new_address', lang)}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <TextField
                                                    name="address"
                                                    options={{required: Text('required', lang)}}
                                                    register={register}
                                                    error={errors?.address}
                                                    className="mb-6"
                                                >
                                                    {Text('address', lang)}
                                                </TextField>
                                                <TextField
                                                    name="address_2"
                                                    options={{}}
                                                    register={register}
                                                    error={errors?.address_2}
                                                    className="mb-6"
                                                >
                                                    {Text('address_2', lang)}
                                                </TextField>
                                                <TextField
                                                    name="city"
                                                    options={{required: Text('required', lang)}}
                                                    register={register}
                                                    error={errors?.city}
                                                    className="mb-6"
                                                >
                                                    {Text('city', lang)}
                                                </TextField>
                                                <TextField
                                                    name="zip_code"
                                                    options={{required: Text('required', lang)}}
                                                    register={register}
                                                    error={errors?.zip_code}
                                                    className="mb-6"
                                                >
                                                    {Text('zip_code', lang)}
                                                </TextField>
                                                <Button className="mb-7.5" onPress={() => setForm('select_address')}>
                                                    {Text('select_address', lang)}
                                                </Button>
                                            </>
                                        )}
                                        <TextField
                                            name="phone"
                                            options={{required: Text('required', lang)}}
                                            register={register}
                                            error={errors?.phone}
                                            className="mb-6"
                                            value={loadQuote ? loadQuote.phone : null}
                                        >
                                            {Text('phone', lang)}
                                        </TextField>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex flex-col">
                                            <span>
                                                <span className="font-bold">{Text('city', lang)}</span> : {loadQuote.delivery_city}
                                            </span>
                                            <span>
                                                <span className="font-bold">{Text('address', lang)}</span> : {loadQuote.delivery_address}
                                            </span>
                                            {loadQuote.delivery_address_2 ? 
                                                <span>
                                                    <span className="font-bold">{Text('address_2', lang)}</span> : {loadQuote.delivery_address_2}
                                                </span> 
                                            : null }
                                            <span>
                                                <span className="font-bold">{Text('zip_code', lang)}</span> : {loadQuote.delivery_zipcode}
                                            </span>
                                        </div>
                                    </>
                                )}
                                
                            </Box>
                        ) : null}
                    </div>
                    <div className="flex justify-between flex-wrap mb-4">
                        <Box className="flex-1">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('products', lang)}</span>
                            </div>
                            <Tab {...{config}} />
                        </Box>
                    </div>
                    {loadQuote ? (
                    <div className="flex justify-end mt-4">
                        { loadQuote && (loadQuote.status === "draft" || loadQuote.status === "pending")? (
                            <Button className="mb-7.5" skin={"success"} type="submit" isLoading={isLoading}>
                                {Text('save', lang)}
                            </Button>
                        ) : null}
                    </div>
                    ): null}
                </form>
            </ScreenContainer>
        </>
    );
}

export default Quote;