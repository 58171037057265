import React from "react";
import logo from '../logo.png';

function Maintenance() {
    return (
        <div className="h-screen w-screen flex items-center	justify-center flex-col container mx-auto p-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 flex items-center rounded-lg shadow p-8 bg-white">
                <img src="https://media.topaz.pro/tpz/blocks/5f92a22ebfbe7847500103.svg" alt="maintenance" className="hidden sm:block" />
                
                <div className="space-y-4">
                    <img src={logo} alt="logo" width={300} className="mb-4"/>
                    {/* <img src="https://topaz.site-client.com/static/frontend/Internetrama/topaz/fr_FR/images/logo.png" alt="logo" className="mb-4" /> */}
                    <strong className="text-2xl">Site en maintenance</strong>
                    <p className="text-base	sm:text-xl">Le site est actuellement en maintenance, vous allez pouvoir retrouver vos <strong style={{color: '#FBC125'}}>machines préférés</strong> dans très peu de temps !</p>
                    <p className="text-base	sm:text-xl">Veuillez nous excuser pour la gêne occasionnée.</p>
                    <p className="text-base	sm:text-xl">Cordialement, L'équipe Topaz.</p> 
                    <div className="pt-4 flex flex-col sm:flex-row justify-between gap-2 text-center">
                        <a href="https://www.facebook.com/topazpro85/" className="rounded p-2 px-4 hover:shadow-sm text-sm" style={{backgroundColor: '#FBC125'}}>Facebook</a>
                        <a href="https://www.linkedin.com/company/2768769/" className="rounded p-2 px-4 hover:shadow-sm text-sm" style={{backgroundColor: '#FBC125'}}>Linkedin</a>
                        <a href="tel:+33252161654" className="rounded p-2 hover:shadow-sm px-4 text-sm" style={{backgroundColor: '#FBC125'}}>02 52 16 16 54</a>
                        <a href="mailto:contact@topaz.pro" className="rounded p-2 hover:shadow-sm px-4 text-sm" style={{backgroundColor: '#FBC125'}}>contact@topaz.pro</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Maintenance;