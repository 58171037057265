import React, { useContext } from "react";
import { Button } from "../";
import { QuoteContext } from "../../contexts/QuoteContext";
import { LangContext } from '../../contexts/LangContext';
import { useHistory } from "react-router-dom";
import Text from '../../utils/text';

function BaseMenu({children}) {
    const [quote] = useContext(QuoteContext);
    const [lang] = useContext(LangContext);
    const history = useHistory();

    return (
        <div className="flex justify-between bg-primary rounded mr-4 mb-2">
            <div className="flex justify-start items-center flex-wrap py-2">
                {children}
            </div>
            { quote ? (
                <Button skin="link" className="px-4 font-bold" onPress={() => history.push('/quote/'+quote.id)}>
                    <i className={"icon-order text-2xl mr-2.5"}></i>
                    {Text('current_quote', lang)} { quote.name ? "("+quote.name+")" : null}
                </Button>
            ) : false}
        </div>
    );
}

export default BaseMenu;