import React, { useState, useContext, useEffect, useRef } from "react";


import { post } from '../server/api';
import { useHistory, useParams } from 'react-router-dom';
import { LangContext } from '../contexts/LangContext';
import { UserContext } from '../contexts/UserContext';
import Text from '../utils/text';
import { useForm } from 'react-hook-form';
import { get } from '../server/api';
import getStock from '../utils/productStock';
import { Box, Button, Message, NumberField, ScreenContainer, Tab, TextField, SelectField } from "../components";
import { MenuContext } from "../contexts/MenuContext";
import TabSelection from "../components/TabSelection";

function MassiveMachinesProducts() {
    const [ lang ] = useContext(LangContext);
    const [ user, setUser ] = useContext(UserContext);
    const [ menu ] = useContext(MenuContext);
    const history = useHistory();
    const [ searchProduct, setSearchProduct ] = useState('');
    const [ searchMachine, setSearchMachine ] = useState('');
    const [ errorMsg, setErrorMsg ] = useState();
    const [ successMsg, setSuccessMsg] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoading2, setIsLoading2 ] = useState(false);
    const [ pieces, setPieces ] = useState([]);
    const [ selection, setSelection ] = useState([]);
    const [site, setSite] = useState('');
    const [subsidiary, setSubsidiary] = useState('');
    const [company, setCompany] = useState('');
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');

    const [companies, setCompanies] = useState([{label: Text('select', lang), value: ''}]);
    const [subsidiaries, setSubsidiaries] = useState([{label: Text('select', lang), value: ''}]);
    const [sites, setSites] = useState([{label: Text('select', lang), value: ''}]);
    const [models, setModels] = useState([{label: Text('select', lang), value: ''}]);
    const [brands, setBrands] = useState([{label: Text('select', lang), value: ''}]);
    
    const [char_filters, setCharFilters] = useState('');
    const [type_filters, setTypeFilters] = useState('');
    const [ isLoadingData, setIsLoadingData ] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    
    useEffect(() => {
        const loadFilters = async () => {

                if(firstLoad) {
                    setIsLoading2(true);
                    setFirstLoad(false);
                }
                
                let data = {
                    company,
                    subsidiary,
                    site,
                    all: true,
                    light: true,
                    char_filters,
                    type_filters
                }

                let dataCompanies = await get('/companies', data);
                if(dataCompanies && dataCompanies.success){
                    let newCompanies = [{value: '', label: Text('select', lang)}];
                    dataCompanies.items.forEach(element => {
                        newCompanies.push({value: element.id, label: element.name})
                    });
                    setCompanies(newCompanies);
                }
                let dataSubsidiaries = await get('/subsidiaries', data);
                if(dataSubsidiaries && dataSubsidiaries.success){
                    let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                    dataSubsidiaries.items.forEach(element => {
                        newSubsidiaries.push({value: element.id, label: element.name})
                    });
                    setSubsidiaries(newSubsidiaries);
                }

                let dataSites = await get('/sites', data);
                if(dataSites && dataSites.success){
                    let newSites = [{value: '', label: Text('select', lang)}];
                    dataSites.items.forEach(element => {
                        newSites.push({value: element.id, label: element.name})
                    });
                    setSites(newSites);
                }
            

            const params = {
                company: menu.context === 'TOPAZ' ? company : menu.company,
                subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary,
                site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site,
                search_machine: searchMachine,
                brand,
                model,
                char_filters, 
                type_filters
            }

            const result = await get('/machine/filters', params);

            if(result.success){
                let filters = result.data;

                if(filters.hasOwnProperty('brands')){
                    let newBrandFilters = [{label: Text('select', lang), value: ''}];

                    filters.brands.forEach((brand) => {
                        newBrandFilters.push(brand);
                    })

                    setBrands(newBrandFilters);
                }

                if(filters.hasOwnProperty('models')){
                    let newModelFilters = [{label: Text('select', lang), value: ''}];

                    filters.models.forEach((city) => {
                        newModelFilters.push(city);
                    })

                    setModels(newModelFilters);
                }
                

            } else {
                if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                    setUser({loggedIn: false});
                }
            }

            setIsLoading2(false);
        }

     
        if(menu.context){
            loadFilters();
        }
       
        
    }, [ site, brand, model, subsidiary, company, searchMachine, menu])

    useEffect(() => {
        const loadFilters = async () => {
            
            let data = {
                company,
                subsidiary,
                site,
                all: true,
                light: true,
                char_filters,
                type_filters
            }


            if( type_filters === "site"){

                let dataSites = await get('/sites', data);
                if(dataSites && dataSites.success){
                    let newSites = [{value: '', label: Text('select', lang)}];
                    dataSites.items.forEach(element => {
                        newSites.push({value: element.id, label: element.name})
                    });
                    setSites(newSites);
                }

            }

            if( type_filters === "subsidiary"){
                
                let dataSubsidiaries = await get('/subsidiaries', data);
                if(dataSubsidiaries && dataSubsidiaries.success){
                    let newSubsidiaries = [{value: '', label: Text('select', lang)}];
                    dataSubsidiaries.items.forEach(element => {
                        newSubsidiaries.push({value: element.id, label: element.name})
                    });
                    setSubsidiaries(newSubsidiaries);
                }

                
            }


            if( type_filters === "company"){
                let dataCompanies = await get('/companies', data);
                if(dataCompanies && dataCompanies.success){
                    let newCompanies = [{value: '', label: Text('select', lang)}];
                    dataCompanies.items.forEach(element => {
                        newCompanies.push({value: element.id, label: element.name})
                    });
                    setCompanies(newCompanies);
                }
            }
            
            switch(type_filters) {
                case "company" : 
                    setSite('')
                    setSubsidiary('');
                    break;
                case "subsidiary" :
                    setSite('');
                    break;
                default:
                    break;
            }
                

            if ( type_filters === "brand" || type_filters === "model" ){
                const params = {
                    company: menu.context === 'TOPAZ' ? company : menu.company,
                    subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary,
                    site: menu.context === 'TOPAZ' || !menu.site ? site : menu.site,
                    search_machine: searchMachine,
                    brand,
                    model,
                    char_filters, 
                    type_filters
                }
    
                const result = await get('/machine/filters', params);
    
                if(result.success){
                    let filters = result.data;
    
                    if(filters.hasOwnProperty('brands')){
                        let newBrandFilters = [{label: Text('select', lang), value: ''}];
    
                        filters.brands.forEach((brand) => {
                            newBrandFilters.push(brand);
                        })
    
                        setBrands(newBrandFilters);
                    }
    
                    if(filters.hasOwnProperty('models')){
                        let newModelFilters = [{label: Text('select', lang), value: ''}];
    
                        filters.models.forEach((city) => {
                            newModelFilters.push(city);
                        })
    
                        setModels(newModelFilters);
                    }
                    
    
                } else {
                    if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                        setUser({loggedIn: false});
                    }
                }
            }
            

        }

     
        if(menu.context){
            loadFilters();
        }
       
        
    }, [char_filters])
   
    const onSubmit = async () => {
        setErrorMsg(null);
        setIsLoadingData(true);

        let piecesLight = [];

        pieces.forEach( piece =>{
            piecesLight.push({id: piece.id, comment: piece.comment ? piece.comment : '', quantity: piece.quantity ? parseInt(piece.quantity) : 1, manufacturer_sku: piece.manufacturer_sku ? piece.manufacturer_sku : ''});
        })

        let machinesLight = [...selection];

        let params = {
            pieces: JSON.stringify(piecesLight),
            machines: JSON.stringify(machinesLight),
        }

        const result = await post("/machines-products/massive", params);

        setIsLoadingData(false);

        if (result.hasOwnProperty("success") && result.success) {
            setSuccessMsg(Text('form_success_massive_machine_product_update', lang));
            setSelection([]);
            setPieces([]);
        } else {
            if(result.hasOwnProperty('error') && result.error === 'invalid_grant'){
                setUser({loggedIn: false});
            }

            if(result.hasOwnProperty("message")){
                setErrorMsg(result.message);
            } else {
                setErrorMsg(Text('form_error_add_pieces', lang));
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setSuccessMsg(false)
        }, 50000);
    }, [successMsg])

    const renderImage = (e) => {
        if(e){
            return (
                <img src={e} className="object-contain h-24"/>
            )
        }

        return null;
    }

    const searchValue = async(charFilters, typeFilters) =>{
        setCharFilters(charFilters)
        setTypeFilters(typeFilters)
    }

    const handleAction = (action, element) => {
        let index = pieces.findIndex(p => p.id === element.id)

        let newPieces = [ ...pieces ];
        element.quantity = 1;
        newPieces.push(element);

        if(index === -1){
            setPieces(newPieces);
        }
    }

    const onRemove = (index) => {

        pieces.splice(index, 1);
        let newPieces = [ ...pieces ];

        setPieces(newPieces);
    }

    const renderStock = (e) => {
        if(e){
            return getStock('in_stock', e);
        }

        return getStock('no_stock', e);
    }

    const config = {
        headers: [
            {
                name: "image",
                label: Text('image', lang),
                render: renderImage
            },
            {
                name: "title",
                label: Text('name', lang),
            },
            {
                name: "sku",
                label: Text('sku', lang),
            },
            {
                name: "is_in_stock",
                label: Text('is_in_stock', lang),
                render: renderStock
            },
        ],
        actions: [
            {
                label: <i className={"icon-more text-l"}></i>,
                name: 'add',
                active: true, 
                skin: 'success'
            },
        ],
        error: Text('piece_list_error', lang),
        no_results: Text('no_piece', lang),
        fetchUrl: '/products',
        handleAction: handleAction,
        params: {
            search_product: searchProduct,
            number_per_page : 10
        }
    };

    const changeQuantity = (quantity, index) => {
        let newPieces = [...pieces];
        newPieces[index]['quantity'] = quantity;
        setPieces(newPieces);
    }

    const changeComment = (comment, index) => {
        let newPieces = [...pieces];
        newPieces[index]['comment'] = comment;
        setPieces(newPieces);
    }

    const changeManufacturerSku = (manufacturer_sku, index) => {
        let newPieces = [...pieces];
        newPieces[index]['manufacturer_sku'] = manufacturer_sku;
        setPieces(newPieces);
    }
    

   

    const config2 = {
        headers: [
            {
                name: "name",
                label: Text('name', lang),
            },
            {
                name: "model",
                label: Text('model', lang),
            },
            {
                name: "brand",
                label: Text('brand', lang),
            },
            {
                name: "park_number",
                label: Text('park_number', lang),
            },
        ],
        error: Text('machine_list_error', lang),
        no_results: Text('no_machine', lang),
        fetchUrl: '/machines',
        handleAction: handleAction,
        params: {
            company:  menu.context === 'TOPAZ' ? company : menu.company,
            subsidiary: menu.context === 'TOPAZ' || !menu.subsidiary ? subsidiary : menu.subsidiary , 
            site:  menu.context === 'TOPAZ' || !menu.site ? site : menu.site ,
            search_machine: searchMachine,
            number_per_page: 12,
            brand,
            model,
            page_type : 'massive_machines_products'
        },
    };

    const selectMultiMachines = (machine) =>{
        let newSelection = [...selection];

        if(!selection.length){
            newSelection.push(machine.id);
        } else {
            let isSelected = false;
            let index = null;
            
            newSelection.forEach((m, i)=> {
                if(m === machine.id){
                    isSelected = true;
                    index = i;
                }
            })

            if(isSelected){
                newSelection.splice(index, 1);
            } else {
                newSelection.push(machine.id);
            }
           
        }

        setSelection(newSelection);
    }

    const selectAllMachines = (tabIndex, items, isCheck) => {

        let newSelection = [...selection];
        if(!isCheck){
            items.forEach((machine, index) => {
                if(tabIndex.includes(index)){
                    newSelection.push(machine.id);
                }
            });
            
        }else{
            newSelection = [];
        }
        

        setSelection(newSelection);
    }


    return (
        <ScreenContainer isLoading={isLoadingData}>
            <div>
                <Message type="error">{errorMsg}</Message>
                <Message type="success">{successMsg}</Message>
                <div className="flex items-center w-full">
                    <Button className="mb-7.5 mr-4" onPress={() => history.goBack()}>
                        {Text('back', lang)}
                    </Button>
                    <div className="w-full mr-4">
                        <h1 className="text-2xl title title-decoration-right mb-7.5">{Text('add_products_to_machines', lang)}</h1>
                    </div>
                    <Button className="mb-7.5" skin={"success"} onPress={() => onSubmit()} isLoading={isLoading} disabled={selection.length === 0 || pieces.length === 0}>
                        {Text('add', lang)}
                    </Button>
                </div>
                <Box className="flex-row flex-1 mb-4">
                    <div className="flex flex-col mb-2">
                        <div>
                            <TextField className="w-96 " onChange={(e) => setSearchProduct(e.target.value)}>
                                {Text('search', lang)}
                            </TextField>
                        </div> 
                        
                    </div>
                </Box>
        
                <div className="flex justify-between flex-row  ">
                    <div className="flex justify-between flex-1  ">
                        

                        <Box className="flex-1 mb-4 mr-4">
                            <div className="mb-4">
                                <span className="text-xl title">{Text('pieces', lang)}</span>
                            </div>
                            <Tab {...{config}} params={config.params}/>
                        </Box>
                    </div>
                    <div className="flex-1 justify-between flex-col">
                        <Box className="flex-1 mb-4" isLoading={isLoading}>
                            <div className="mb-4 ">
                                <span className="text-xl title">{Text('infos', lang)}</span>
                            </div>
                            <div className="mb-4">
                                <span className="block font-bold text-xs">{Text('piece_added', lang).toUpperCase()}</span>
                            </div>
                            <div className="sticky top-0">
                                <div className="w-full h-100 bg-secondary rounded border flex flex-col overflow-auto px-4 py-2">
                                    {pieces && pieces.length ? (
                                        <table className={"w-full"}>
                                            <thead>
                                                <tr className="border-b border-gray-400">
                                                    <th className="px-4 py-2 text-left text-gray-500">
                                                        {Text('name', lang)}
                                                    </th>
                                                    <th className="px-4 py-2 text-left text-gray-500">
                                                        {Text('sku', lang)}
                                                    </th>
                                                    <th className="px-4 py-2 text-left text-gray-500">
                                                        {Text('manufacturer_sku', lang)}
                                                    </th>
                                                    <th className="px-4 py-2 text-left text-gray-500">
                                                        {Text('comment', lang)}
                                                    </th>
                                                    <th className="px-4 py-2 text-left text-gray-500">
                                                        {Text('quantity', lang)}
                                                    </th>
                                                    <th className="px-4 py-2 text-left text-gray-500">
                                                        
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { pieces.map((piece, i) => (
                                                    <tr key={i} className={i === pieces.length - 1? null : "border-b border-gray-400"} >
                                                        {console.log(piece)}
                                                        <td className="px-4 py-2">
                                                            <span>{piece.title}</span>
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            <span>{piece.sku}</span>
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            <TextField
                                                                className="mb-6"
                                                                onChange={(e) => changeManufacturerSku(e.target.value, i)}
                                                                key={i}
                                                                value={piece.manufacturer_sku ? piece.manufacturer_sku : []}
                                                            />
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            <TextField
                                                                test='comment'
                                                                className="mb-6"
                                                                onChange={(e) => changeComment(e.target.value, i)}
                                                                key={i}
                                                                value={piece.comment ? piece.comment : []}
                                                            />
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            <NumberField
                                                                className="mb-6"
                                                                defaultValue={1}
                                                                onChange={(e) => changeQuantity(e.target.value, i)}
                                                                min='1'
                                                                value={piece.quantity ? piece.quantity : 1}
                                                            />
                                                        </td>
                                                        <td className="flex flex-row px-4 py-2">
                                                            <Button  skin={"warning"} onPress={() => onRemove(i)}><i className={"icon-less text-l"}></i></Button>
                                                        </td>
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <Message>{Text('no_piece_add', lang)}</Message>
                                    )}
                                    
                                </div>
                            </div>
                        </Box>
                    
                    
                    </div>
                </div>

                <Box className={isLoading2 ? "is-loading-tab mb-4" : "mb-4"}>
                    { !isLoading2 ? 
                    <>
                        <div className="flex flex-wrap mb-2 ">
                            <div className="flex flex-col flex-1 mr-6">
                                <TextField className="mb-4" onChange={(e) => setSearchMachine(e.target.value)}>
                                    {Text('search', lang)}
                                </TextField>

                                <SelectField 
                                    className="mb-4" 
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    choices={brands}
                                    search
                                    searchByRequest
                                    searchValue={(char) => searchValue(char, 'brand')}
                                >
                                    {Text('brand', lang)}
                                </SelectField>
                            
                                <SelectField 
                                    className="mb-4" 
                                    value={model}
                                    onChange={(e) => setModel(e.target.value)}
                                    choices={models}
                                    search
                                    searchByRequest
                                    searchValue={(char) => searchValue(char, 'model')}
                                >
                                    {Text('model', lang)}
                                </SelectField>
                            </div> 
                            <div className="flex flex-col flex-1">
                                <SelectField 
                                    className="mb-4" 
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    choices={companies}
                                    search
                                    searchByRequest
                                    searchValue={(char) => searchValue(char, 'company')}
                                >
                                    {Text('company', lang)}
                                </SelectField>

                                <SelectField 
                                    className="mb-4" 
                                    value={subsidiary}
                                    onChange={(e) => setSubsidiary(e.target.value)}
                                    choices={menu.context === 'TOPAZ' ? subsidiaries : menu.subsidiaries}
                                    search
                                    searchByRequest
                                    searchValue={(char) => searchValue(char, 'subsidiary')}
                                >
                                    {Text('subsidiary', lang)}
                                </SelectField>

                                <SelectField 
                                    className="mb-4 " 
                                    value={site}
                                    onChange={(e) => setSite(e.target.value)}
                                    choices={menu.context === 'TOPAZ' ? sites : menu.sites}
                                    search
                                    searchByRequest
                                    searchValue={(char) => searchValue(char, 'site')}
                                >
                                    {Text('site', lang)}
                                </SelectField>
                            </div>
                            
                        </div>
                    </>
                    : null}
                </Box>
                <Box className="flex-1 mb-4 ">
                    <div className="mb-4">
                        <span className="text-xl title">{Text('machines', lang)}</span>
                    </div>
                    <div className="w-full flex flex-col  px-4 py-2">
                        <TabSelection 
                        config={config2}
                        selectMulti={(machine, index) => selectMultiMachines(machine, index)} 
                        selectAll = { (tabIndex, items, isCheck) => selectAllMachines(tabIndex, items, isCheck)}
                    />
                    </div>
                    
                </Box>
                    
                <div className="flex justify-end mt-4">
                    <Button className="mb-7.5" skin={"success"}  onPress={() => onSubmit()} isLoading={isLoading} disabled={selection.length === 0 || pieces.length === 0}>
                        {Text('add', lang)}
                    </Button>
                </div>
            </div>
        </ScreenContainer>
    );
}
export default MassiveMachinesProducts;